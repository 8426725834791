import { useEffect, useState } from "react";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import { cd, cdh, get_date } from "../../../_assets/js/global";
import Icon from "../../../components/body/icon";
import "./style.css"
import { GlobalContext } from "../../../context/Global";
import { useContext } from "react";
import axios from "axios";

export default function Relatorio({ icons, filters }) {
    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // NECESSÁRIO PARA FUNCIONAR O CARREGAMENTO DA LISTA AO ENTRAR NA TELA (PRECISA AJUSTAR)
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    //ESTADOS
    const [info, setInfo] = useState();
    //FILTROS TABELA
    const [administradora, setAdministradora] = useState();
    const [empreendimento, setEmpreendimento] = useState();
    const [loja, setLoja] = useState();
    const [sistema, setSistema] = useState();
    const [acesso, setAcesso] = useState();
    const [status, setStatus] = useState();
    const [usuario, setUsuario] = useState();
    const [cargo, setCargo] = useState();
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();
    const [login, setLogin] = useState();
    //FILTROS DASHBOARD
    const [dashSistemas, setDashSistemas] = useState();
    const [dashLojas, setDashLojas] = useState();
    const [dashDepartamentos, setDashDepartamentos] = useState();
    const [dashCategoria, setDashCategoria] = useState();
    const [dashSubcategoria, setDashSubcategoria] = useState();
    const [dashFrequencia, setDashFrequencia] = useState();
    const [dashStatus, setDashStatus] = useState();
    const [filterMonth, setFilterMonth] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    const [dashMonth, setDashMonth] = useState();
    const [dashYear, setDashYear] = useState();
    const [dashInicio, setDashInicio] = useState();
    const [dashFim, setDashFim] = useState()

    //HANDLE EVENT
    const handleEvent = (setState) => (e) => {
        setState(e)
    }
    //HANDLE VALUE
    const handleValue = (setState) => (e) => {
        setState(e.value)
    }
    //HANDLE EVENT
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    }

    //constrói a thead
    const thead = [
        { enabled: (window.rs_id_emp == 26 ? true : false), export: (window.rs_id_emp == 26 ? true : false), label: 'Administradora', id: 'grupo', name: 'grupo', api: `${window.host}/api/sql.php?do=select&component=grupo`, onChange: handleEvent(setAdministradora) },
        { enabled: true, label: 'Empreendimento', id: 'empreendimento', name: 'empreendimento', api: `${window.host}/api/sql.php?do=select&component=empreendimento`, onChange: handleEvent(setEmpreendimento) },
        { enabled: true, label: 'Loja', id: 'loja', name: 'loja', api: `${window.host}/api/sql.php?do=select&component=loja&filial=true&np=true`, onChange: handleEvent(setLoja) },
        { enabled: true, label: 'Usuário', id: 'usuario', name: 'usuario', onChange: handleEvent(setUsuario), api: `${window.host}/api/sql.php?do=select&component=usuario`, },
        { enabled: true, label: 'Cargo', id: 'cargo', name: 'cargo', api: `${window.host}/api/sql.php?do=select&component=cargo`, params: { empreendimento_id: window.rs_id_emp }, onChange: handleEvent(setCargo) },
        { enabled: true, label: 'Login', id: 'login', name: 'login', onChange: handleTarget(setLogin) },
        { enabled: true, label: 'Data', id: 'dataHora_acesso', name: 'dataHora_acesso', type: 'date', start: { value: dataInicio, onChange: handleEvent(setDataInicio) }, end: { value: dataFim, onChange: handleEvent(setDataFim) } },
        { enabled: true, label: 'Sistema', id: 'sistema', name: 'sistema', api: `${window.host}/api/sql.php?do=select&component=sistema`, onChange: handleEvent(setSistema) },
        // { enabled: true, label: 'Acesso', id: 'acesso', name: 'acesso', onChange: handleEvent(setAcesso) },
        { enabled: true, label: 'Status', id: 'status', name: 'status', onChange: handleEvent(setStatus), items: [{ value: 'with_store', label: 'Loja' }, { value: 'no_store', label: 'Sem Loja' }] },
    ];

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3'

    //
    // PARAMS API TABLE
    const params = {
        //  do: 'get',
        page: 0,
        filter_id_emp: empreendimento,
        filter_id_store: loja,
        filter_id_user: usuario,
        filter_id_office: cargo,
        filter_date_start_access: dataInicio && get_date('date_sql', cd(dataInicio)),
        filter_date_end_access: dataFim && get_date('date_sql', cd(dataFim)),
        filter_id_system: sistema,
        filter_login: login,
        filter_has_store: status,
        // filter_has_store: status&&status.join(','),
    };

    // BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: 'Log Acesso',
        filters: params,
        orientation: 'L',
        key: 'data'
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    {/* <Icon type='pdf' api={{ body: body }} /> */}
                    <Icon type='excel' api={{ body: body }} />
                </>
            )
        }
        if (filters) {
            filters(<></>)
        }
    }, [info, filterMonth, dashInicio, dashFim]);

    return (
        <>
            <Row>
                <Table
                    id='log-acesso'
                    api={url}
                    params={params}
                    thead={thead}
                    key_aux_2={'data'}
                    onLoad={handleEvent(setInfo)}
                    reload={dataFim}
                >
                    <Tbody>
                        {
                            info &&
                            info?.map((item, i) => {

                                let status;
                                let color;
                                if (item.loja) {
                                    status = "Loja"
                                    color = 'blue'
                                } else {
                                    status = "Sem Loja"
                                    color = 'gray'
                                }
                                return (
                                    <Tr key={i}>
                                        {(window.rs_id_emp == 26 ?
                                            <Td>{item?.grupo}</Td>
                                        :'')}
                                        <Td>{item?.empreendimento}</Td>
                                        <Td title={item.loja}>{item.loja}</Td>
                                        <Td title={item.usuario}>{item.usuario}</Td>
                                        <Td>{item?.cargo}</Td>
                                        <Td>{item?.login}</Td>
                                        <Td>{cdh(item.dataHora_acesso)}</Td>
                                        <Td>{item?.sistema}</Td>
                                        {/* <Td>{item?.acesso}</Td> */}
                                        <Td align="center" > <span className={`log-acesso ${color}`}>{status}</span></Td>

                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Row>
            {/* <Row className="my-4" wrap={(window.isMobile ? true : false)}>
                <DashboadAcesso
                    sistemas={dashSistemas}
                    lojas={dashLojas}
                    departamentos={dashDepartamentos}
                    categoria={dashCategoria}
                    subcategoria={dashSubcategoria}
                    frequencia={dashFrequencia}
                    status={dashStatus}
                    data_inicio={dashInicio}
                    data_fim={dashFim}
                    mes={dashMonth}
                    ano={dashYear}
                />
            </Row> */}
        </>
    )
}
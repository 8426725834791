import { useState, useEffect } from 'react';

import Icon from "../../../../../components/body/icon";
import Modal from "../../../../../components/body/modal";
import ModalHeader from "../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../../components/body/modal/modalBody";
import Form from '../../../../../components/body/form';
import Input from '../../../../../components/body/form/input';
import InputContainer from '../../../../../components/body/form/inputcontainer';
import Switch from '../../../../../components/body/switch';
import Button from '../../../../../components/body/button';
import axios from 'axios';
import "./style.scss";

import {
    DndContext,
    pointerWithin,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import Item from './Item';


export default function Editar({ callback }) {
    // ESTADOS
    const [showModal, setShowModal] = useState(true);
    const [formStatus, setFormStatus] = useState(null);
    const [dragOver, setDragOver] = useState(false);
    const [dragEnd, setDragEnd] = useState(true);
    const [onDragStart, setOnDragStart] = useState(null);
    const [onDragEnd, setOnDragEnd] = useState(null);
    const [activeId, setActiveId] = useState();
    const [inativos, setInativos] = useState(false);
    const [remover, setRemover] = useState(0);

    // ESTADOS DE VALORES
    const [id, setId] = useState(null);
    const [posicao, setPosicao] = useState(null);
    const [nome, setNome] = useState(null);
    const [imagens, setImagens] = useState(null);
    const [items, setItems] = useState([]);

    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);

        if (callback) {
            callback({
                show: false
            })
        }
    }

    // DADOS DO FORM
    const handleAddBanner = () => {
        setRemover(remover + 1);
        setItems(prevBanner => [
            ...prevBanner,
            {
                id: '',
                imagens: '',
                nome: '',
                categoria_id: 3,
                subcategoria_id: 4,
                posicao: '',
                ativo: 1
            }
        ]);
    };

    const handleRemoveBanner = () => {
        setRemover(remover - 1);
        setItems(items.slice(0, items.length - 1));
    };

    // STATUS DO FORM
    const handleFormStatus = (e) => {
        setFormStatus(e);
    }

    // CALLBACK DO FORM
    const handleFormResponse = () => {
        setShowModal(false);

        if (callback) {
            callback({
                show: false,
                reload: true
            })
        }

        // if(!item){
        //     setPosicao(null);
        //     setNome(null);
        // }

        // if(callback){
        //     callback({
        //         reload: true
        //     })
        // }

        // if(item){
        //     toast('Banner editado com sucesso');
        // }else{
        //     toast('Banner criado com sucesso');
        // }
    }

    // GET BANNERS

    function getBanners() {
        axios({
            method: 'get',
            url: window.host + '/systems/banner/api/banner.php',
            params: {
                do: 'get',
                inativos: inativos
            }
        }).then((response) => {
            if (response.data) {
                setItems(response.data);
            }
        })
    }

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        getBanners();
    }, [inativos]);

    const handleChange = (e, index) => {
        const name = e.name;
        const value = e.value;

        setItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems[index][name] = value;
            return updatedItems;
        });

        if (name === 'anexo') {
            handleChange({ name: 'imagens', value: [JSON.parse(value)[0].id] }, index);
        }
    };

    const handleChangeInativos = (e) => {
        setInativos(e.target.checked);
    }

    function customCollisionDetectionAlgorithm(args) {
        const pointerCollisions = pointerWithin(args);

        if (pointerCollisions.length > 0) {
            return pointerCollisions;
        }

        return rectIntersection(args);
    };

    function findContainer(id) {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key) => items[key].items.filter((elem) => elem?.id === id).length > 0);
    }

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;

        setActiveId(id);
        setDragEnd(false);
        setDragOver(true);
    };

    function handleDragOver(event) {
        const { active, over, activatorEvent } = event;
        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        const { id } = active;

        // Find the containers
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        setItems((prev) => {
            const activeItems = prev[activeContainer];
            const overItems = prev[overContainer];

            //Find the indexes for the items
            const activeIndex = activeItems.items.findIndex((elem) => elem?.id == id);

            const overIndex = overItems.items.findIndex((elem) => elem?.id == overId);

            let newIndex;
            if (overId in prev) {
                newIndex = overItems.length + 1;
            } else {
                const isBelowLastItem = over && overIndex === overItems.length - 1;
                // && draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;        
                const modifier = isBelowLastItem ? 1 : 0;
                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            return {
                ...prev,

                [activeContainer]: {
                    items: [
                        ...prev[activeContainer].items.filter((item) => item.id !== active.id)
                    ]
                },

                [overContainer]: {
                    items: [
                        ...prev[overContainer].items.slice(0, newIndex),
                        items[activeContainer].items[activeIndex],
                        ...prev[overContainer].items.slice(newIndex, prev[overContainer].length)
                    ]
                }
            };
        });

        setDragEnd(false);
        setDragOver(true);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        const { id } = active;
        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        const activeIndex = items[activeContainer].items.findIndex((elem) => elem?.id == active.id);
        const overIndex = items[overContainer].items.findIndex((elem) => elem?.id == overId);

        if (activeIndex !== overIndex) {
            setItems((items) => ({
                ...items,
                [overContainer]: {
                    items: arrayMove(items[overContainer].items, activeIndex, overIndex)
                }
            }));

            // Object.assign(items[overContainer].items, arrayMove(items[overContainer].items, activeIndex, overIndex));
        }

        setActiveId(null);
        setDragEnd(true);
        setDragOver(false);
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleBannerFromSubmit = (e) => {
        document.getElementById('btn_submit_banner').click();
    }

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} xxl={true}>
                <ModalHeader>
                    <ModalTitle>
                        {'Banners'}

                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="p-0" id={'modal_banner'} styleAux={{ minHeight: '80vh' }}>
                    <div className="position-relative" style={{ overflowX: 'auto', padding: '20px 0 80px', margin: '0 30px', minHeight: '80vh' }}>
                        <Form
                            api={window.host + '/systems/banner/api/banner.php?do=postMultiple'}
                            data={items}
                            status={handleFormStatus}
                            response={handleFormResponse}
                            width="100%"
                            height="50%"
                        >
                            <div id="container_overflow">
                                {(items.length > 0 ? items.map((item, i) => {
                                    return (
                                        <Item banner={item} key={i} index={i} handleChange={handleChange} />
                                    )
                                })
                                    : '')}
                            </div>

                            <Button
                                type="submit"
                                className={'d-none'}
                                id="btn_submit_banner"
                            >
                                Salvar
                            </Button>
                        </Form>
                    </div>

                    <div className="sm-btn"
                        // style={{ right: 20, bottom: 35, gap: 10 }}
                    >
                        <div>
                            <Input
                                type="checkbox"
                                id="check_inativos"
                                label="Mostrar Inativos"
                                onChange={handleChangeInativos}
                                required={false}
                            />
                        </div>

                        {(remover ?
                            <Button
                                type="button"
                                onClick={handleRemoveBanner}
                                className="mr-3"
                                color={'red'}
                            >
                                Remover Banner
                            </Button>
                            : '')}

                        <Button
                            type="button"
                            onClick={handleAddBanner}
                            className="mr-3"
                        >
                            Adicionar Banner
                        </Button>

                        <Button
                            type="submit"
                            status={formStatus}
                            onClick={handleBannerFromSubmit}
                        >
                            Salvar
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

import { useState, useContext } from "react";

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import axios from "axios";
import { useEffect } from "react";
import Icon from "../../../components/body/icon";
import SelectReact from '../../../components/body/select';
import { get_date } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";

export default function Acesso({filters, icons}){
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // VARIÁVEIS
    var date = new Date();

    // HABILITA O LOADING DA TABELA NOVAMENTE AO TROCAR AS TELAS
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // ESTADOS
    const [thead, setThead] = useState([]);
    const [theadAux, setTheadAux] = useState([]);
    const [items, setItems] = useState([]);
    const [usuarios, setUsuarios] = useState([]); // PROVISÓRIO
    const [titleExport, setTitleExport] = useState({});

    // ESTADOS FILTROS
    const [grupo, setGrupo] = useState([]);
    const [empreendimento, setEmpreendimento] = useState(window.rs_id_emp);
    const [departamento, setDepartamento] = useState([]);
    const [responsavel, setResponsavel] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [status, setStatus] = useState([]);
    const [statusAux, setStatusAux] = useState([]);
    const [sistemas, setSistemas] = useState([]);

    // ESTADOS OPTIONS
    const [optionsGrupo, setOptionsGrupo] = useState([]);
    const [optionsEmpreendimento, setOptionsEmpreendimentos] = useState([]);    

    // SETA ITENS VINDOS DA API
    const handleSetItems = (e) => {
        setItems(e);
    }

    // USUÁRIOS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component=usuario'
        }).then((response) => {
            setUsuarios(response.data);
        });
    },[]);

    // EMPREENDIMENTO
    useEffect(() => {
        let component_aux;

        if(window.rs_id_emp == 26){
            component_aux = 'empreendimento';
        }else{
            component_aux = 'grupo_empreendimento';
        }

        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component='+component_aux
        }).then((response) => {
            setOptionsEmpreendimentos(response.data);
        });
    },[]);

    // GRUPOS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component=grupo'
        }).then((response) => {
            setOptionsGrupo(response.data);
        });
    },[]);

    // GET THEADS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess',
        }).then((response) => {
            if(response?.data?.data_aux){
                setTheadAux(response.data.data_aux);

                // PEGA ID'S DOS SISTEMAS PARA CRIAR O FILTRO
                let sistema_aux = [];
                response.data.data_aux.map((item, i) => {
                    sistema_aux.push(item.id);
                });

                setSistemas(sistema_aux);
            }
        })
    },[]);

    // FILTRO GRUPO
    const handleSetGrupo = (e) => {
        setGrupo(e.value);
        setEmpreendimento([]);
        handleSetFilter(true);
    }

    // FILTRO EMPREENDIMENTO
    const handleSetEmpreendimento = (e) => {
        setEmpreendimento(e.value);
        setGrupo([]);
        handleSetFilter(true);
    }

    // FILTRO DEPARTAMENTO
    const handleSetDepartamento = (e) => {
        setDepartamento(e);
    }

    // FILTRO RESPONSÁVEL
    const handleSetResponsavel = (e) => {
        setResponsavel(e);
    }

    // FILTRO USUÁRIO
    const handleSetUsuario = (e) => {
        setUsuario(e);
    }

    // FILTRO DE STATUS
    const handleSetStatus = (e) => {
        setStatus(e.value);
        
        let filter_aux = [];
        sistemas.map((item, i) => {
            console.log('item: ',item);
            filter_aux.push({
                id_system: item,
                value: e.value
            });
        });

        setStatusAux(filter_aux);
        handleSetFilter(true);
    }

    // OPTIONS FILTER
    const optionsStatus = [
        {value: 'todos', label: 'Todos'},
        {value: 'com_acesso', label: 'Com Acesso'},
        {value: 'sem_acesso', label: 'Sem Acesso'},
        {value: 'sem_registro_de_acesso', label: 'Sem registro de acesso'}
    ]

    // CONSTRÓI AS TH'S
    const thead_aux = [
        { enabled: true, label: 'Departamento', id: 'departamento', name: 'departamento', api: {url: window.host+'/api/sql.php?do=select&component=departamento', params: {empreendimento_id: empreendimento}}, onChange: handleSetDepartamento, reload: (empreendimento+grupo) },
        { enabled: true, label: 'Respon. Depto.', id: 'responsavel_departamento', name: 'responsavel_departamento', api: {url: window.host+'/api/sql.php?do=select&component=usuario&ns=false', params: {empreendimento_id: empreendimento}}, onChange: handleSetResponsavel, reload: (empreendimento+grupo) },
        { enabled: true, label: 'Usuário', id: 'usuario', name: 'usuario', api: {url: window.host+'/api/sql.php?do=select&component=usuario&ns=false', params: {empreendimento_id: empreendimento}}, onChange: handleSetUsuario, reload: (empreendimento+grupo) },
    ]

    useEffect(() => {
        let thead_aux_2 = thead_aux;

        theadAux.map((item, i) => {
            thead_aux_2.push(
                { enabled: true, label: item.nome, id: 'th_'+item.id, name: 'th_'+item.id, minWidth: 110, align: 'center', filter: false }
            );
        })

        setThead(thead_aux_2);
    },[theadAux]);

    // TITLES EXPORT
    useEffect(() => {
        let title_export_aux = {};
        thead.map((item, i) => {
            if (item?.export !== false) {
                title_export_aux[item?.name] = item?.label;
            }
        });

        setTitleExport(title_export_aux);
    },[thead]);

    // URL API TABLE
    const url = window.host_madnezz+'/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess';

    // PARAMS API TABLE
    const params = {        
        filter_id_group: grupo,
        filter_id_emp: [empreendimento],
        filter_id_user: usuario,
        filter_id_department: departamento,
        filter_id_user_responsible_department: responsavel,
        filter_system_permission: (status === 'todos' ? undefined : statusAux),
        limit: 50
    };

    // BODY DO EXPORTADOR
    const body = {
        titles: titleExport,
        url: url.replace('malltech.test','dev.malltech.com.br').replace('http:','https:'),
        name: 'Acessos',
        filters: params
    }

    // MANDA OS FILTROS PRO COMPONENTE PAI
    useEffect(() => {
        if (icons) {
            icons('');
        }

        if (filters) {
            filters(
                <>
                    {/* <SelectReact
                        placeholder="Status"
                        options={optionsStatus}
                        onChange={(e) => handleSetStatus(e)}
                        isRtl={true}
                        allowEmpty={false}
                        value={status}
                    /> */}

                    {/* {(window.rs_id_emp == 26 ?
                        <SelectReact
                            placeholder="Grupo"
                            options={optionsGrupo}
                            onChange={(e) => handleSetGrupo(e)}
                            isRtl={true}
                            allowEmpty={false}
                            value={grupo}
                        />
                    :'')} */}

                    {(optionsEmpreendimento.length > 1 ?
                        <SelectReact
                            placeholder="Empreendimento"
                            options={optionsEmpreendimento}
                            onChange={(e) => handleSetEmpreendimento(e)}
                            isRtl={true}
                            allowEmpty={false}
                            value={empreendimento}
                        />
                    :'')}
                </>
            );
        }
    }, [optionsEmpreendimento, empreendimento, status]);

    return(
        <Table
            id="acessos"
            api={url}     
            key_aux_2={'data'}   
            params={params}     
            thead={thead}
            loadAlign="left"
            onLoad={handleSetItems}
        >
            <Tbody>
                {(items.length > 0 ?
                    items.map((item, i) => {
                        return(
                            <Tr key={'usuario_'+item.id_usuario}>
                                <Td>{(item.departamento ? item.departamento : '-')}</Td>
                                <Td>{(item.responsavel_departamento ? item.responsavel_departamento : '-')}</Td>
                                <Td>{item.usuario}</Td>

                                {(theadAux.map((item_th, i_th) => {
                                    let key_aux = 'sistema_acesso_'+item?.id_usuario+'_'+i_th;

                                    if(item[item_th.id].usuario_acesso_sistema){
                                        if(item[item_th.id].dataHora_ult_acesso){
                                            return(
                                                <Td align="center" title="Último acesso" key={key_aux}>
                                                    <Icon type="check-circle" className="text-success" readonly title={false} /> 
                                                    {get_date('date', item[item_th.id].dataHora_ult_acesso, 'date_sql').slice(0,5)}
                                                </Td>
                                            )
                                        }else{
                                            return(
                                                <Td align="center" key={key_aux}>
                                                    <Icon type="check-circle" title="Sem registro de acesso" className="text-secondary" readonly />
                                                </Td>
                                            )
                                        }
                                    }else{
                                        return(
                                            <Td align="center" key={key_aux}>
                                                <Icon type="times-circle" title="Sem acesso" className="text-danger" readonly />
                                            </Td>
                                        )
                                    }
                                }))}
                            </Tr>
                        )
                    })
                :
                    <></>
                )}
            </Tbody>
        </Table>
    )
}

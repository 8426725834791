import { useState, useEffect } from 'react';

import Icon from "../../../../../components/body/icon";
import Modal from "../../../../../components/body/modal";
import ModalHeader from "../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../../components/body/modal/modalBody";
import Form from '../../../../../components/body/form';
import CheckboxGroup from '../../../../../components/body/form/checkboxGroup';
import SelectReact from '../../../../../components/body/select';
import Input from '../../../../../components/body/form/input';
import Button from '../../../../../components/body/button';
import axios from 'axios';
import toast from 'react-hot-toast';
import InputContainer from '../../../../../components/body/form/inputcontainer';

export default function Editar({item, callback}){
    // ESTADOS
    const [showModal, setShowModal] = useState(false);
    const [formStatus, setFormStatus] = useState(null);

    // ESTADOS DE VALORES
    const [tipo, setTipo] = useState(item?.tipo);
    const [lojas, setLojas] = useState(item?.loja ? item.loja.split(',') : []);
    const [marcas, setMarcas] = useState(item?.marca ? item.marca.split(',') : []);
    const [cargos, setCargos] = useState(item?.cargo ? item.cargo.split(',') : []);
    const [categoria, setCategoria] = useState(item?.categoria_id);
    const [subcategoria, setSubcategoria] = useState(item?.subcategoria_id);
    const [subsubcategoria, setSubSubcategoria] = useState(item?.sub_subcategoria_id);
    const [subsubsubcategoria, setSubSubSubcategoria] = useState(item?.sub_sub_subcategoria_id);
    const [posicao, setPosicao] = useState(item?.posicao);
    const [nome, setNome] = useState(item?.nome);
    const [url, setUrl] = useState(item?.url);
    const [icone, setIcone] = useState(item?.icone);

    // ESTADOS DE OPTIONS
    const [optionsCategoria, setOptionsCategoria] = useState([]);
    const [optionsSubcategoria, setOptionsSubcategoria] = useState([]);
    const [optionsSubSubcategoria, setOptionsSubSubcategoria] = useState([]);
    const [optionsSubSubSubcategoria, setOptionsSubSubSubcategoria] = useState([]);

    // SEMPRE QUE ALTERA O TIPO ZERA A CATEGORIA E SUBS OU URL
    useEffect(() => {
        if(tipo === 'link'){
            setCategoria(null);
            setSubcategoria(null);
            setSubSubcategoria(null);
            setSubSubSubcategoria(null);
        }else if(tipo === 'documento'){
            setUrl(null);
            setNome(null);
        }
    },[tipo]);

    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);
    }

    // GET OPTIONS CATEGORIAS
    function get_categorias(){
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/relatorio.php',
            params: {
                do: 'get_categoria',
                sistema_id: 218,
                limit: false
            }
        }).then((response) => {
            if(response.data){
                setOptionsCategoria(response.data);
            }
        })
    }

    // GET OPTIONS SUBCATEGORIAS
    function get_subcategorias(){
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/relatorio.php',
            params: {
                do: 'get_subcategoria',
                sistema_id: 218,
                limit: false
            }
        }).then((response) => {
            if(response.data){
                setOptionsSubcategoria(response.data);
            }
        })
    }

    // GET OPTIONS SUB-SUBCATEGORIAS
    function get_sub_subcategorias(){
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/relatorio.php',
            params: {
                do: 'get_sub_subcategoria',
                sistema_id: 218,
                limit: false
            }
        }).then((response) => {
            if(response.data){
                setOptionsSubSubcategoria(response.data);
            }
        })
    }

    // GET OPTIONS SUB-SUB-SUBCATEGORIAS
    function get_sub_sub_subcategorias(){
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/relatorio.php',
            params: {
                do: 'get_sub_sub_subcategoria',
                sistema_id: 218,
                limit: false
            }
        }).then((response) => {
            if(response.data){
                setOptionsSubSubSubcategoria(response.data);
            }
        })
    }

    // GET OPTIONS
    useEffect(() => {
        if(showModal){
            get_categorias();
            get_subcategorias();
            get_sub_subcategorias();
            get_sub_sub_subcategorias();
        }
    },[showModal]); 

    // CALLBACK COMPONENTE DE LOJAS
    const handleSetLojas = (e) => {
        setLojas(e);
    }

    // CALLBACK COMPONENTE DE MARCAS
    const handleSetMarcas = (e) => {
        setMarcas(e);
    }

    // CALLBACK COMPONENTE DE CARGOS
    const handleSetCargos = (e) => {
        setCargos(e);
    }

    // DADOS DO FORMULÁRIO
    const data = {
        id: item?.id,
        loja_id: lojas,
        marca_id: marcas,
        cargo_id: cargos,
        categoria_id: categoria,
        subcategoria_id: subcategoria,
        sub_subcategoria_id: subsubcategoria,
        sub_sub_subcategoria_id: subsubsubcategoria,
        url: url,
        nome: nome,
        posicao: posicao,
        icone: icone
    }

    // STATUS DO FORM
    const handleFormStatus = (e) => {
        setFormStatus(e);
    }

    // CALLBACK DO FORM
    const handleFormResponse = () => {
        setShowModal(false);
        
        if(!item){
            setTipo(null);
            setLojas([]);
            setMarcas([]);
            setCargos([]);
            setCategoria(null);
            setSubcategoria(null);
            setSubSubcategoria(null);
            setSubSubSubcategoria(null);
            setPosicao(null);
            setUrl(null);
            setNome(null);
            setIcone(null);
        }

        if(callback){
            callback({
                reload: true
            })
        }

        if(item){
            toast('Atalho editado com sucesso');
        }else{
            toast('Atalho criado com sucesso');
        }
    }

    return(
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <ModalHeader>
                    <ModalTitle>
                        {item ? 'Editar' : 'Novo atalho'} {item ? ' - '+item?.nome_aux : ''}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host+'/homes/api/home7.php?do=set_quickAccess&tipo='+tipo}
                        data={data}
                        status={handleFormStatus}
                        response={handleFormResponse}
                    >
                        <SelectReact
                            id="tipo"
                            name="tipo"
                            label="Tipo"
                            options={[{value: 'link', label: 'Link'}, {value: 'documento', label: 'Documento'}]}
                            value={tipo}
                            allowEmpty={false}
                            onChange={(e) => setTipo(e.value)}
                        />

                        <CheckboxGroup
                            group="loja"
                            required={false}
                            value={lojas}
                            callback={handleSetLojas}
                        />

                        <CheckboxGroup
                            group="marca"
                            required={false}
                            value={marcas}
                            callback={handleSetMarcas}
                        />

                        <CheckboxGroup
                            group="cargo"
                            required={false}
                            value={cargos}
                            callback={handleSetCargos}
                        />

                        {(tipo === 'documento' ?
                            <SelectReact
                                id="categoria"
                                name="categoria"
                                label="Categoria"
                                options={optionsCategoria}
                                value={categoria}
                                allowEmpty={false}
                                onChange={(e) => setCategoria(e.value)}
                            />
                        :'')}

                        {(categoria && tipo === 'documento' ? 
                            <SelectReact
                                id="subcategoria"
                                name="subcategoria"
                                label="Subcategoria"
                                required={false}
                                options={optionsSubcategoria.filter((elem) => elem.aux1 == categoria)}
                                value={subcategoria}
                                onChange={(e) => setSubcategoria(e.value)}
                            />
                        :'')}

                        {(subcategoria && tipo === 'documento' ?
                            <SelectReact
                                id="subsubcategoria"
                                name="subsubcategoria"
                                label="Sub-Subcategoria"
                                required={false}
                                options={optionsSubSubcategoria.filter((elem) => elem.aux1 == subcategoria)}
                                value={subsubcategoria}
                                onChange={(e) => setSubSubcategoria(e.value)}
                            />
                        :'')}

                        {(subsubcategoria && tipo === 'documento' ?
                            <SelectReact
                                id="subsubsubcategoria"
                                name="subsubsubcategoria"
                                label="Sub-Sub-Subcategoria"
                                required={false}
                                options={optionsSubSubSubcategoria.filter((elem) => elem.aux1 == subsubcategoria)}
                                value={subsubsubcategoria}
                                onChange={(e) => setSubSubSubcategoria(e.value)}
                            />
                        :'')}

                        {(tipo === 'link' ?
                            <Input
                                id="nome"
                                name="nome"
                                label="Nome"
                                type="text"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                        :'')}

                        {(tipo === 'link' ?
                            <Input
                                id="url"
                                name="url"
                                label="URL"
                                type="text"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        :'')}

                        <Input
                            id="posicao"
                            name="posicao"
                            label="Posição"
                            type="tel"
                            mask={'999'}
                            maskChar={''}
                            value={posicao}
                            onChange={(e) => setPosicao(e.target.value)}
                        />

                        <InputContainer label="Ícone:">
                            <div className="d-flex align-items-center" style={{gap:8, flexWrap: 'wrap'}}>
                                <Icon type="document" className={icone === 'document' ? 'text-primary' : ''} title={false} onClick={() => setIcone('document')} />
                                <Icon type="external" className={icone === 'external' ? 'text-primary' : ''} title={false} onClick={() => setIcone('external')} />
                                <Icon type="report-money" className={icone === 'report-money' ? 'text-primary' : ''} title={false} onClick={() => setIcone('report-money')} />
                                <Icon type="chart-bar" className={icone === 'chart-bar' ? 'text-primary' : ''} title={false} onClick={() => setIcone('chart-bar')} />
                                <Icon type="table" className={icone === 'table' ? 'text-primary' : ''} title={false} onClick={() => setIcone('table')} />
                                <Icon type="network" className={icone === 'network' ? 'text-primary' : ''} title={false} onClick={() => setIcone('network')} />
                                <Icon type="velocimeter" className={icone === 'velocimeter' ? 'text-primary' : ''} title={false} onClick={() => setIcone('velocimeter')} />
                                <Icon type="speaker" className={icone === 'speaker' ? 'text-primary' : ''} title={false} onClick={() => setIcone('speaker')} />
                                <Icon type="watch" className={icone === 'watch' ? 'text-primary' : ''} title={false} onClick={() => setIcone('watch')} />
                                <Icon type="home" className={icone === 'home' ? 'text-primary' : ''} title={false} onClick={() => setIcone('home')} />
                                <Icon type="contract" className={icone === 'contract' ? 'text-primary' : ''} title={false} onClick={() => setIcone('contract')} />
                                <Icon type="tag" className={icone === 'tag' ? 'text-primary' : ''} title={false} onClick={() => setIcone('tag')} />
                                <Icon type="people-group" className={icone === 'people-group' ? 'text-primary' : ''} title={false} onClick={() => setIcone('people-group')} />
                                <Icon type="person" className={icone === 'person' ? 'text-primary' : ''} title={false} onClick={() => setIcone('person')} />
                                <Icon type="truck" className={icone === 'truck' ? 'text-primary' : ''} title={false} onClick={() => setIcone('truck')} />
                                <Icon type="contract-signature" className={icone === 'contract-signature' ? 'text-primary' : ''} title={false} onClick={() => setIcone('contract-signature')} />
                                <Icon type="stairs" className={icone === 'stairs' ? 'text-primary' : ''} title={false} onClick={() => setIcone('stairs')} />
                                <Icon type="cash" className={icone === 'cash' ? 'text-primary' : ''} title={false} onClick={() => setIcone('cash')} />
                                <Icon type="brandcash" className={icone === 'brandcash' ? 'text-primary' : ''} title={false} onClick={() => setIcone('brandcash')} />
                                <Icon type="hand-shake" className={icone === 'hand-shake' ? 'text-primary' : ''} title={false} onClick={() => setIcone('hand-shake')} />
                                <Icon type="medal" className={icone === 'medal' ? 'text-primary' : ''} title={false} onClick={() => setIcone('medal')} />
                                <Icon type="podium" className={icone === 'podium' ? 'text-primary' : ''} title={false} onClick={() => setIcone('podium')} />
                            </div>
                        </InputContainer>

                        <Button
                            type="submit"
                            status={formStatus}
                            disabled={(lojas.length === 0 && marcas.length === 0 && cargos.length === 0 ? true : false)}
                            title={(lojas.length === 0 && marcas.length === 0 && cargos.length === 0 ? 'Selecione ao menos uma Loja, Marca ou Cargo' : false)}
                        >
                            Salvar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Icon
                type={item ? 'edit' : 'new'}
                onClick={() => setShowModal(!showModal)}           
            />
        </>
    )
}

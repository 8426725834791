import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import Navbarlink from '../../components/header/navbar/navbarLink';
import { decrypt } from '../../_assets/js/global';

// PÁGINAS
import Lista from './Lista';
import DashboardChecklist from './Dashboard';
import Fotos from './Fotos';
import Macro from './Macro';
import { ChecklistProvider } from '../../context/Checklist';
import { JobsProvider } from "../../context/Jobs";
import GerenciadorChecklist from './Gerenciador/Checklist';
import { useState } from 'react';
import axios from 'axios';

export default function Checklist(){
  const params = useParams();    
  const paramsUrl = params['*'].substring(params['*'].indexOf('/')+1);
  const paramsUrlCount = (paramsUrl.match(/\//g)?paramsUrl.match(/\//g).length:0);
  var tokenUrl, idJob;

  if(paramsUrlCount>0){
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    idJob = params['*'].substring(params['*'].lastIndexOf('/') + 1)
  }else{
    tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));
  
  window.idJob = idJob;
  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_grupo = decryptedReturn.id_grupo;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.permission_apl;

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  const [filters, setFilters] = useState(null);

  // DEFINE ÍCONES NAVBAR
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  // DEFINE FILTROS NAVBAR
  const handleSetFilters = (e) => {
    setFilters(e);
  }

  return (
    <>
      <JobsProvider>  
        <ChecklistProvider>
          <Navbar
            filters={filters}
            icons={icons}
          >
            <Navbarlink link={'/systems/checklist-react/lista/'+window.token} name="Lista" icon="relatorio" />
            <Navbarlink link={'/systems/checklist-react/supervisao/'+window.token} name="Supervisão Visual" icon="camera" />   

            {(window.rs_permission_apl > 2 ?
              <Navbarlink link={'/systems/checklist-react/dashboard/'+window.token} name="Dashboard" icon="analytics" />
            :'')}

            {(window.rs_permission_apl > 3 ?
              <Navbarlink link={'/systems/checklist-react/gerenciador/'+window.token} name="Gerenciador" icon="cog" />
            :'')}
          </Navbar>
        
          <Routes>
              <Route path="/:token" index={true} element={ <Navigate to={'lista/'+window.token} replace /> } />
              <Route path="lista/:token" element={ <Lista icons={handleSetIcons} filters={handleSetFilters} /> } />
              <Route path="supervisao/:token" element={ <Fotos icons={handleSetIcons} filters={handleSetFilters} /> } />
              <Route path="macro/:token" element={ <Macro icons={handleSetIcons} filters={handleSetFilters} /> } />

              {(window.rs_permission_apl > 2 ?
                <Route path="dashboard/:token" element={<DashboardChecklist icons={handleSetIcons} filters={handleSetFilters} />} />
              :'')}

              {(window.rs_permission_apl > 3 ?
                <Route path="gerenciador/:token" element={<GerenciadorChecklist icons={handleSetIcons} filters={handleSetFilters} />} />
              :'')}
          </Routes>
        </ChecklistProvider>
      </JobsProvider>
    </>
  );
}

import { useState, useEffect } from 'react';

import Icon from '../../../../../components/body/icon';
import style from '../style.module.scss';
import axios from 'axios';
import Tippy from '@tippyjs/react';
import Loader from '../../../../../components/body/loader';

export default function Pasta({item, index, sidebar, level, callback}){
    // ESTADOS
    const [collapse, setCollapse] = useState(false);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [sidebarAux, setSidebarAux] = useState(sidebar);

    // ABRIR PASTA
    const handleSetCollapse = () => {
        if(collapse){
            setCollapse(false);

            if(items.length == 0){
                setLoading(true);
            }
        }else{
            setCollapse(true);

            if(items.length == 0){
                axios({
                    method: 'get',
                    url: window.host+'/systems/treinamento/api/lista.php',
                    params: {
                        do: 'get_foldersFiles',
                        sistema_id: 218,
                        limit: false,
                        page: 0,
                        level: level + 1,
                        aux_id: item?.folder_id
                    }
                }).then((response) => {
                    if(response.data){
                        setItems(response.data)
                    }
                    
                    setLoading(false);
                });
            }
        }
    }

    // CLIQUE PARA MOSTRAR/ESCONDER SIDEBAR
    const handleCollapseSidebar = (e) => {
        if(callback){
            callback({
                sidebar: e === true ? true : !sidebarAux
            })
        }
    }

    // ATUALIZA ESTADO DA SIDEBAR SEMPRE QUE A PROPS É ALTERADA
    useEffect(() => {
        setSidebarAux(!sidebar);
    },[sidebar]);

    // ATUALIZA ESTADO DO COLAPSE SEMPRE QUE A SIDEBAR SOFRE ALTERAÇÃO
    useEffect(() => {
        if(!sidebarAux){
            setCollapse(false);
        }
    },[sidebarAux]);

    let icon_aux;

    // CRAVADO APENAS PARA MOSTRAR AO CLIENTE, DEPOIS PRECISA SER PROGRAMADO
    if(item.folder_id == 55){
        icon_aux = 'contract-signature';
    }else if(item.folder_id == 61){
        icon_aux = 'stairs';
    }else if(item.folder_id == 57){
        icon_aux = 'cash';
    }else if(item.folder_id == 52){
        icon_aux = 'brandcash';
    }else if(item.folder_id == 60){
        icon_aux = 'home';
    }else if(item.folder_id == 38){
        icon_aux = 'hand-shake';
    }else if(item.folder_id == 59){
        icon_aux = 'medal';
    }else if(item.folder_id == 56){
        icon_aux = 'podium';
    }else if(item.folder_id == 58){
        icon_aux = 'table';
    }else{
        icon_aux = 'document';
    }

    return(
        <>
            <div
                className={style.title + ' cursor-pointer'}
                style={{paddingLeft:level*20}}                
            >         
                <Tippy content={item?.folder_name}>                                        
                    <span onClick={handleSetCollapse}>
                        <Icon
                            type={icon_aux}
                            readonly={(sidebarAux ? true : false)}
                            title={(sidebarAux ? false : item.folder_name)}
                            onClick={() => handleCollapseSidebar(true)}
                        />

                        {(sidebarAux ?                                                     
                            <>{item.folder_name}</>
                        :'')}
                    </span>
                </Tippy>

                {/* SÓ EXIBIE SE FOR O PRIMEIRO ITEM, A SIDEBAR ESTIVER ABERTA E FOR LEVEL 0 (PRIMEIRA CATEGORIA) */}
                {(index == 0 && sidebarAux && level == 0 ?
                    <Icon
                        type={sidebarAux ? 'left' : 'right'}
                        title={sidebarAux ? 'Ocultar' : 'Mostrar'}    
                        onClick={handleCollapseSidebar}                
                    />
                :'')}
            </div>

            {(collapse && sidebarAux ?
                (loading ?
                    <Loader className="mb-4" />
                :
                    items.map((subitem, i) => {
                        if(subitem.file_id){ // SE TIVER FILE ID PRECISA EXIBIR O DOCUMENTO AO INVÉS DA PASTA
                            return(
                                <li
                                    key={'documento_' + subitem?.file_id}
                                    style={{paddingLeft:(level+1)*20}}
                                >
                                    <span>
                                        <Tippy content={subitem.file_name}>
                                            <div>
                                                <a
                                                    href={process.env.REACT_APP_URL_UPLOAD+'/'+subitem.anexo}
                                                    target={'_blank'}
                                                >

                                                    <span>
                                                        {subitem.file_name}
                                                    </span>
                                                </a>
                                            </div>
                                        </Tippy>
                                    </span>
                                </li>
                            )
                        }else{
                            return (
                                <Pasta
                                    key={'pasta_'+subitem?.folder_id}
                                    index={i}
                                    id={subitem?.folder_id}
                                    level={level+1}
                                    item={subitem}
                                />
                            )
                        }                        
                    })
                )
            :'')}
        </>
    )
}

import Modal from "../../../../../components/body/modal";
import ModalHeader from "../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../../components/body/modal/modalBody";
import Table from "../../../../../components/body/table";
import Thead from "../../../../../components/body/table/thead";
import Tbody from "../../../../../components/body/table/tbody";
import Th from "../../../../../components/body/table/thead/th";
import Tr from "../../../../../components/body/table/tr";
import Td from "../../../../../components/body/table/tbody/td";
import axios from "axios";
import { useEffect, useState } from "react";
import Icon from "../../../../../components/body/icon";
import { cd } from "../../../../../_assets/js/global";

export default function ModalDocumentos({ show, onHide, id, id_aux, level, doc_name }) {

    const handleClose = () => {
        onHide(false);
    }


    const [documento, setDocumento] = useState([]);

    function getInfo() {
        axios.get("https://dev.malltech.com.br/systems/treinamento/api/lista.php?&_", {
            params: {
                do: "get_foldersFiles",
                sistema_id: id,
                aux_id: id_aux,
                level: level,
                limit: false
            }
        }).then((response) => setDocumento(response.data))
    }

    useEffect(() => {
        getInfo();
    }, [id_aux])


    return (
        <Modal show={show} onHide={handleClose} large={true} >
            <ModalHeader>
                <ModalTitle>
                    Documento - {doc_name}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>
                                Nome
                            </Th>
                            <Th>
                                Cadastro
                            </Th>
                            <Th>
                                Atualização
                            </Th>
                            <Th align="center" width={1}>
                                Ações
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            documento &&
                            documento.map((item, i) => {
                                return (
                                    <Tr key={item.file_id}>
                                        <Td>{item.file_name}</Td>
                                        <Td>{item.cad !== "-" ? cd(item.cad) : item.cad}</Td>
                                        <Td>{item.cad_update !== "-" ? cd(item.cad_update) : item.cad_update}</Td>
                                        <Td align="center" width={1}>
                                            <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.anexo} target="_blank" rel="noreferrer">
                                                <Icon type="view" />
                                            </a>
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}
import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Container from '../../../components/body/container';
import { decrypt } from '../../../_assets/js/global';
import style from './style.module.scss';
import Documentos from './Documentos';
import Atalhos from './Atalhos';
import KPI from './KPI';
import Busca from './Busca';
import Banner from './Banner';
import News from './News';
import Comunicados from './Comunicados';
import axios from 'axios';

export default function Intranet(){
    const params = useParams();
    const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
    var tokenUrl, tokenUrlAux;

    if (paramsUrlCount > 0) {
        tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
        tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
    } else {
        tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    }

    var decryptedReturn = JSON.parse(decrypt(tokenUrl));

    window.token = tokenUrl;
    axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
    window.rs_id_emp = decryptedReturn.id_emp;
    window.rs_id_usr = decryptedReturn.id_usr;
    window.rs_id_lja = decryptedReturn.id_lja;
    window.rs_name_usr = decryptedReturn.name_usr;
    window.rs_id_apl = decryptedReturn.id_apl;
    window.rs_sistema_id = decryptedReturn.sistema_id;
    window.rs_permission_apl = decryptedReturn.pus;
    window.rs_id_module = decryptedReturn.id_module;
    window.rs_id_office = decryptedReturn.id_office;
    window.rs_id_brand = decryptedReturn.id_brand;

    // ESTADOS
    const [documentosActive, setDocumentosActive] = useState(window.isMobile ? false : true);
    const [search, setSearch] = useState(null);
    const [filterNews, setFilterNews] = useState(false);
    const [kpiPermission, setKpiPermission] = useState(0);
    const [newsPermission, setNewsPermission] = useState(0);

    // CALLBACK DOCUMENTOS
    const handleCallbackDocumentos = (e) => {
        setDocumentosActive(e.active);
    }

    // CALLBACK BUSCA
    const handleCallbackBusca = (e) => {
        setSearch(e.search);
        setFilterNews(e.news);
    }

    // FUNÇÃO QUE LIMPA FILTRO DA NEWS
    const handleClearBusca = (e) => {
        if(e?.filter === false){
            setFilterNews(false);
            setSearch(null);
        }else{
            setFilterNews(e?.news);
        }
    }

    //VERIFICA SE O USUÁRIO TEM ACESSO AO SISTEMA VENDAS
    useEffect(() => {
        let vendas_id = 56;
        let news_id = 187;

        axios({
            method: 'get',
            url: window.host+'/api/permissoes.php',
            params: {
                do: 'get_acesso',
                id_usuario:window.rs_id_usr,
                id_sistema: [vendas_id, news_id]
            }
        }).then((response) => {   
            if(response?.data){
                setKpiPermission(response.data?.filter((elem) => elem.sistema_id == vendas_id)[0]?.permissao);
                setNewsPermission(response.data?.filter((elem) => elem.sistema_id == news_id)[0]?.permissao);
            }
        });
    },[]);

    return(
        <Container>
            <div className={style.container}>
                <div className={style.sidebar}>
                    <Documentos
                        // search={search}
                        callback={handleCallbackDocumentos}
                    />
                </div>
                <div className={style.content + ' ' + (documentosActive ? '': style.large)}>
                    <Atalhos
                        // search={search}
                    />

                    {(kpiPermission > 0 ?
                        <KPI />
                    :'')}

                    <Busca
                        search={search}
                        callback={handleCallbackBusca}
                    />

                    <Banner />

                    {(window.isMobile ?
                        <Comunicados
                            // search={search}
                        />
                    :'')}

                    <div className={style.container_news}>
                        {(newsPermission > 0 ?
                            <News
                                search={filterNews ? search : undefined}
                                filter={filterNews}
                                permission={newsPermission}
                                callback={handleClearBusca}
                            />
                        :'')}
                        
                        {(!window.isMobile ?
                            <Comunicados
                                // search={search}
                            />
                        :'')}
                    </div>
                </div>
            </div>
        </Container>
    )
}

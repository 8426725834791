import { useEffect, useState } from 'react';

/*SWIPER*/
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import style from './style.module.scss';
import banner from '../../../../_assets/img/hs_telecom.jpg'; // PROVISÓRIO
import axios from 'axios';

export default function Banner(){
    // ESTADOS
    const [swiper, setSwiper] = useState();
    const [items, setItems] = useState([]);

    // GET BANNERS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host+'/systems/banner/api/banner.php',
            params: {
                do: 'get'
            }
        }).then((response) => {
            if(response.data){
                setItems(response.data);
            }
        })
    },[]);

    if(items.length > 0){
        return (
            <div className={style.banner}>
                <Swiper
                    ref={swiper}
                    focusableElements="input, select, div, button, label, option, textarea"
                    preventClicks={false}
                    modules={[Autoplay, Pagination]}
                    autoHeight={false}
                    speed={1000}
                    autoplay={{
                        delay: 3000,
                    }}
                    spaceBetween={24}
                    onSwiper={(swiper) => setSwiper(swiper)}
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                    className="swiper_banner"
                >
                    {(items.map((item, i) => {
                        return(
                            <SwiperSlide key={'banner_'+item?.id}>
                                <img src={process.env.REACT_APP_URL_UPLOAD+'/'+item?.anexo} />
                            </SwiperSlide>
                        )
                    }))}
                </Swiper>
            </div>
        )
    }
}

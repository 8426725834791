import { useEffect, useState, useContext } from 'react';
import { TreinamentoContext } from "../../../context/Treinamento";
import { cdh } from '../../../_assets/js/global';
import style from './lista.module.scss';

import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import SelectReact from "../../../components/body/select";
import Title from "../../../components/body/title";
import Button from "../../../components/body/button"
import Icon from "../../../components/body/icon";
import CardContainer from "../components/productCard/cardContainer";
import ImageCard from "../components/productCard/imageCard";
import TitleDiv from "../components/productCard/titleDiv";
import ModalTreinamento from '../ModalCadastro';
import CalendarFilter from '../../../components/body/calendarTitle/calendarFilter';
import CalendarTitle from '../../../components/body/calendarTitle';
import Treinamento from '../Treinamento';
import Breadcrumbs from '../components/breadcrumbs';
import Breadcrumb from '../components/breadcrumbs/breadcrumb';
import Sidebar from '../components/sidebar';
import PageEmpty from '../../../components/body/pageEmpty';
import axios from 'axios';

export default function Lista() {
    // CONTEXT
    const {
        view,
        level,
        handleSetLevel,
        handleCurso,
        cursoInfo,
        handleSetCursoInfo,
        cursoNome,
        handleSetCursoNome,
        handleSetCursoId,
        filterName
    } = useContext(TreinamentoContext);

    // FILTROS
    const [filterStatus, setFilterStatus] = useState(-1);
    const [filterTipo, setFilterTipo] = useState(-1);
    const [filterStatusCurso, setFilterStatusCurso] = useState(1);

    // BREADCRUMBS
    const [categoriaNome, setCategoriaNome] = useState('');
    const [categoriaId, setCategoriaId] = useState('');
    const [subcategoriaNome, setSubcategoriaNome] = useState('');
    const [subcategoriaId, setSubcategoriaId] = useState('');
    const [subsubcategoriaNome, setSubsubcategoriaNome] = useState('');
    const [subsubcategoriaId, setSubsubcategoriaId] = useState('');
    const [subsubsubcategoriaNome, setSubsubsubcategoriaNome] = useState('');
    const [subsubsubcategoriaId, setSubsubsubcategoriaId] = useState('');

    // ESTADOS
    const [loading, setLoading] = useState(true);
    const [itemId, setItemID] = useState('');
    
    // MODAL
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);

    // ESTADO QUE RECEBE INFORMAÇÕES DA API E RENDERIZA NO CARDCONTAINER
    const [treinamentoCard, setTreinamentoCard] = useState([]);        

    //CONSUMO DA API
    function loadTreinamento(level) {
        setTreinamentoCard([]);
        setLoading(true);

        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/lista.php?do=get_foldersFiles',
            params:{
                level: level,
                aux_id: itemId,
                filter_search: filterName,
                status: (filterStatus == -1 ? '' : filterStatus),
                tipo_treinamento: (filterTipo == -1 ? '' : filterTipo),
                status_curso: (filterStatusCurso == -1 ? '' : filterStatusCurso),
            }
        }).then((response) => {
            setTreinamentoCard(response.data);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadTreinamento(level);
    }, [filterName, filterStatus, filterTipo, filterStatusCurso, itemId]);

    useEffect(() => {
        handleSetCursoInfo(false);
        handleSetLevel(0);
        setCategoriaNome('');
        setSubcategoriaNome('');
        setSubsubcategoriaNome('');
        setSubsubsubcategoriaNome('');
        handleSetCursoNome('');
    }, [filterName]);

    //FUNÇÃO PARA MUDAR O LEVEL 
    function handleFolders(levelClicked, aux_id, nome) {
        handleSetLevel(levelClicked);
        // loadTreinamento(levelClicked, aux_id);
        setItemID(aux_id);

        if((level)==0){
            setCategoriaNome(nome);
            setCategoriaId(aux_id);
        }else if((level)==1){
            setSubcategoriaNome(nome);
            setSubcategoriaId(aux_id);
        }else if((level)==2){
            setSubsubcategoriaNome(nome);
            setSubsubcategoriaId(aux_id);
        }else if((level)==3){
            setSubsubsubcategoriaNome(nome);
            setSubsubsubcategoriaId(aux_id);
        }
    }

    //ESTADO QUE SALVA O NOME DO LEVEL 
    const [levelName, setLevelName] = useState([]);
    function menuTitle() {
        setLevelName([...levelName, levelName])
    }

    // SCROLL TO TOP MOBILE
    useEffect(() => {
        if(window.isMobile){
            window.scrollTo(0, 0);
            document.getElementById('breadcrumbs').scrollTo(1000, 0);
        }
    },[categoriaId, subcategoriaId, subsubcategoriaId, subsubsubcategoriaId, itemId])

    return (
        <>
            <ModalTreinamento show={show} handleShow={handleShow} />

            {(!cursoInfo ?
                <CalendarTitle>
                    <CalendarFilter margin={false} align="center">
                        <SelectReact
                            value={filterStatus}
                            options={[
                                { value: -1, label: 'Todos os status'},
                                { value: 1, label: "Concluído" },
                                { value: 2, label: "Pendente" }
                            ]}
                            onChange={(e) => setFilterStatus(e.value)}
                        />
                        <SelectReact
                            value={filterTipo}
                            options={[
                                { value: -1, label: "Todos os tipos" },
                                { value: 1, label: "Convocação" },
                                { value: 2, label: "Convite" }
                            ]}
                            onChange={(e) => setFilterTipo(e.value)}
                        />
                        {(window.rs_permission_apl === 'administracao' || window.rs_permission_apl === 'master' ? // FILTRO DE PUBLICAÇÃO E BOTÃO "NOVO" SOMENTE PARA QUEM TEM PERMISSÃO MAIOR QUE "GERENTE"
                            <>
                                <SelectReact
                                    value={filterStatusCurso}
                                    options={[
                                        { value: -1, label: "Todos" },
                                        { value: 1, label: "Publicado" },
                                        { value: 2, label: "Rascunho" }
                                    ]}
                                    onChange={(e) => setFilterStatusCurso(e.value)}
                                />
                                {(!window.producao ? 
                                    <Button onClick={handleShow}>Novo</Button>
                                :'')}
                            </>
                        :'')}
                    </CalendarFilter>
                </CalendarTitle>
            :'')}

            {(view === 1 ? // SE ESTIVER FORA DO QUESTIONÁRIO, MOSTRA BREADCRUMBS
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs title="Local">
                            <Breadcrumb onClick={() => (
                                handleFolders(0, ''),
                                setCategoriaNome(''),
                                setSubcategoriaNome(''),
                                setSubsubcategoriaNome(''),
                                setSubsubsubcategoriaNome(''),
                                handleSetCursoNome(''),
                                handleSetCursoId(''),
                                handleSetCursoInfo('')
                            )}>
                                Treinamento
                            </Breadcrumb>
                            
                            {(categoriaNome ?
                                <Breadcrumb onClick={() => (
                                    handleFolders(1, categoriaId),
                                    setSubcategoriaNome(''),
                                    setSubsubcategoriaNome(''),
                                    setSubsubsubcategoriaNome(''),
                                    handleSetCursoNome(''),
                                    handleSetCursoId(''),
                                    handleSetCursoInfo('')
                                )}>
                                    {categoriaNome}
                                </Breadcrumb>
                            :'')}

                            {(subcategoriaNome ?
                                <Breadcrumb onClick={() => (
                                    handleFolders(2, subcategoriaId),
                                    setSubsubcategoriaNome(''),
                                    setSubsubsubcategoriaNome(''),
                                    handleSetCursoNome(''),
                                    handleSetCursoId(''),
                                    handleSetCursoInfo('')
                                )}>
                                    {subcategoriaNome}
                                </Breadcrumb>
                            :'')}

                            {(subsubcategoriaNome ?
                                <Breadcrumb onClick={() => (
                                    handleFolders(3, subsubcategoriaId),
                                    setSubsubsubcategoriaNome(''),
                                    handleSetCursoNome(''),
                                    handleSetCursoId(''),
                                    handleSetCursoInfo('')
                                )}>
                                    {subsubcategoriaNome}
                                </Breadcrumb>
                            :'')}

                            {(subsubsubcategoriaNome ?
                                <Breadcrumb onClick={() => (
                                    handleFolders(4, subsubsubcategoriaId),
                                    handleSetCursoNome(''),
                                    handleSetCursoId(''),
                                    handleSetCursoInfo('')
                                )}>
                                    {subsubsubcategoriaNome}
                                </Breadcrumb>
                            :'')}

                            {(cursoNome ?
                                <Breadcrumb>
                                    {cursoNome}
                                </Breadcrumb>
                            :'')}
                        </Breadcrumbs>
                    </Col>
                </Row>
            :'')}

            <Row>
                <Col lg={(cursoInfo ? 12 : 9)}>
                    {(!cursoInfo ?
                        <div className={style.containerLista+' mb-4 mb-lg-0'}>
                            {(treinamentoCard.length>0 ?
                                treinamentoCard.map((item) => {
                                    return (
                                        <CardContainer
                                            loading={loading}
                                            key={item.id}
                                            onClick={() => {
                                                item.curso_id ?
                                                    handleCurso(
                                                        item.curso_id,
                                                        item.nome
                                                    )
                                                :
                                                    handleFolders(
                                                        level + 1,
                                                        item.id,
                                                        item.nome
                                                    )
                                                }
                                            }>
                                            {item.curso_id ?
                                                <div className={style.edit}>
                                                    {/* {(window.rs_permission_apl === 'administracao' || window.rs_permission_apl === 'master' ? // BOTÃO DE EDITAR SOMENTE SE O USUÁRIO TIVER PERMISSÃO MAIOR QUE "GERENTE"
                                                        <div className={style.cardBtn}>
                                                            <Icon type="editar" />
                                                        </div>
                                                    :'')} */}
                                                    {(item.data_finalizado ?
                                                        <div className={style.date + ' ' + style.date__green}>
                                                            <Icon type="aprovar" />
                                                            <span>Concluído - {cdh(item.data_finalizado)}</span>
                                                        </div>
                                                    :'')}
                                                </div>
                                                : " "
                                            }
                                            <ImageCard image={item.capa ? process.env.REACT_APP_URL_UPLOAD+`/` + item.capa : process.env.REACT_APP_URL_UPLOAD+"/ffba96e5ace17ead8660359897db44a0?v1=10.16"} />
                                            <TitleDiv onClick={() => {menuTitle(item.nome)}}>
                                                <Title wrap={true}>{item.nome}</Title>
                                            </TitleDiv>
                                        </CardContainer>
                                    )
                                })
                            :
                                (!loading ?
                                    <PageEmpty button={false}/>
                                :
                                    [...Array(8)].map((card, i) => {
                                        return(
                                            <CardContainer
                                                empty={true}
                                                loading={true}
                                            ></CardContainer>
                                        )
                                    })
                                )
                            )}
                        </div>
                    :
                        <Treinamento curso={cursoInfo}
                    />)}
                </Col>
                {(!cursoInfo ?
                    <Col lg={3}>
                        <Sidebar />
                    </Col>
                :'')}
            </Row>
        </>
    )
}
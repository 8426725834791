import { Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { GerenciadorProvider } from '../../context/Gerenciador';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import Icon from '../../components/body/icon';
import { decrypt } from '../../_assets/js/global';
import axios from 'axios';

// PÁGINAS
import Lista from './Lista';
import Manual from './Manual';
import Lancamento from './Lancamento';
import CardsGis from './Cards';

export default function Gis() {
    const params = useParams();
    const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
    var tokenUrl, tokenUrlAux;

    if (paramsUrlCount > 0) {
        tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
        tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
    } else {
        tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    }

    // PROVISÓRIO
    // var decryptedReturn = JSON.parse(decrypt(tokenUrl));
    // window.rs_id_emp = decryptedReturn.id_emp;
    // window.rs_id_usr = decryptedReturn.id_usr;
    // window.rs_id_lja = decryptedReturn.id_lja;
    // window.rs_name_usr = decryptedReturn.name_usr;
    // window.rs_id_apl = decryptedReturn.id_apl;
    // window.rs_sistema_id = decryptedReturn.sistema_id;
    // window.rs_permission_apl = decryptedReturn.pus;
    // window.rs_id_module = decryptedReturn.id_module;
    // window.rs_id_office = decryptedReturn.id_office;

    const location = useLocation();

    // ESTADOS (ÍCONES E FILTROS)
    const [icons, setIcons] = useState(null);
    const [filters, setFilters] = useState(null);

    // DEFINE ÍCONES NAVBAR
    const handleSetIcons = (e) => {
        setIcons(e);
    }

    // DEFINE FILTROS NAVBAR
    const handleSetFilters = (e) => {
        setFilters(e);
    }


    return (
        <GerenciadorProvider>
            <Navbar
                icons={
                    (icons)
                }
                filters={
                    (filters)
                }
            >
                <NavbarLink link={'cards/' + window.token} name="Cards" icon="document" />
                <NavbarLink link={'lancamento/' + window.token} name="Lançamento Gis" icon="calendar" />
                
                {( /* permissão administrador */
                    window.rs_permission_apl === '' ?
                        <NavbarLink link={'lista/' + window.token} name="Acompanhamento" icon="analytics" active={true} />
                        : <></>
                )}
                {/* <NavbarLink link={'manual/' + window.token} name="Manual" icon="relatorio" /> */}
            </Navbar>

            <Container>
                <Routes>
                    <Route path="/:token" index element={<Navigate to={'lancamento/' + window.token} replace />} />
                    <Route path="lancamento/:token" element={<Lancamento icons={handleSetIcons} filters={handleSetFilters} />} />
                    <Route path="lista/:token" index element={<Lista icons={handleSetIcons} filters={handleSetFilters} />} />
                    <Route path="manual/:token" element={<Manual icons={handleSetIcons} filters={handleSetFilters} />} />
                    <Route path="cards/:token" element={<CardsGis icons={handleSetIcons} filters={handleSetFilters} />} />
                </Routes>
            </Container>
        </GerenciadorProvider>
    );
}

import { useState } from 'react';

import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import { decrypt } from '../../_assets/js/global';
import axios from 'axios';

// PÁGINAS
import Acesso from './Acesso';
import Posvenda from './Posvenda';
import Contrato from './Contrato';
import Relatorio from './Relatorio';
import DashboadAcesso from './Dashboard';

export default function Cockpit() {
  const params = useParams();
  const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
  var tokenUrl, tokenUrlAux;

  if (paramsUrlCount > 0) {
    tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
  } else {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.token;
  window.rs_id_emp = decryptedReturn.id_emp;
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;
  window.rs_id_office = decryptedReturn.id_office;

  // FILTROS E ÍCONES
  const [filters, setFilters] = useState(null);
  const [icons, setIcons] = useState(null);

  // SETA FILTROS
  const handleSetFilters = (e) => {
    setFilters(e);
  }
  // SETA FILTROS
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  return (
    <>
      <Navbar
        filters={filters}
        icons={icons}
      >
        <NavbarLink link={'acesso/' + window.token} name="Acesso/Uso" icon="trend" active={true} />
        {/* <NavbarLink link={'posvenda/' + window.token} name="Pós-Venda" icon="like" active={true} /> */}
        <NavbarLink link={'relatorio/' + window.token} name="Relatório" icon="relatorio" />
        <NavbarLink link={'dashboard/' + window.token} name="Dashboard" icon="analytics" />

        {(!window.producao && window.rs_id_emp == 26 ?
          <NavbarLink link={'contrato/' + window.token} name="Contrato" icon="document" active={true} />
          : '')}
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index element={<Navigate to={'acesso/' + window.token} replace />} />
          <Route path="acesso/:token" index element={<Acesso filters={handleSetFilters} icons={handleSetIcons} />} />
          {/* <Route path="posvenda/:token" index element={<Posvenda filters={handleSetFilters} icons={handleSetIcons} />} /> */}
          <Route path="relatorio/:token" index element={<Relatorio filters={handleSetFilters} icons={handleSetIcons} />} />
          <Route path="dashboard/:token" index element={<DashboadAcesso filters={handleSetFilters} icons={handleSetIcons} />} />

          {(!window.producao && window.rs_id_emp == 26 ?
            <Route path="contrato/:token" index element={<Contrato filters={handleSetFilters} />} />
            : '')}
        </Routes>
      </Container>
    </>
  );
}

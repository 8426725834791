import { useState, useContext, useEffect } from 'react';

import Chat from '../../chat';
import Icon from '../../icon';
import style from '../Card.module.scss';
import axios from 'axios';
import { GlobalContext } from '../../../../context/Global';
import Message from '../../chat/message';
import { cd, cdh, get_date } from '../../../../_assets/js/global';
import Loader from '../../loader';
import TrocaOperador from '../trocaOperador';
import Tippy from '@tippyjs/react';
import Input from '../../form/input';
import TrocaStatus from '../trocaStatus';
import { JobsContext } from '../../../../context/Jobs';

export default function Mensagens({ props, job, callback, changeOperator, id_job2, id_job_status2, refreshCard, minimized, status }) {
    // CONTEXT JOBS
    const { optionsStatus } = useContext(JobsContext);

    // VARIÁVEIS
    let id_job_status, id_job, loja, usuario, data_formatada, chat_id, title, id_card_status;

    if (job) { // SE RECEBER A PROPS "JOB" PEGA AS INFORMAÇÕES DO QUE ESTÁ SENDO PASSADO
        id_job_status = job?.id_job_status;
        id_job = job?.id_job;
        loja = job?.loja;
        usuario = job?.usuario;
        data_formatada = job?.data_formatada;
        chat_id = job?.id_job_status;
        title = job?.job;
        id_card_status = job?.id_card_status;
    } else {
        id_job_status = props?.parent?.id_job_status;
        id_job = props?.parent?.id_job;
        loja = props?.parent?.loja;
        usuario = props?.parent?.usuario;
        data_formatada = props?.parent?.data_formatada;
        chat_id = props.chat?.id;
        title = props?.parent?.job;
        id_card_status = props?.parent?.id_card_status;
    }

    // GLOBAL CONTEXT
    const { filterModule } = useContext(GlobalContext);

    // ESTADOS
    const [viewHistory, setViewHistory] = useState(false);
    const [messages, setMessages] = useState([]);
    const [anexos, setAnexos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState((data_formatada ? new Date(get_date('date_sql', get_date('date', data_formatada, 'date_add_day', 1))) : ''));
    const [adjustSize, setAdjustSize] = useState(false);

    // MANDA O COMANDO DE RELOAD PRO COMPONENTE PAI
    const reloadChat = (e) => {
        get_messages(false);
    }

    // GET MESSAGES
    function get_messages(loading) {
        if (loading) {
            setLoading(true);
        }

        axios.get(window.host_madnezz + '/systems/integration-react/api/list.php?do=get_msg&filter_id_module=' + filterModule + '&id=' + id_job_status).then((response) => {
            setMessages(response.data);

            setTimeout(() => {
                var divChat = document.getElementById('chat_' + id_job_status + '_maximized');

                if (divChat) {
                    divChat.scrollTop = divChat.scrollHeight;
                }
            }, 50);

            // CONCATENA ANEXOS
            if (response.data) {
                var messageFiles = [];
                response.data.map((mensagem, i) => {
                    if (mensagem.anexos) {
                        mensagem.anexos.split(',').map((item, i) => {
                            if (!anexos.includes(item)) {
                                messageFiles.push(item);
                            }
                        });
                    }
                });

                if (messageFiles) {
                    if (anexos.length > 0) {
                        anexos.map((anexo, i) => {
                            messageFiles.push(anexo);
                        });
                    }
                }

                setAnexos(messageFiles);
                setLoading(false);
            }
        });
    }

    // VISUALIZAR HISTÓRICO
    function handleSetViewHistory() {
        setViewHistory(!viewHistory);

        if (!viewHistory) {
            setTimeout(() => {
                if (callback) {
                    callback({
                        offsetLeft: document.getElementById('card_historico_' + id_job_status).offsetLeft
                    });
                }
            }, 100);
        }
    }

    // MINIMIZAR HISTÓRICO
    function handleSetMinimize() {
        if (minimized) {
            minimized(id_job_status);
        }
    }

    // TROCA DE OPERADOR
    const handleChangeOperator = () => {
        get_messages();
        refreshCard(id_job_status);

        if (changeOperator) {
            changeOperator();
        }
    }

    //Corrigir o cabeçalho do Chat
    let responsavel;

    if (loja && usuario) {
        responsavel = loja + ' - ' + usuario;
    } else if (loja || usuario) {
        responsavel = (loja || usuario);
    } else if (!loja || !usuario) {
        responsavel = '';
    }

    // GET MESSAGES AO INICIAR
    useEffect(() => {
        get_messages(true);
    }, []);

    // ROLAR SCROLL HISTÓRICO PRO FINAL SEMPRE QUE ATUALIZAR O VALOR
    useEffect(() => {
        if (viewHistory) {
            let div = document.getElementById('card_historico_' + id_job_status);
            div.scrollTop = div.scrollHeight;
        }
    }, [viewHistory, messages]);

    let defaultValue_aux;
    if (messages.filter((elem) => elem.log_ativ === 'Enviou uma mensagem').length > 0) {
        defaultValue_aux = messages.filter((elem) => elem.log_ativ === 'Enviou uma mensagem')[messages.filter((elem) => elem.log_ativ === 'Enviou uma mensagem').length - 1]?.mensagem
    } else {
        if (job?.descricao) {
            defaultValue_aux = job?.descricao;
        } else {
            defaultValue_aux = '';
        }
    }

    // FUNÇÃO PARA ADIAR CARD
    function setDate(ativ_desc, id_job_status, date) {
        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/list.php?do=set_status&filter_id_module=" + filterModule,
            data: {
                ativ_desc: ativ_desc,
                id_mov: id_job_status,
                status: 4,
                postpone_date: cd(date),
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            get_messages(false);
        });
    }

    // VERIFICA SE O CARD ESTÁ ATRASADO
    let atrasado = false;

    if (get_date('date_sql', cd(data)) < window.currentDateWithoutHour && (status == 0 || status == 4)) {
        atrasado = true;
    }

    // AJUSTA TAMANHO BODY SE TIVER O SELECT DE STATUS
    const handleAdjustSize = (e) => {
        if (e.enabled) {
            setAdjustSize(true);
        }
    }

    //VERIFICAR STATUS PARA ESCOLHER A COR DO HEADER DA MENSAGEM
    let bg_aux
    let background_aux
    if (props.fases === true) {
        if (status === 0 && job?.data_job === undefined ? get_date('datetime_sql', cd(data)) : job?.data_job > get_date('datetime_sql', new Date())) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'nao_feito';
            })[0]?.cor;
        } else if (status === 0 || status === 4 && get_date('datetime_sql', cd(data)) < get_date('datetime_sql', new Date())) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'atrasado'
            })[0]?.cor;
        } else if (status === 1) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'feito';
            })[0]?.cor;
        } else if (status === 2) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'nao_tem';
            })[0]?.cor;
        } else if (status === 3) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'feito_com_atraso';
            })[0]?.cor;
        } else if (status === 5) {
            bg_aux = optionsStatus.filter((el) => {
                return el.status_name == 'feito_com_ressalva';
            })[0]?.cor;
        } else {
            bg_aux = '';
        }
    };

    switch (bg_aux) {
        case 'blue':
            background_aux = style.bg__blue;
            break;
        case 'orange':
            background_aux = style.bg__orange;
            break;
        case 'dark_orange':
            background_aux = style.bg__dark_orange;
            break;
        case 'yellow':
            background_aux = style.bg__yellow;
            break;
        case 'green':
            background_aux = style.bg__green;
            break;
        case 'red':
            background_aux = style.bg__red;
            break;
        case 'dark':
            background_aux = style.bg__dark;
            break;
        case 'light_gray':
            background_aux = style.bg__light_gray;
            break;
        case 'dark_gray':
            background_aux = style.bg__dark_gray;
            break;
        case 'purple':
            background_aux = style.bg__purple;
            break;
        default:
            background_aux = '';
    }


        return (
            <div className="d-flex align-items-stretch h-100" style={{ gap: 24 }}>
                <div className={style.card__maximized_box}>
                    <div className={style.card__maximized_header + ' ' + (background_aux ? background_aux : atrasado ? style.bg_red : '')}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between" style={{ gap: 15 }}>
                                <Tippy content={title}>
                                    <span>{title}</span>
                                </Tippy>

                                {(props?.internal?.params && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') ?
                                    <TrocaOperador
                                        label={(responsavel ? '' : 'Trocar de operador')}
                                        placeholder={responsavel}
                                        options={props?.internal?.params?.options}
                                        fases={props?.fases}
                                        chamados={props?.chamados}
                                        visitas={props?.visitas}
                                        margin={false}
                                        params={{
                                            id_modulo: job?.id_modulo,
                                            id_job2: id_job2,
                                            id_job_status2: id_job_status2,
                                            id_job: id_job,
                                            id_job_status: id_job_status,
                                            tipo_fase: job?.tipo_fase,
                                            ativ_desc: props?.internal?.params?.ativ_desc,
                                            filterModule: props?.internal?.params?.filterModule,
                                            filter_subtype: props?.internal?.params?.filter_subtype
                                        }}
                                        onChange={handleChangeOperator}
                                    />
                                    : responsavel)}

                                {((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') ?
                                    <Input
                                        placeholder="Data"
                                        label={false}
                                        icon={false}
                                        background={false}
                                        border={false}
                                        padding={false}
                                        type="date"
                                        name="adiar"
                                        className="me-3"
                                        required={false}
                                        value={data}
                                        onChange={(e) => (
                                            setDate("Adiou o job de " + data_formatada + " para " + cd(e), id_job_status, e),
                                            setData(e)
                                        )}
                                    />
                                    :
                                    <Tippy content={data_formatada}>
                                        <span className="pe-3">{data_formatada}</span>
                                    </Tippy>
                                )}
                            </div>

                            <span>
                                <span className="me-2">
                                    <Icon
                                        type="minus"
                                        title="Minimizar"
                                        className=" "
                                        onClick={() => handleSetMinimize()}
                                    />
                                </span>

                                <Icon
                                    type="view"
                                    className={(viewHistory ? 'text-primary' : '')}
                                    title={(viewHistory ? 'Ocultar histórico' : 'Ver histórico')}
                                    onClick={() => handleSetViewHistory()}
                                    animated
                                />
                            </span>
                        </div>
                    </div>

                    <div className={style.card__maximized_body + ' ' + (adjustSize ? style.adjust_size : '')}>
                        <Chat
                            api={props.chat?.api}
                            id={chat_id}
                            data={props.chat?.data}
                            border={false}
                            send={(props.chat?.send === false ? false : true)}
                            editor={{
                                size: true,
                                font: true
                            }}
                            anexo={(props?.chat?.anexo === false ? false : { multiple: true })}
                            callback={reloadChat}
                            defaultValue={defaultValue_aux}
                        />

                        {(props?.internal?.params && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master') ?
                            <TrocaStatus
                                id_mov={id_job_status}
                                id_job={id_job}
                                value={id_card_status}
                                menuPlacement={'top'}
                                className="mt-3"
                                callback={handleAdjustSize}
                            />
                            : '')}
                    </div>
                </div>

                {(viewHistory ?
                    <div className={style.card__maximized_box} id={'card_historico_' + id_job_status}>
                        <div className={style.card__maximized_header}>
                            <div className="d-flex align-items-center justify-content-between">
                                <span>Mensagens</span>
                            </div>
                        </div>
                        <div className={style.card__maximized_body}>
                            <Chat
                                id={chat_id + '_maximized'}
                                send={false}
                                border={false}
                                empty={(messages.length > 0 || props.chat?.defaultMessage ? false : true)}
                                defaultMessage={(!loading ? props.chat?.defaultMessage : '')}
                            >
                                {(loading ?
                                    <Loader />
                                    :
                                    messages?.map((message, i) => {
                                        return (
                                            <Message
                                                key={'message_' + message.id}
                                                sender={message.name_usr}
                                                date={cdh(message.cad)}
                                                text={
                                                    message.mensagem + (message.motivo ? '\nMotivo: ' + message.motivo + '' : '')
                                                }
                                                files={
                                                    (message.anexos ?
                                                        (message?.anexos.includes('{') ?
                                                            JSON.parse(message?.anexos).map((item, i) => {
                                                                return (
                                                                    <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item.id} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                                        {item.name}
                                                                        <Icon type="external" />
                                                                    </a>
                                                                )
                                                            })
                                                            :
                                                            message?.anexos.split(',').map((item, i) => {
                                                                return (
                                                                    <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                                        Arquivo {i + 1}
                                                                        <Icon type="external" />
                                                                    </a>
                                                                )
                                                            })
                                                        )
                                                        : '')
                                                }
                                                align={(window.rs_id_usr == message.id_usr ? 'right' : 'left')}
                                            />
                                        )
                                    })
                                )}
                            </Chat>
                        </div>
                    </div>
                    : '')}
            </div>
        )
    }

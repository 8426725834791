import axios from "axios";
import { useEffect, useState } from "react";
import style from './style.module.scss';
import Tippy from "@tippyjs/react";
import Icon from "../../../../components/body/icon";
import Modal from "../../../../components/body/modal";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../components/body/modal/modalBody";
import Table from "../../../../components/body/table";
import Thead from "../../../../components/body/table/thead";
import Tr from "../../../../components/body/table/tr";
import Th from "../../../../components/body/table/thead/th";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import { cd } from "../../../../_assets/js/global";
import Loader from "../../../../components/body/loader";
import Editar from "./Editar";
import toast from "react-hot-toast";

export default function Atalhos({search}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [height, setHeight] = useState(window.isMobile ? 158 : 109);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [documentoInfo, setDocumentoInfo] = useState([]);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [modalTitle, setModalTitle] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [url, setUrl] = useState(null);

    // BUSCA ATALHOS
    function get_atalhos(loading){
        if(loading !== false){
            setLoading(true);
        }

        axios({
            method: 'get',
            url: window.host+'/homes/api/home7.php',
            params: {
                do: 'get_quickAccess',
                filter_multiple_columns: search
            }
        }).then((response) => {
            setLoading(false);
            
            if(response.data){
                setItems(response.data);
            }
        });
    }

    // CHAMA A FUNÇÃO DE BUSCA SEMPRE QUE SOFRE ALTERAÇÃO NA PROPS SEARCH
    useEffect(() => {
        get_atalhos();
    },[search]);

    // VERIFICA TAMANHO DO BOX DOS ATALHOS
    useEffect(() => {
        let height_item = 0;
        let height_container = 0;
        
        if(document.getElementsByClassName('atalho').length > 0){
            if(window.isMobile){
                if(document.getElementsByClassName('atalho').length <= 4){
                    height_item = document.getElementsByClassName('atalho')[0].offsetHeight + 16;
                }else{
                    height_item = (document.getElementsByClassName('atalho')[0].offsetHeight + 16) * 2;
                }
            }else{
                height_item = document.getElementsByClassName('atalho')[0].offsetHeight + 14;
            }
        }

        height_container = document.getElementById('atalhos_container').offsetHeight + 14;

        if(show){
            if(items.length == 0){
                setHeight(height_item);
            }else{
                setHeight(height_container);
            }
        }else{
            setHeight(height_item);
        }
    },[items, show, search]);   

    // MODO DE EDIÇÃO
    const handleSetEditMode = () => {
        setEditMode(!editMode);

        if(!editMode){
            setShow(true);
        }
    }

    // CALLBACK DE EDIÇÃO DOS ATALHOS
    const handleCallbackAtalhos = (e) => {
        if(e.reload){
            get_atalhos(false);
        }
    }

    // BOTÕES DE AÇÃO
    const actions = <div className={style.actions}>
                        {(editMode && (window.rs_permission_usr>1 || window.rs_permission_apl === 'master') && (window.rs_id_lja > 0 || !window.rs_id_lja) ?
                            <Tippy content="Novo atalho">
                                <Editar callback={handleCallbackAtalhos} />
                            </Tippy>
                        :'')}

                        {(window.rs_permission_apl === 'master' || window.rs_permission_usr>1 ?
                            <Tippy content={editMode ? 'Ocultar edição' : 'Editar atalhos'}>
                                <div className={style.button} onClick={handleSetEditMode}>
                                    <Icon type={editMode ? 'no-edit' : 'edit'} />
                                </div>
                            </Tippy>
                        :'')}
                        
                        {(!editMode ?
                            <Tippy content={show ? 'Mostrar menos' : 'Mostrar mais'}>
                                <div className={style.button} onClick={() => setShow(!show)}>
                                    <Icon type={show ? 'up' : 'down'} />
                                </div>
                            </Tippy>
                        :'')}
                    </div>;

    // GET INFO DOCUMENTO
    function getInfo(aux_id, level, name, item){
        setShowModal(true);
        setLoadingInfo(true);
        setModalTitle(name);
        
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/lista.php',
            params: {
                do: "get_foldersFiles",
                sistema_id: 218,
                aux_id: aux_id,
                level: level,
                limit: false
            }
        }).then((response) => {
            setLoadingInfo(false);

            if(response.data){
                setDocumentoInfo(response.data);
                setUrl({
                    urls: item?.url?.includes(',') ? item?.url.split(',') : [item?.url], 
                    nomes: item?.nome?.includes(',') ? item?.nome.split(',') : [item?.nome]
                });
            }
        });
    }

    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);
        setUrl({});
        setTimeout(() => {
            setDocumentoInfo([]);
        },500);
    }

    // REMOVER ATALHO
    function handleSetRemove(id){
        if(window.confirm('Confirma remoção do acesso rápido?')){
            axios({
                method: 'get',
                url: window.host+'/homes/api/home7.php?do=set_quickAccess_status&id='+id
            }).then(() => {
                toast('Atalho removido com sucesso');

                get_atalhos(false);
            })
        }
    }
    
    return(
        <>
            <Modal show={showModal} onHide={handleCloseModal} large={true} >
                <ModalHeader>
                    <ModalTitle>
                        Documentos {modalTitle ? ' - '+modalTitle : ''}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Table text_limit={loadingInfo ? false : true} className="mb-4">
                        <Thead>
                            <Tr>
                                <Th>
                                    Nome
                                </Th>
                                <Th>
                                    Cadastro
                                </Th>
                                <Th>
                                    Atualização
                                </Th>
                                <Th align="center" width={1}>
                                    Ações
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(loadingInfo ?
                                <Tr>
                                    <Td colspan="100%">
                                        <Loader />
                                    </Td>
                                </Tr>
                            :
                                (documentoInfo.filter((elem) => elem.file_id != 1073).length > 0 ?
                                    documentoInfo.filter((elem) => elem.file_id != 1073).map((item, i) => { // ID ESPECÍFICO DA HS (PROVISÓRIO) PARA APRESENTAR AO CLIENTE
                                        return (
                                            <Tr key={item.file_id}>
                                                <Td>{item.file_name}</Td>
                                                <Td>{item.cad !== "-" ? cd(item.cad) : item.cad}</Td>
                                                <Td>{item.cad_update !== "-" ? cd(item.cad_update) : item.cad_update}</Td>
                                                <Td align="center" width={1}>
                                                    <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.anexo} target="_blank" rel="noreferrer">
                                                        <Icon type="view" />
                                                    </a>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                :<></>)
                            )}
                        </Tbody>
                    </Table>

                    {(url?.urls  && Array.isArray(url.urls) && url.urls?.length > 0?
                        <>
                            <ModalTitle close={false}>
                                Link
                            </ModalTitle>
                            <Table className="my-3" text_limit={false}>
                                <Tbody>
                                    {url.urls.map((row, i) => {
                                        return(
                                            <Tr key={i}>
                                                <Td>
                                                    {url.nomes[i]}
                                                </Td>
                                                <Td>
                                                    <a href={row} target="_blank">
                                                        {row}
                                                    </a>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </>
                    :'')}
                </ModalBody>
            </Modal>

            <div className="position-relative">
                <div className={style.atalhos} style={{height:height}}>
                    <div className={style.atalhos_container} id="atalhos_container">
                        {(loading ?
                            [...Array(7)].map((item, i) => (
                                <div key={'atalho_loading_'+i} className={style.atalho + ' atalho ' + style.loading}></div>
                            ))
                        :
                            (items.length > 0 ?
                                items.map((item, i) => {
                                    let icon_aux;

                                    if(item.icone){
                                        icon_aux = item.icone;
                                    }else{
                                        if(item.tipo === 'documento'){
                                            icon_aux = 'document';
                                        }else if(item.tipo === 'link'){
                                            icon_aux = 'external';
                                        }else if(item.tipo === 'documento_link'){
                                            icon_aux = 'document_link';
                                        }
                                    }

                                    return(
                                        <>
                                            <div key={'atalho_'+i} className={style.atalho + ' atalho'}>
                                                <a
                                                    href={item.tipo === 'link' && item?.url ? item.url : false}
                                                    target={item.tipo == "link" ? '_blank' : ''}
                                                    onClick={() => (item.tipo === 'documento' || item.tipo === 'documento_link' ? getInfo(item?.aux_id, item?.level, item.nome_aux,item) : {})}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <Icon type={icon_aux} readonly title={false} className={'text-primary ' + (window.isMobile ? '' : 'me-2')} />

                                                        {(!window.isMobile ?
                                                            <Tippy content={item?.nome_aux} disabled={item?.nome_aux.length >= 20 ? false : true}>
                                                                <div>
                                                                    {item?.nome_aux.length >= 20 ? item?.nome_aux.slice(0,20)+'...' : item?.nome_aux}
                                                                </div>
                                                            </Tippy>
                                                        :'')}
                                                    </div>
                                                </a>

                                                {(editMode ?
                                                    <div className={style.icon + ' ' + (item ? '' : style.new)}>
                                                        <div>
                                                            <Icon type="trash" title="Excluir atalho" onClick={() => handleSetRemove(item.id)} />
                                                            <Editar item={item} callback={handleCallbackAtalhos} />
                                                        </div>
                                                    </div>
                                                :'')}
                                            </div>

                                            {/* SE TIVER 7 ITENS OU MAIS E POSIÇÃO 7 OU TIVER MENOS QUE 7 ITENS E ÚLTIMA POSIÇÃO */}
                                            {(((items.length >= 7 && i === 6) || (items.length < 7 && i === (items.length - 1))) && window.isMobile ?
                                                <div key={'atalho_'+i} className={style.atalho + ' atalho ' + style.empty}>
                                                    {actions}
                                                </div>
                                            :'')}
                                        </>
                                    )
                                })
                            :
                                <div className={style.atalho + ' atalho ' + style.fullWidth}>
                                    <div>
                                        {(search ?
                                            <>Nenhum atalho encontrado para: <b>{search}</b></>
                                        :
                                            <>Nenhum atalho encontrado</>
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                {/* BOTÕES DE AÇÃO SE NÃO FOR MOBILE */}
                {(!window.isMobile ?
                    actions
                :'')}
            </div>
        </>
    )
}

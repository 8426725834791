import Input from "../../../components/body/form/input";
import Gerenciador from "../../../components/body/gerenciador";
import Row from "../../../components/body/row";
import Switch from "../../../components/body/switch";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Tr from "../../../components/body/table/tr";
import Categorias from "./Categoria";
import { useState } from "react";


export default function GerenciadorMelhorias() {


    const [lojas, setLojas] = useState([
        { id: 1, loja: "Filial 004 - Drogal Monte Alto", ativo: 1 },
        { id: 2, loja: "Filial 005 - Drogal Jaboticaba", ativo: 1 },
        { id: 3, loja: "Filial 006 - Drogal Nova Dois", ativo: 1 },
        { id: 4, loja: "Filial 007 - Drogal Teste 1", ativo: 1 },
        { id: 5, loja: "Filial 008 - Drogal Teste 2", ativo: 1 }
    ]);

    const [selected, setSelected] = useState('');
    const [search, setSearch] = useState('');
    const [inativos, setInativos] = useState('');

    return (
        <Row>
            <Gerenciador
                id="lojas-melhorias"
                title="Lojas"
                autoScroll={true}
                search={
                    <Input placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />
                }
                switch={
                    <Input
                        type="checkbox"
                        id="check_inativos"
                        label="Mostrar Inativos"
                        inverse={true}
                        onChange={(e) => (setInativos(e.target.checked))}
                    />
                }
            >
                <Table fullHeight={true} >
                    <Tbody>
                        {(
                            lojas &&
                            lojas.map((item) => {
                                return (
                                    <Tr 
                                    key={item.id}
                                    cursor="pointer"
                                    active={(selected == item.id ? true : false)}
                                    >
                                        <Td
                                        onClick={ () => setSelected( selected == item.id ? "" : item.id) }
                                        >{item.loja}</Td>
                                        <Td align="end" >
                                            <Switch
                                                checked={(item.ativo == 1 ? true : false)}
                                                onChange
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>
            {(
                selected && <Categorias id={selected} />
            )}
        </Row>
    )
}
import { useState, useEffect } from 'react';

import style from './style.module.scss';
import Input from '../../../../components/body/form/input';
import Icon from '../../../../components/body/icon';
import axios from 'axios';
import Modal from '../../../../components/body/modal';
import ModalHeader from '../../../../components/body/modal/modalHeader';
import ModalTitle from '../../../../components/body/modal/modalHeader/modalTitle';
import ModalBody from '../../../../components/body/modal/modalBody';
import Loader from '../../../../components/body/loader';
import Table from '../../../../components/body/table';
import Tbody from '../../../../components/body/table/tbody';
import Tr from '../../../../components/body/table/tr';
import Th from '../../../../components/body/table/thead/th';
import Title from '../../../../components/body/title';
import Thead from '../../../../components/body/table/thead';
import Td from '../../../../components/body/table/tbody/td';
import { get_date } from '../../../../_assets/js/global';
import Tippy from '@tippyjs/react';

export default function Busca({search, callback}){
    // ESTADOS
    const [searchAux, setSearchAux] = useState(search);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingDocumentos, setLoadingDocumentos] = useState(true);
    const [pastas, setPastas] = useState([]);
    const [pasta, setPasta] = useState(null);
    const [comunicados, setComunicados] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [news, setNews] = useState([]);

    // SETA ESTADO DA BUSCA SEMPRE QUE RECEBE ALTERAÇÃO NA PROPS
    useEffect(() => {
        setSearchAux(search);
    },[search]);

    // FUNÇÃO DE BUSCA
    function handleSetBusca(e){
        if(e.key === 'Enter' && searchAux){
            setShow(true);

            // BUSCA DOCUMENTOS
            // axios({
            //     method: 'get',
            //     url: window.host+'/homes/api/home7.php',
            //     params: {
            //         do: "get_quickAccess",
            //         filter_multiple_columns: searchAux,
            //     }
            // }).then((response) => {
            //     if(response.data){
            //         setPastas(response.data);
            //     }

            //     // BUSCA COMUNICADOS
            //     axios({
            //         method: 'get',
            //         url: window.host+'/systems/comunicado/api/lista.php',
            //         params: {
            //             p: "lista",
            //             s: "1",
            //             page: "1",
            //             limit: "100",
            //             lista_metodo: "array",
            //             filtro_tipo_comunicado: "Comunicados",
            //             filter_multiple_columns: searchAux
            //         }
            //     }).then((response) => {
            //         if(response.data){
            //             setComunicados(response.data);
            //         }

            //         setLoading(false);
            //     });
            // });

            // BUSCA DOCUMENTOS
            axios({
                method: 'get',
                url: window.host+'/systems/treinamento/api/lista.php',
                params: {
                    do: 'get_category',
                    page: 0,
                    limit: 25,
                    sistema_id: 218
                }
            }).then((response) => {
                if(response.data){         
                    // BUSCA DOCUMENTOS
                    let documentos_aux = [];
                    
                    axios({
                        method: 'get',
                        url: window.host+'/systems/treinamento/api/lista.php',
                        params: {
                            do: 'get_foldersFiles',
                            limit: false,
                            level: 4,
                            //aux_id: categoria.id,
                            filter_multiple_columns: searchAux
                        }
                    }).then((responseDoc) => {
                        if(responseDoc.data){
                            responseDoc.data.map((item, i) => { 
                                documentos_aux.push(item);
                            });                            
                        }
                    
                        response.data.map((categoria, i) => {        
                            if(i == (response.data.length - 1)){
                                setDocumentos(documentos_aux);
                                
                                // BUSCA COMUNICADOS
                                axios({
                                    method: 'get',
                                    url: window.host+'/systems/comunicado/api/lista.php',
                                    params: {
                                        p: "lista",
                                        s: "1",
                                        page: "1",
                                        limit: "100",
                                        lista_metodo: "array",
                                        filtro_tipo_comunicado: "Comunicados",
                                        filter_multiple_columns: searchAux
                                    }
                                }).then((response) => {
                                    if(response.data){
                                        setComunicados(response.data);
                                    }

                                    // BUSCA NEWS
                                    let permission_aux;

                                    if(window.rs_permission_apl === 'com_acesso'){
                                        permission_aux = 1;
                                    }else{
                                        permission_aux = 3;
                                    }

                                    axios({
                                        method: 'get',
                                        url: window.host + '/homes/api/home3.php?do=cadastros',
                                        params: {
                                            ativo: 1,
                                            permissao: permission_aux,
                                            page: 1,
                                            limit: 50,
                                            filter_multiple_columns: searchAux
                                        }
                                    }).then((response) => {
                                        if(response.data){
                                            setNews(response.data);
                                        }

                                        setLoading(false);
                                    },[]);
                                });
                            }
                        });
                    });
                }
            });
        }

        if(callback){
            callback({
                search: e.target.value,
                news: false // SE TRUE FALA PRA PÁGINA FILTRAR A NEWS
            });
        }
    }

    // AÇÕES AO FECHAR MODAL
    const handleClose = () => {
        setShow(false);

        setTimeout(() => {            
            setPastas([]);
            setPasta(null);
            setDocumentos([]);
            setComunicados([]);
            setNews([]);
            setLoading(true);
            setLoadingDocumentos(true);
        },500);
    }

    // ABRIR DOCUMENTOS
    function handleShowDocumento(id, level){
        if(pasta == id){ // FECHA PASTA
            setPasta(null);
            setDocumentos([]);
        }else{ // ABRE PASTA
            setPasta(id);
            setDocumentos([]);
            setLoadingDocumentos(true);

            axios({
                method: 'get',
                url: window.host+'/systems/treinamento/api/lista.php',
                params: {
                    do: 'get_foldersFiles',
                    sistema_id: 218,
                    aux_id: id,
                    level: level,
                    limit: false
                }
            }).then((response) => {
                setLoadingDocumentos(false);

                if(response.data){
                    setDocumentos(response.data);
                }
            });
        }
    }

    // FUNÇÃO PARA FILTRAR A NEWS
    function handleFilterNews(id){
        handleClose();

        if(callback){
            callback({
                search: searchAux,
                news: id // SE TRUE FALA PRA PÁGINA FILTRAR A NEWS
            });
        }
    }

    return(
        <>
            <Modal show={show} large={true} onHide={handleClose}>
                <ModalHeader>
                    <ModalTitle>Busca: <b>{searchAux}</b></ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(loading ? 
                        <Loader />
                    : 
                        <>
                            <div className="mb-4">
                                <Table
                                    fixed={false}
                                    text_limit={false}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Documentos</Th>
                                            {documentos.length > 0 ? <Th width={1} align="center">Cadastro</Th> : ''}
                                            {documentos.length > 0 ? <Th width={1} align="center">Ações</Th> : ''}
                                        </Tr>
                                    </Thead>
                                    <Tbody>                                                            
                                        {(documentos.length > 0 ?                            
                                            documentos.map((item, i) => {
                                                return(
                                                    <>
                                                        <Tr
                                                            key={'modal_documentos_'+item.file_id}
                                                            // disabled={pasta && pasta != item?.aux_id ? true : false}
                                                        >
                                                            <Td className="cursor-pointer" onClick={() => handleShowDocumento(item?.aux_id, item?.level)}>
                                                                {item?.file_name}
                                                            </Td>

                                                            <Td width={1} align="center">
                                                                {item?.cad ? get_date('date', item?.cad, 'datetime_sql') : '-'}
                                                            </Td>
                                                            
                                                            <Td width={1} align="center">
                                                                <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.anexo} target="_blank">
                                                                    <Icon
                                                                        type={'view'}
                                                                        title="Ver documento"
                                                                        // onClick={() => handleShowDocumento(item?.aux_id, item?.level)}
                                                                    />
                                                                </a>
                                                            </Td>
                                                        </Tr>

                                                        {/* {(pasta == item?.aux_id ?
                                                            (loadingDocumentos ?
                                                                <Tr>
                                                                    <Td colspan="100%">
                                                                        <Loader />
                                                                    </Td>
                                                                </Tr>
                                                            :
                                                                documentos.map((item, i) => {
                                                                    return(
                                                                        <Tr key={'modal_documento_'+item?.file_id}>
                                                                            <Td className="ps-5">{item?.file_name}</Td>
                                                                            <Td width={1} align="center">
                                                                                {item?.cad ? get_date('date', item?.cad, 'datetime_sql') : ''}

                                                                                {(item?.cad_update && item?.cad_update !== '-' ?
                                                                                    <Tippy content="Atualização">
                                                                                        <span className="text-secondary ms-2">
                                                                                            ({get_date('date', item?.cad_update, 'datetime_sql')})
                                                                                        </span>
                                                                                    </Tippy>
                                                                                :'')}
                                                                            </Td>
                                                                            <Td></Td>
                                                                        </Tr>
                                                                    )
                                                                })
                                                            )
                                                        :
                                                            <></>
                                                        )}                                                         */}
                                                    </>
                                                )
                                            })
                                        :
                                            <Tr empty={true} />
                                        )}
                                    </Tbody>
                                </Table>
                            </div>

                            {(pasta == 120 && !loadingDocumentos ?
                                <div className="mb-4">
                                    <Table
                                        fixed={false}
                                        text_limit={false}
                                    >                                
                                        <Thead>
                                            <Tr>
                                                <Th colspan="100%">
                                                    Links - {pastas.filter((elem) => elem.aux_id == pasta)[0].nome_aux}
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td width={1}>
                                                    Claro Troca
                                                </Td>
                                                <Td>
                                                    <a href="https://tradeinv2.trocafone.com/claro-aa/auth/login" target="_blank">
                                                        https://tradeinv2.trocafone.com/claro-aa/auth/login
                                                    </a>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td width={1}>
                                                    Payjoy
                                                </Td>
                                                <Td>
                                                    <a href="https://movil-journey.adelantosbrasil.com/" target="_blank">
                                                        https://movil-journey.adelantosbrasil.com/
                                                    </a>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td width={1}>
                                                    Soudi Captação
                                                </Td>
                                                <Td>
                                                    <a href="https://soudi.onboarding.smilego.io/app/login" target="_blank">
                                                    https://soudi.onboarding.smilego.io/app/login
                                                    </a>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td width={1}>
                                                Soudi SAV
                                                </Td>
                                                <Td>
                                                    <a href="https://sav.wooza.com.br/hstelecom" target="_blank">
                                                        https://sav.wooza.com.br/hstelecom
                                                    </a>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            :'')}

                            <div className="mb-4">
                                <Table
                                    fixed={false}
                                    text_limit={50}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Comunicados</Th>
                                            {comunicados.length > 0 ? <Th width={1} align="center">Enviado em</Th> : ''}
                                            {comunicados.length > 0 ? <Th width={1} align="center">Ações</Th> : ''}
                                        </Tr>
                                    </Thead>
                                    <Tbody>      
                                        {(comunicados.length > 0 ?                                      
                                            comunicados.map((item, i) => {
                                                return(
                                                    <Tr key={'modal_comunicados_'+item.id}>
                                                        <Td>{item?.nome}</Td>
                                                        <Td width={1} align="center">{item?.data}</Td>
                                                        <Td width={1} align="center">
                                                            <a href={window.host+'/systems/comunicado/?p=lista&s=1&c='+item.id} target="_blank">
                                                                <Icon
                                                                    type="view"
                                                                    title="Ver comunicado"
                                                                    animated
                                                                />
                                                            </a>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true} />
                                        )}
                                    </Tbody>
                                </Table>
                            </div>

                            <div>
                                <Table
                                    fixed={false}
                                    text_limit={50}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>News</Th>
                                            {news.length > 0 ? <Th width={1} align="center">Enviado em</Th> : ''}
                                            {news.length > 0 ? <Th width={1} align="center">Ações</Th> : ''}
                                        </Tr>
                                    </Thead>
                                    <Tbody>      
                                        {(news.length > 0 ?                                      
                                            news.map((item, i) => {
                                                return(
                                                    <Tr key={'modal_news_'+item.id}>
                                                        <Td>{item?.nome}</Td>
                                                        <Td width={1} align="center">{item?.data_publicacao ? get_date('date', item?.data_publicacao, 'datetime_sql') : '-'}</Td>
                                                        <Td width={1} align="center">
                                                            <Icon
                                                                type="view"
                                                                title="Ver News"
                                                                onClick={() => handleFilterNews(item.id)}
                                                                animated
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true} />
                                        )}
                                    </Tbody>
                                </Table>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>

            <div className={style.busca}>
                <Icon type="search" disabled title={false} />

                <Input
                    placeholder="Pesquisa aqui"
                    value={searchAux}                
                    onChange={(e) => setSearchAux(e.target.value)}
                    onKeyDown={handleSetBusca}
                />
            </div>
        </>
    )
}

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Icon from "../../../../components/body/icon";
import style from './style.module.scss';
import Tippy from "@tippyjs/react";
import Button from "../../../Treinamento/components/button";
import Modal from "../../../../components/body/modal";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../components/body/modal/modalBody";
import Title from "../../../../components/body/title";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import Loader from "../../../../components/body/loader";

export default function Comunicados({ search, active, callback, permission }) {
    // ESTADOS
    const [showModal, setShowModal] = useState(false);
    const [show, setShow] = useState('');
    const [lista, setLista] = useState([]);
    const [loading, setLoading] = useState(true);

    // BUSCA LISTA
    function getLista() {
        setLoading(true);

        axios({
            method: 'get',
            url: window.host + '/systems/comunicado/api/lista.php',
            params: {
                p: "lista",
                s: "1",
                page: "1",
                limit: "10",
                lista_metodo: "array",
                filtro_nome: "",
                filtro_loja: "",
                filtro_data_inicio: "",
                filtro_data_fim: "",
                filtro_tipo_comunicado: "Comunicados",
                filtro_usuario: 79507,
                filter_multiple_columns: search
            }
        }).then((response) => {
            setLoading(false);

            if (response.data) {
                setLista(response.data);
            }
        });
    }

    // EXECUTA A FUNÇÃO DE BUSCA SEMPRE QUE SOFRE ALTERAÇÃO NA PROPS SEARCH
    useEffect(() => {
        getLista();
    }, [search]);

    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);
    }

    // LISTA DE COMUNICADOS
    const comunicados = <>
        <ul>
            {(loading ?
                <Loader />
                :
                <>
                    {/* <li >
                        <a className={style.comunicado_anchor} >
                            <span>
                                <div>
                                    <a >
                                        <div className={style.comunicado_container_title}>
                                            <div className={style.comunicado_title}>
                                                <b>Empreendimento:</b>
                                            </div>

                                            <div className={style.comunicado_data + ' text-primary'}>
                                                De:
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </span>
                        </a>
                    </li> */}
                    {(lista.length > 0 ?
                        lista.map((item, i) => {
                            let data_aux = item?.data?.split("/")
                            return (
                                <li
                                    key={'documento_' + item?.id}
                                >
                                    <a href={window.host + '/systems/comunicado/?p=lista&s=1&c=' + item.id} target="_blank" className={style.comunicado_anchor} >
                                        <span className={show.includes(item.id) ? "span-active" : ''}>
                                            <Tippy content={item.nome}>
                                                <div>
                                                    <a href={item.url} target={item.tipo == "link" ? '_blank' : ''}>
                                                        {/* <Icon
                                                        type={'chat-open'}
                                                        readonly={(active ? true : false)}
                                                        title={(active ? false : item.nome)}
                                                        /> */}

                                                        <div className={style.comunicado_container_title}>
                                                            <div className={style.comunicado_title}>
                                                                <HiOutlineChatBubbleBottomCenterText

                                                                    readonly={(active ? true : false)}
                                                                    title={(active ? false : item.nome)}
                                                                />
                                                                {item.nome}
                                                            </div>

                                                            <div className={style.comunicado_data + ''}>
                                                                {`${data_aux[0]}/${data_aux[1]}`}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </Tippy>
                                        </span>
                                    </a>
                                </li>
                            )
                        })
                        :
                        <li>
                            {(search ?
                                <>Nenhum comunicado encontrado para: <b>{search}</b></>
                                :
                                <>Nenhum comunicado encontrado</>
                            )}
                        </li>
                    )}
                </>
            )}
        </ul>

        {(!loading ?
            <a href={window.host + '/systems/comunicado/'} target="_blank" className={style.ver_todos}>
                {/* <button>Ver todos</button> */}
                <Button>Ver todos comunicados</Button>
            </a>
        :'')}
    </>;

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} large={true} >
                <ModalHeader>
                    <ModalTitle>
                        Últimos comunicados
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className={style.comunicados}>
                        <ul>
                            {comunicados}
                        </ul>
                    </div>
                </ModalBody>
            </Modal>

            {(!window.isMobile ?
                <div className={style.comunicados}>
                    {/* <Title className={style.title}> */}
                    {/* Últimos Comunicados */}
                    {/* </Title> */}

                    <div className={style.background}>
                        <div className={style.title}>
                            <div>
                                <Icon type="document" className="text-primary" /> Comunicados
                            </div>

                            {(permission == 3 ?
                                <a href={window.host+'/systems/comunicado'} target="_blank">
                                    <Icon type="plus" />
                                </a>
                            :'')}
                        </div>
                        {comunicados}
                    </div>
                </div>
                :
                <div className={style.button_mobile} onClick={() => setShowModal(true)}>
                    <Icon type="chat-open" /> Últimos comunicados
                </div>
            )}
        </>
    )
}

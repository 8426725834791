import { useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';
import style from './style.module.scss';
import { Circle } from "rc-progress";
import Title from '../../../../components/body/title';
import SelectReact from '../../../../components/body/select';
import axios from 'axios';
import { FaFontAwesomeFlag } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";

export default function KPI(hiddenAux) {
    // ESTADOS
    const [filterUsuario, setFilterUsuario] = useState(window.rs_id_usr);
    const [info, setInfo] = useState([])

    // GET RANKING
    function get_ranking() {
        axios({
            method: 'get',
            url: window.host + '/systems/vendas/api/ranking_claro.php',
            params: {
                do: 'get_rankingHome'
            }
        }).then((response) => {
            if (response.data) {
                setInfo(response.data);
            }
        });
    }

    // PRIMEIRA CHAMADA
    useEffect(() => {
        get_ranking();
    }, []);

    // CONDICIONAL DE PROJEÇÃO
    const projecao_condicional = {
        milhas: 0,
        pos: 70,
        controle: 70,
        residencial: 0,
        acessorios: 50,
        servicos: 50
    }

    // CORES 
    const cor = {
        minima: '#F4C008',
        media: '#DB2F30',
        acima: '#0396D8',
    }

    return (
        <div className={style.kpi} hidden={hiddenAux?.hidden}>
            <div className={style.background}>
                {/* MILHAS */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.milhas?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.milhas?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.milhas?.projecao_porc) <= projecao_condicional.milhas ? cor.minima : Math.round(info?.data?.milhas?.projecao_porc) > projecao_condicional.milhas && Math.round(info?.data?.milhas?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.milhas?.projecao_porc) <= projecao_condicional.milhas ? cor.minima : Math.round(info?.data?.milhas?.projecao_porc) > projecao_condicional.milhas && Math.round(info?.data?.milhas?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.milhas?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} /> Milhas</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />  <b>{info?.data?.milhas?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                    {info?.data?.milhas?.meta ? info?.data?.milhas?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.milhas?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                <FaFontAwesomeFlag className="text-secondary" /> Meta Diária: {info?.data?.milhas?.meta_diaria ? info?.data?.milhas?.meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                {/* PÓS-PAGO */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.pos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.pos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.pos?.projecao_porc) <= projecao_condicional.pos ? cor.minima : Math.round(info?.data?.pos?.projecao_porc) > projecao_condicional.pos && Math.round(info?.data?.pos?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.pos?.projecao_porc) <= projecao_condicional.pos ? cor.minima : Math.round(info?.data?.pos?.projecao_porc) > projecao_condicional.pos && Math.round(info?.data?.pos?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.pos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} />  Pós-Pago</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />  <b>{info?.data?.pos?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                    {info?.data?.pos?.meta ? info?.data?.pos?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.pos?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                <FaFontAwesomeFlag className="text-secondary" /> Meta Diária: {info?.data?.pos?.meta_diaria ? info?.data?.pos?.meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                {/* CONTROLE */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.controle?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.controle?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.controle?.projecao_porc) <= projecao_condicional.controle ? cor.minima : Math.round(info?.data?.controle?.projecao_porc) > projecao_condicional.controle && Math.round(info?.data?.controle?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.controle?.projecao_porc) <= projecao_condicional.controle ? cor.minima : Math.round(info?.data?.controle?.projecao_porc) > projecao_condicional.controle && Math.round(info?.data?.controle?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.controle?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} />  Controle</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />  <b>{info?.data?.controle?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                    {info?.data?.controle?.meta ? info?.data?.controle?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.controle?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                {/* ESSA META PRECISA SER NÚMERO INTEIRO E ARREDONDADA PARA CIMA */}
                                <FaFontAwesomeFlag className="text-secondary" />  Meta Diária: {info?.data?.controle?.meta_diaria ? Math.ceil(info?.data?.controle?.meta_diaria).toLocaleString('pt-BR') : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                {/* PRODUTOS RESIDENCIAIS */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.residencial?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.residencial?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.residencial?.projecao_porc) <= projecao_condicional.residencial ? cor.minima : Math.round(info?.data?.residencial?.projecao_porc) > projecao_condicional.residencial && Math.round(info?.data?.residencial?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.residencial?.projecao_porc) <= projecao_condicional.residencial ? cor.minima : Math.round(info?.data?.residencial?.projecao_porc) > projecao_condicional.residencial && Math.round(info?.data?.residencial?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.residencial?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} /> Residenciais</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />  <b>{info?.data?.residencial?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                        {info?.data?.residencial?.meta ? info?.data?.residencial?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.residencial?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                {/* ESSA META PRECISA SER NÚMERO INTEIRO E ARREDONDADA PARA CIMA */}
                                <FaFontAwesomeFlag className="text-secondary" /> Meta Diária: {info?.data?.residencial?.meta_diaria ? Math.ceil(info?.data?.residencial?.meta_diaria).toLocaleString('pt-BR') : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                {/* ACESSÓRIOS */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.acessorios?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.acessorios?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.acessorios?.projecao_porc) <= projecao_condicional.acessorios ? cor.minima : Math.round(info?.data?.acessorios?.projecao_porc) > projecao_condicional.acessorios && Math.round(info?.data?.acessorios?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.acessorios?.projecao_porc) <= projecao_condicional.acessorios ? cor.minima : Math.round(info?.data?.acessorios?.projecao_porc) > projecao_condicional.acessorios && Math.round(info?.data?.acessorios?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.acessorios?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} />  Acessórios</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />  <b>{info?.data?.acessorios?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                        {info?.data?.acessorios?.meta ? info?.data?.acessorios?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.acessorios?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                <FaFontAwesomeFlag className="text-secondary" /> Meta Diária: {info?.data?.acessorios?.meta_diaria ? info?.data?.acessorios?.meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                {/* SERVIÇOS */}
                <div>
                    <div className={style.circle}>
                        <Circle
                            percent={info?.data?.servicos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) < 100 ? info?.data?.servicos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={Math.round(info?.data?.servicos?.projecao_porc) <= projecao_condicional.servicos ? cor.minima : Math.round(info?.data?.servicos?.projecao_porc) > projecao_condicional.servicos && Math.round(info?.data?.servicos?.projecao_porc) < 100 ? cor.media : cor.acima}
                        />

                        <div className={style.number} style={{ color: Math.round(info?.data?.servicos?.projecao_porc) <= projecao_condicional.servicos ? cor.minima : Math.round(info?.data?.servicos?.projecao_porc) > projecao_condicional.servicos && Math.round(info?.data?.servicos?.projecao_porc) < 100 ? cor.media : cor.acima }}>
                            {info?.data?.servicos?.projecao_porc.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
                        </div>
                    </div>

                    <div>
                        <span className={style.primary_info}><FaChartSimple className="text-primary" sytle={{ fontSize: '30px!important' }} />  Serviços</span>
                        <span className={'text-secondary ' + style.secondary_info}>
                            <div>
                                <Tippy content="Realizado Mensal">
                                    <span>
                                        <FaChartBar className="text-secondary" />   <b>{info?.data?.servicos?.realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
                                    </span>
                                </Tippy>

                                <Tippy content="Meta Mensal">
                                    <span>
                                        {info?.data?.servicos?.meta ? info?.data?.servicos?.meta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                                    </span>
                                </Tippy>
                            </div>
                        </span>

                        <Tippy content={'Projeção: ' + info?.data?.servicos?.projecao_meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}>
                            <span className={'text-secondary ' + style.tertiary_info}>
                                <FaFontAwesomeFlag className="text-secondary" /> Meta Diária: {info?.data?.servicos?.meta_diaria ? info?.data?.servicos?.meta_diaria?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                            </span>
                        </Tippy>
                    </div>
                </div>

                <div className="d-lg-flex d-block align-items-center justify-content-between w-100">
                    <a href={window.host + '/systems/vendas/'} target="_blank" className={style.ver_todos}>
                        <button>Ver Ranking</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

import { useEffect, useState } from "react";
import Gerenciador from "../../../../components/body/gerenciador";
import Icon from "../../../../components/body/icon";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";
import Input from "../../../../components/body/form/input";
import Form from "../../../../components/body/form";
import SelectReact from "../../../../components/body/select";
import Button from "../../../../components/body/button";
import Switch from "../../../../components/body/switch";


export default function Categorias() {


    const [categoria, setCategoria] = useState([
        { id: 1, categoria: "Depósito Medicamentos", subcategoria: 1, ativo: 1 },
        { id: 2, categoria: "Depósito Controlados", subcategoria: 1, ativo: 1 },
        { id: 3, categoria: "Sala Atendimento Farmacêutico", subcategoria: 1, ativo: 1 },
        { id: 4, categoria: "Salão de Vendas", subcategoria: 1, ativo: 1 },
        { id: 5, categoria: "Termolábil", subcategoria: 2, ativo: 1 },
    ]);
    const [search, setSearch] = useState('');
    const [inativos, setInativos] = useState('');
    const [editarCategoria, setEditarCategoria] = useState('');
    const [selected, setSelected] = useState('');


    //EDITAR CATEGORIA
    const [tipo, setTipo] = useState('');
    const [ambiente, setAmbiente] = useState('');
    const [minima, setMinima] = useState('');
    const [maxima, setMaxima] = useState('');
    const [momento, setMomento] = useState('');
    const [umidade, setUmidade] = useState('');
    const [uminadeMin, setUmidadeMin] = useState('');
    const [umidadeMax, setUmidadeMax] = useState('');
    const [temperaturaMin, setTemperaturaMin] = useState('');
    const [temperaturaMax, setTemperaturaMax] = useState('');
    const [foto, setFoto] = useState(1);
    const [observacao, setObservacao] = useState(2);

    const options_boolean = [{ value: 1, label: "Sim" }, { value: 2, label: "Não" }]

    const handleSelected = (id, tipo, ambiente, minima, maxima, momento, umidade, umindadeMin,
        umidadeMax, temperaturaMin, temperaturaMax, foto, observacao) => (
        setSelected(id),
        setTipo(tipo),
        setAmbiente(ambiente),
        setMinima(1),
        setMaxima(1),
        setMomento(1),
        setTemperaturaMin("20°C"),
        setTemperaturaMax("30°C")
    )


    useEffect(() => {
        if (umidade == 1) {
            setUmidadeMin("45%")
            setUmidadeMax("75%")
        } else {
            setUmidadeMin("")
            setUmidadeMax("")
        }

    }, [umidade])

    return (
        <>
            <Gerenciador
                id="categoria-melhorias"
                title="Categorias"
                autoScroll={true}
                icon={<Icon type="new" />}
                search={
                    <Input placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />
                }
                switch={
                    <Input
                        type="checkbox"
                        id="check_inativos"
                        label="Mostrar Inativos"
                        inverse={true}
                        onChange={(e) => (setInativos(e.target.checked))}
                    />
                }
            >
                <Table fullHeight={true} >
                    <Tbody>
                        {
                            categoria &&
                            categoria.map((item) => {
                                let aux
                                if (item.subcategoria == 1) {
                                    aux = "Ambiente"
                                }
                                if (item.subcategoria == 2) {
                                    aux = "Termolábil"
                                }
                                return (
                                    <Tr
                                        key={item.id}
                                    >
                                        <Td title={`${item.categoria} (${aux})`}>{item.categoria}
                                            {<span style={{ color: "#ddd" }}>{` (${aux})`}</span>}
                                        </Td>
                                        <Td
                                            align="end"
                                        >
                                            <Icon
                                                type="edit"
                                                classname={selected == item.id ? "text-primary" : "text-secondary"}
                                                // onClick={() => setSelected(selected == item.id ? '' : )}
                                                onClick={() => handleSelected(item.id, item.subcategoria, item.categoria)}
                                            />
                                            <Switch
                                                checked={(item.ativo == 1 ? true : false)}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Gerenciador>

            {
                selected &&
                <Gerenciador
                    id="editar-categoria-melhorias"
                    title="Editar Categoria"
                    autoScroll={true}
                    icon={<Icon type="reprovar" />}
                >
                    <Form>
                        <h6>
                            Componentes
                        </h6>
                        <SelectReact
                            label="Tipo"
                            value={tipo}
                            onChange={(e) => setTipo(e.value)}
                            options={[{ value: 1, label: "Ambiente" }, { value: 2, label: "Termolábil" }]}
                        />
                        <Input
                            type="text"
                            name="nome-ambiente"
                            label="Nome do ambiente"
                            value={ambiente}
                            onChange={(e) => setAmbiente(e.target.value)}
                        />
                        <SelectReact
                            name="temperatura-minima"
                            label="Temperatura mínima"
                            value={minima}
                            onChange={(e) => setMinima(e.value)}
                            options={options_boolean}
                        />
                        <SelectReact
                            name="temperatura-maxima"
                            label="Temperatura máxima"
                            value={maxima}
                            onChange={(e) => setMaxima(e.value)}
                            options={options_boolean}
                        />
                        <SelectReact
                            name="temperatura-momento"
                            label="Temperatura momento"
                            value={momento}
                            onChange={(e) => setMomento(e.value)}
                            options={options_boolean}
                        />
                        <SelectReact
                            name="umidade"
                            label="Umidade"
                            value={umidade}
                            onChange={(e) => setUmidade(e.value)}
                            options={options_boolean}
                            required={false}
                        />
                        <SelectReact
                            name="foto-termometro"
                            label="Foto Termômetro"
                            value={foto}
                            onChange={(e) => setFoto(e.value)}
                            required={false}
                            options={options_boolean}
                        />
                        <SelectReact
                            name="observacao"
                            label="Observação"
                            value={observacao}
                            onChange={(e) => setObservacao(e.value)}
                            required={false}
                            options={options_boolean}
                        />
                        <h6>
                            Valores de Referência
                        </h6>
                        <Input
                            name="temperatura-minima"
                            type="text"
                            label="Temperatura mínima"
                            value={temperaturaMin}
                            onChange={(e) => setTemperaturaMin(e.target.value)}
                            required={false}
                        />
                        <Input
                            name="temperatura-maxima"
                            label="Temperatura máxima"
                            value={temperaturaMax}
                            onChange={(e) => setTemperaturaMax(e.target.value)}
                            required={false}
                        />

                        <Input
                            name="umdade-minima"
                            type="text"
                            label="Umidade minima"
                            value={uminadeMin}
                            onChange={(e) => setUmidadeMin(e.target.value)}
                            required={false}
                        />
                        <Input
                            name="umidade-maxima"
                            type="text"
                            label="Umidade máxima"
                            value={umidadeMax}
                            onChange={(e) => setUmidadeMax(e.target.value)}
                            required={false}
                        />

                        <Button type="submit">Enviar</Button>
                    </Form>
                </Gerenciador>
            }

        </>
    )
}
import style from './style.module.scss';
import NewsSystem from '../../../News';

export default function News({search, callback, filter, permission}){
    // FUNÇÃO QUE LIMPA FILTRO
    const handleClearBusca = (e) => {
        if(callback){
            callback({
                filter: e?.filter
            })
        }
    }

    return(
        <div className={style.news} id="news_home">
            <NewsSystem
                permission={permission}
                search={false}
                integrated={true}
                busca={search}
                filter={filter}
                callback={handleClearBusca}
            />
        </div>
    )
}

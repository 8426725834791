import { useState, useEffect } from "react";
import Button from "../../../../../components/body/button";
import Form from "../../../../../components/body/form";
import Input from "../../../../../components/body/form/input";
import Gerenciador from "../../../../../components/body/gerenciador";
import Icon from "../../../../../components/body/icon";
import axios from "axios";

export default function NovaSubcategoria({ id_categoria, nome_table, id_table, callback, callback_close }) {

    //ESTADOS INPUT
    const [subcategoria, setSubcategoria] = useState('');

    //PEGAR INFO 
    function getInfo() {
        // axios.get(window.host + "/systems/checklist-novo/api/gerenciador.php?do=get_perguntas&id=" + id_table)
        //     .then((response) => {

        //     })
    }

    //ATUALIZAR INPUTS COM INFOS QUE VEM DE PROPS
    useEffect(() => {
        if (id_table) {
            setSubcategoria(nome_table);
        } else if (!id_table) {
            setSubcategoria('');
        }
    }, [id_table]);

    //INFORMAÇÕES QUE SERÃO ENVIADAS PARA A API
    const data = {
        id: id_table ? id_table : '',
        name: subcategoria,
        id_ite: id_categoria
    };

    //LIMPAR OS INPUTS AO ENVIAR O FORMULÁRIO
    function resetForm() {
        setSubcategoria('');
        callback(true);
        callback_close(false)
    };

    return (
        <Gerenciador
            id="editar_subcategoria"
            title={id_table ? "Editar" : "Novo"}
            icon={<Icon type="reprovar" title="Fechar" onClick={() => callback_close(false)} />}
        >
            <Form
                api={window.host_madnezz+"/systems/integration-react/api/registry.php?do=set_subcategory"}
                data={data}
                callback={resetForm}
                toast={"Salvo com sucesso"}
            >
                <Input
                    type="text"
                    name="subcategoria"
                    label="Subcategoria"
                    value={subcategoria}
                    onChange={(e) => setSubcategoria(e.target.value)}
                />
                <Button type="submit"> Salvar </Button>
            </Form>
        </Gerenciador>
    )
}
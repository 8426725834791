import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import Icon from "../../../components/body/icon";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import { useState } from "react";
import Button from "../../../components/body/button";

export default function ModalVendas({ show, handleShow }) {

    //ESTADO SALVAR ARQUIVO
    const [arquivoExcel, setArquivoExcel] = useState('');

    return (
        <Modal show={show} onClick={handleShow} >
            <ModalHeader>
                <ModalTitle>
                    Importar Excel - Vendas
                    <Icon
                        type="close"
                        onClick={handleShow}
                    />
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Input
                        name="arquivo"
                        type="file"
                        label="Escolher arquivo"
                        value={arquivoExcel}
                        onChange={(e) => setArquivoExcel(e.target.value)}
                    />
                    <Button type="submit">Enviar</Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}
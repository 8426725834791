import "./social.css";
import Icon from "../../../../../components/body/icon";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import ModalDocumentos from "./modalDocumentos";

export default function MenuDocumentos(props) {

    const [show, setShow] = useState('');
    const [lista, setLista] = useState([]);
    //Modal Documentos
    // const [showModal, setShowModal] = useState(false);
    // const [idDoc, setIdDoc] = useState('');
    // const [idAuxDoc, setIdAuxDoc] = useState('');
    // const [levelDoc, setLeveDoc] = useState('');
    // const [nomeDoc, setNomeDoc] = useState('');

    // const handleModal = (id, aux, level, nome) => (
    //     setShowModal(true),
    //     setIdDoc(id),
    //     setIdAuxDoc(aux),
    //     setLeveDoc(level),
    //     setNomeDoc(nome)
    // )

    // if(props?.callback){
    //     setShowModal(true);
    //     setIdDoc(props?.id_doc)
    //     setNomeDoc(props?.id_doc)
    // }

    // FECHA MODAL CADASTRO
    const handleCloseModal = (e) => {
        props?.setShowModal(e);
        setTimeout(() => {
        }, 500);
    }

    function getLista() {
        axios.get("https://dev.malltech.com.br/homes/api/home7.php?do=get_quickAccess", {
            params: {
                filter_multiple_columns: props?.search,
                id_apl: props?.search
            }
        })
            .then((response) => {
                setLista(response.data)
            })
    }

    useEffect(() => {
        getLista()
    }, [props?.search, props.callback])

    return (
        <>
            <ModalDocumentos
                show={props?.showModal}
                onHide={handleCloseModal}
                id={props?.idDoc}
                id_aux={props?.idAuxDoc}
                level={props?.levelDoc}
                doc_name={props?.nomeDoc}
            />
            <div className={'main-container-social ' + (props.active ? 'active' : '')} >
                <div className={`btn-social ${props.active ? 'btn-social-active' : ''} `} >
                    <Icon
                        type={props.active ? "left" : "right"}
                        title={props.active ? "Fechar" : "Abrir"}
                        onClick={props.handleActive}
                    />
                </div>

                <div className="overflowy-auto">
                    {(
                        <div className={`container-social ${props.active ? '' : 'container-social-active'}`} >


                            <div style={{ position: "relative" }} >
                                <div style={{ position: "sticky" }}>
                                    <ul>
                                        <li className="social-li-title">
                                            {props.active ? "Documento" : " - "}
                                        </li>
                                        {(lista.length > 0 ?
                                            lista.map((item, i) => {

                                                let icon

                                                if (item.tipo == "documento") {
                                                    icon = "document"
                                                } else {
                                                    icon = item.tipo
                                                }

                                                return (
                                                    <li className="social-li-category"
                                                        key={'documento_' + item?.id}
                                                    >
                                                        <span className={show.includes(item.id) ? "span-active" : ''}>
                                                            <div>
                                                                <Icon
                                                                    type={icon}
                                                                    readonly={(props?.active ? true : false)}
                                                                    title={(props?.active ? false : item.nome_aux)}
                                                                />
                                                                {
                                                                    item.tipo == "link" ?
                                                                        <a href={item.url} target="_blank">
                                                                            {item.nome_aux}
                                                                        </a>
                                                                        :
                                                                        <a onClick={() => props?.handleModal(218, item.aux_id, item.level, item.nome_aux)}>
                                                                            {item.nome_aux}
                                                                        </a>
                                                                }
                                                            </div>
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            <li className="social-li-category social-li-empty">
                                                <span>
                                                    <div>
                                                        <Icon
                                                            type={'sad'}
                                                            readonly={(props?.active ? true : false)}
                                                            title={(props?.active ? false : 'teste')}
                                                        />

                                                        <a>
                                                            {(props?.search ?
                                                                <span dangerouslySetInnerHTML={{ __html: 'Nenhum resultado encontrado para a busca: <b>' + props?.search + '</b>' }} />
                                                                :
                                                                'Nenhum resultado encontrado'
                                                            )}
                                                        </a>
                                                    </div>
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
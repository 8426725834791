import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Row from "../../../components/body/row/index";
import Col from "../../../components/body/col/index";
import Icon from "../../../components/body/icon/index";
import ActionSection from "../../../components/body/news/actionSection";
import NewsLiContainer from "../../../components/body/news/listNews/newsLiContainer";
import TextSuccess from "../../../components/body/news/listNews/textSuccess";
import BoxImageNews from "../../../components/body/news/listNews/boxImageNews";
import NewsHashtag from "../../../components/body/news/listNews/newsHashtags";
import TextNewsDivContainer from "../../../components/body/news/listNews/textNewsDivContainer";
import TitleNews from "../../../components/body/news/listNews/titleNews";
import MainImageNews from "../../../components/body/news/listNews/mainImageNews";
import CommentTitle from "../../../components/body/news/listNews/commentTitleNews";
import DescriptionParagraph from "../../../components/body/news/listNews/descriptionNews";
import SearchNewsDiv from "../../../components/body/news/buscaNews/searchNewsDiv";
import SearchNewsInput from "../../../components/body/news/buscaNews/searchNewsInput";
import SearchNewsTitle from "../../../components/body/news/buscaNews/searchNewsTitle";
import "./style.scss";
import ImageModal from "./modalImage";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../../_assets/css/swiper.scss";
import { GlobalContext } from "../../../context/Global";
import NewModal from "./newModal";
import SelectReact from "../../../components/body/select";
import parse from "html-react-parser";
import IframeNews from "../../../components/body/news/listNews/iframeNews";
import DocumentParagraph from "../../../components/body/news/listNews/documentParagraph";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";

export default function Lista(props) {
    const { handlePageError } = useContext(GlobalContext);

    //ESTADO QUE SALVA AS INFOMAÇÕES DA API
    const [loading, setLoading] = useState(true);
    const [infoApi, setInfoApi] = useState([]);

    //FILTRO DO STATUS NO TITULO
    const [status, setStatus] = useState(1);

    //BUSCAR POR HASHTAGS
    const [hashtag, setHashtag] = useState('');

    //MUDAR STATUS
    const handleStatus = (e) => {
        setStatus(e);
    };

    // PERMISSÃO NO SISTEMA
    let permission_aux;

    if(props?.permission){
        permission_aux = props?.permission;
    }else{
        permission_aux = window.rs_permission_apl;
    }

    //ativo=" + status + "permissao=3&page=1&limit=15&&hashtag_filtro=",
    function getNews(loading) {
        if(loading){
            setLoading(true);
        }

        axios.get(window.host + "/homes/api/home3.php?do=cadastros&", {
            params: {
                ativo: status,
                permissao: permission_aux,
                page: 1,
                limit: 15, // limitado a 15, mas deveria aumentar conforme da scroll 
                hashtag_filtro: hashtag,
                //noticia_id_filtro: noticiaFiltro,
                filter_multiple_columns: props?.busca
            }
        }).then((response) => {
            setInfoApi(response.data);
            setLoading(false);


            if(props?.filter){
                let scrollTop, containerTop, containerHeight;

                setTimeout(() => {
                    containerTop = document.getElementById('news_'+props?.filter).getBoundingClientRect().top ;
                    scrollTop = containerTop - 40;
                    window.scrollTo({
                        top: scrollTop,
                        behavior: 'smooth'
                    });
                },300);
            }
        }).catch(() => {
            handlePageError(true);
        })
    };

    //MOSTRAR AS INFORMAÇÕES DA API
    useEffect(() => {
        getNews(true)
    }, [status, hashtag, props?.busca]);


    //FUNÇÃO DE DELETAR NOTICIA
    function deleteNews(newsId) {
        if (window.confirm("Deseja remover essa noticía?")) {
            axios({
                url: window.host + "/homes/api/home3.php?do=removerNews&id=" + newsId,
                method: "post",
                data: { id: newsId },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                toast("Noticia removida com sucesso!");
                getNews(false);
            }).catch((_error) => {
            })
        }
    };

    //ESTADO QUE MUDA QUANDO TEM MAIS DE UMA IMAGEM
    const [swiper, setSwiper] = useState();

    //MODAL PARA ABRIR A IMAGEM
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(!show)

    //MODAL PARA ADICIONAR NOTICIAS
    const [showNew, setShowNew] = useState(false);
    const [reload, setReload] = useState(false);
    const handleShowNew = () => setShowNew(true);

    //FECHAR MODAL 
    const handleCloseNew = (e) => {
        setShowNew(e);
        getNews(false);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500)

        if(props?.callbackNovaNews){
            props.callbackNovaNews(false);
        }
    };

    function editShowClose(id) {
        setEditId(id);
        handleShowNew();
    };

    //ESTADO QUE SALVA ID DA NOTICIA QUE VAI SER EDITADA
    const [editId, setEditId] = useState();

    useEffect(() => {
        if (editId)
            handleShowNew();
    }, [editId]);

    //FUNÇÃO QUE MANDA O ID 0 PRO MODAL NOVO/EDITAR
    function openNew() {
        setEditId();
        handleShowNew();
    };

    // FUNÇÃO QUE LIMPA FILTRO
    const handleClearBusca = () => {
        if(props.callback){
            props.callback({
                filter: false
            })
        }
    }

    useEffect(() => {
        if(props?.novaNews){
            openNew();
        }
        
    }, [props?.novaNews]);

    const callback_refresh = () => getNews();
    return (
        <div className={'mainContainer' + ' ' + (props?.widget || props.integrated ? 'my-0' : '')}>
            <Row justifyContent="center" className="rowNews">
                <Col lg={props?.integrated ? 12 : 'auto'}>
                    <NewModal
                        showNew={showNew}
                        handleShowNew={handleShowNew}
                        id={editId}
                        onHide={handleCloseNew}
                    />

                    {/* {(!props?.integrated ? */}
                        <TitleNews
                            overflow={true}
                            display="block"
                            // filter={
                            //     hashtag.length > 0 ? '' :
                            //         <SelectReact
                            //             value={status}
                            //             options={[
                            //                 { value: 1, label: "Ativas" },
                            //                 { value: 2, label: "Inativas" }
                            //             ]}
                            //             onChange={(e) => handleStatus(e.value)}
                            //         />
                            // }
                            icon={permission_aux >= 2 && !props?.integrated? <Icon type="plus" onClick={() => openNew()} /> : ''} //handleShowNew
                        >
                            {props.notitle === true?'':"Notícias"}
                            
                            {hashtag.length > 0 ? "#" + hashtag : ''}

                            {props?.busca ?
                                <span className="text-secondary position-relative ms-2">
                                    ({props?.busca})

                                    <Tippy content="Limpar filtro">
                                        <button onClick={handleClearBusca} className={'news_limpar_filtro'}>x</button>
                                    </Tippy>
                                </span>
                            : ''}

                            {/* {(
                                hashtag ?
                                    <div>#{hashtag}
                                        <button onClick={setHashtag('')}>x</button>
                                    </div>
                                    : ""
                            )} */}
                        </TitleNews>
                    {/* :'')} */}

                    <ul className="ps-0">
                        {(!loading ?
                            (infoApi.length > 0 ?
                                infoApi.map((item, index) => {
                                var published= new Date(item.data_publicacao).toLocaleDateString([], {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }).replaceAll(",", "");

                                var expired= new Date(item.data_saida).toLocaleDateString([], {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }).replaceAll(",", "");                        

                                var corrigirUrlVideo;
                                var corrigirUrlVideo2;
                                if (item.video) {
                                    if (item.video.includes("youtube")) {
                                        if (item.video.includes("watch?v=")) {
                                            corrigirUrlVideo = (item.video).split("watch?v=");
                                            corrigirUrlVideo2 = corrigirUrlVideo[0] + "embed/" + corrigirUrlVideo[1];
                                        }
                                    }
                                }

                                // IMAGEM
                                let img_aux = [];

                                if(item.img){
                                    if(item.img.includes('{')){
                                        JSON.parse(item.img).map((item, i) => {
                                            img_aux.push(item.id);
                                        });
                                    }else{
                                        item.img.split(',').map((item, i) => {
                                            img_aux.push(item);
                                        });
                                    }
                                }

                                return (
                                    <NewsLiContainer key={'noticia_'+item.id} widget={props.widget} id={'news_'+item.id}>
                                        <div className="buttonDeleteDiv">
                                            {(!props.widget && permission_aux >= 2 ? 
                                                <div className="buttonDelete">
                                                    <Icon
                                                        type="trash"
                                                        animated
                                                        onClick={() => deleteNews(item.id)}
                                                    />
                                                </div>
                                            :'')}
                                        </div>

                                        <div className="publisher">
                                            <div>
                                                {(item.marca_logo ?
                                                    <div className="publisher_logo" style={{backgroundImage:'url('+process.env.REACT_APP_URL_UPLOAD+'/'+item.marca_logo+')'}}></div>
                                                : 
                                                    <div className="text-uppercase">{item.nome.slice(0,1)}</div>
                                                )}
                                            </div>
                                            <div>
                                                <span className="publisher_title">{item.nome}</span>
                                                <span className="text-secondary">
                                                    Publicado: {published}

                                                    {(item.data_saida === null ?
                                                        ''
                                                    :
                                                        <div className="text-danger">
                                                            <Icon className="text-danger" type="calendar" />
                                                            Expira em: {expired}
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        {(
                                            item.video ?
                                                <IframeNews
                                                    src={corrigirUrlVideo2 ? corrigirUrlVideo2 : item.video}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                >
                                                </IframeNews>
                                                : ""
                                        )}
                                        {(
                                            item.documento ?
                                                <>
                                                    <iframe
                                                        title={item.id}
                                                        className="documentoDiv" src={"https://docs.google.com/gview?url="+process.env.REACT_APP_URL_UPLOAD+"/" + item.documento + "&embedded=true"}
                                                    >
                                                    </iframe>
                                                    <div>
                                                        <DocumentParagraph>
                                                            <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.documento} target="_blank" rel="noreferrer">
                                                                Clique aqui</a> caso o documento não seja exibido.
                                                        </DocumentParagraph>
                                                    </div>
                                                </>
                                                : ""
                                        )}
                                        <BoxImageNews>
                                            {(
                                                img_aux.length > 0 ?
                                                    <>
                                                        {(img_aux.length == 1 ?
                                                            <MainImageNews src={img_aux[0] ? process.env.REACT_APP_URL_UPLOAD+"/" + img_aux[0] : window.host+"/systems/news-react/"} alt={item.nome} onClick={() => handleShow()} />
                                                        :
                                                            <Swiper
                                                                ref={swiper}
                                                                autoHeight={false}
                                                                spaceBetween={15}
                                                                onSwiper={(swiper) => setSwiper(swiper)}
                                                                modules={[Navigation]}
                                                                navigation
                                                                allowTouchMove={true}
                                                                slidesPerView={1}
                                                                slidesPerGroup={1}
                                                            >
                                                                {img_aux.map((image, i) => {
                                                                    return (
                                                                        <SwiperSlide key={'imagem_'+item.id+'_'+i}>
                                                                            <MainImageNews src={image ? process.env.REACT_APP_URL_UPLOAD+"/" + image : window.host+"/systems/news-react/"} alt={item.nome} onClick={() => handleShow()} />
                                                                        </SwiperSlide>
                                                                    )
                                                                })}
                                                            </Swiper>
                                                        )}
                                                    </>
                                                    : ""
                                            )}
                                        </BoxImageNews>
                                        <TextNewsDivContainer>
                                            <CommentTitle>
                                                {item.nome}

                                                {(permission_aux >= 2 ?
                                                    <Icon
                                                        type="editar"
                                                        animated
                                                        onClick={() => editShowClose(item.id)}
                                                    />
                                                :'')}
                                            </CommentTitle>
                                            {(
                                                item.hashtags ?
                                                    <NewsHashtag>
                                                        {item.hashtags}
                                                    </NewsHashtag>
                                                    : ""
                                            )}
                                            <DescriptionParagraph>
                                                {parse(item.descricao)}
                                            </DescriptionParagraph>
                                        </TextNewsDivContainer>
                                        <ActionSection item={item} callback={callback_refresh} i={index} permission={permission_aux} />
                                    </NewsLiContainer>
                                )
                                }) 
                            :
                                (props.integrated ?
                                    <div className="news_integrated_empty">
                                        Nenhuma news encontrada para: <b>{props?.busca}</b>
                                    </div>
                                :
                                    <div className="erroHashtag">
                                        <Icon type="sad" className="erroHashtagIcon" />
                                        <h3 className="erroHashtagTitle">Não encontramos nenhum resultado</h3>
                                        <p>Certifique-se de que tudo está escrito corretamente ou tente hashtags diferentes</p>
                                    </div>
                                )
                            )
                        :
                            [...Array(3)].map((card, i) => (
                                <NewsLiContainer loading={true}></NewsLiContainer>
                            ))
                        )}
                    </ul>
                </Col>

                {(!props.widget && props?.search !== false ?
                    <Col>
                        <SearchNewsTitle>
                            Busca
                        </SearchNewsTitle>
                        <SearchNewsDiv>
                            <label>
                                #
                            </label>
                            <SearchNewsInput
                                type="text"
                                name="hashtag"
                                placeholder="Busca por Hashtag"
                                className="inputBuscaNews"
                                value={hashtag}
                                onChange={e => (setHashtag(e.target.value), setStatus(1))}
                            />
                        </SearchNewsDiv>
                    </Col>
                :'')}
            </Row>
        </div >
    )
}
import { useEffect, useState, useContext, useRef, useMemo } from 'react';

import style from './Card.module.scss';
import Icon from '../../body/icon';
import Tippy from '@tippyjs/react';
import Loader from '../loader';
import Chat from '../../../components/body/chat';
import Message from '../../../components/body/chat/message';
import axios from 'axios';
import './sharedStyles.scss';

import { GlobalContext } from "../../../context/Global";
import { JobsContext } from "../../../context/Jobs";
import Editar from './editar';
import Recusar from './recusar';
import { cd, cdh, get_date } from '../../../_assets/js/global';
import Input from '../form/input';
import { toast } from 'react-hot-toast';
import Form from '../form';
import TrocaOperador from './trocaOperador';
import Mensagens from './mensagens';
import Modal from '../modal';
import ModalHeader from '../modal/modalHeader';
import ModalTitle from '../modal/modalHeader/modalTitle';
import ModalBody from '../modal/modalBody';
import Button from '../button';

export default function Card(props){
    // GLOBAL CONTEXT
    const { refreshCalendar, refreshChat, handleRefreshChat, filterModule, handleSetPrevIndex, refresh, cardExternal, handleSetCardExternal, firstLoad, handleSetOpenExternal, openExternal } = useContext(GlobalContext);

    // JOBS CONTEXT
    const { optionsStatus, optionsSystems, configuracoes } = useContext(JobsContext);

    // REF
    const cardRef = useRef(null);
    const cardInIvew = useIsInViewport(cardRef);

    function useIsInViewport(ref) {
        const [inView, setInView] = useState(false);

        const observer = useMemo(() =>
            new IntersectionObserver(([entry]) =>
            setInView(entry.isIntersecting),
            ),
        [],);

        useEffect(() => {
            if(cardRef !== null && ref.current){
                observer.observe(ref.current);

                return () => {
                    observer.disconnect();
                };
            }
        }, [ref, observer]);

        return inView;
    }

    // ESTADOS
    const [collapse, setCollapse] = useState(false);
    const [hover, setHover] = useState(false);
    const [searchMessages, setSearchMessages] = useState(true);
    const [showMessages, setShowMessages] = useState(false);
    const [messages, setMessages] = useState([]); 
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anexos, setAnexos] = useState([]);
    const [maximized, setMaximized] = useState(false);
    const [loadCardInternal, setLoadCardInternal] = useState(false);
    const [cardsInternal, setCardsInternal] = useState([]);
    const [position, setPosition] = useState((props?.position?.position ? props.position?.position : ''));
    const [hide, setHide] = useState([]);
    const [viewArchived, setViewArchived] = useState(false);
    const [microssistemaValues, setMicrossistemaValues] = useState([]);
    const [microssistemaCustomOptions, setMicrossistemaCustomOptions] = useState([]);
    const [microssistemaOptionsLoja, setMicrossistemaOptionsLoja] = useState([]);
    const [infoLoja, setInfoLoja] = useState('');
    const [infoLojaLoading, setInfoLojaLoading] = useState(false);
    const [infoUsuario, setInfoUsuario] = useState('');
    const [modalArchive, setModalArchive] = useState(false);
    const [dateStartArchive, setDateStartArchive] = useState(new Date(window.currentDate));
    const [dateEndArchive, setDateEndArchive] = useState('');    
    const [buttonStateArchive, setButtonStateArchive] = useState('');

    // ARQUIVAR CARDS INTERNO APÓS CLIQUE DO BOTÃO
    function handleArchive(id_job, id_job_status, status, title, date_start, period) {
        if(period){
            setModalArchive({
                id_job: id_job,
                id_job_status: id_job_status,
                status: status,
                title: title,
                period: period,
                date_start: date_start,
                show: true
            });

            if(date_start){
                setDateStartArchive(new Date(get_date('add_days', date_start, 'date_sql', 1)));
            }
        }else{
            if (window.confirm('Deseja ' + (status === 1 ? 'arquivar' : 'desarquivar') + ' esse card?')) {
                axios({
                    method: 'post',
                    url: window.host_madnezz + '/systems/integration-react/api/list.php?do=closedJob',
                    data: {
                        id_job: id_job,
                        id_mov: id_job_status,
                        ativ_desc: 'Arquivou o card',
                        closed: status
                    },
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(() => {
                    toast("Card arquivado com sucesso!");
                    get_card_internal(true, (viewArchived ? true : false));
                })
            }
        }
    }

    // ESTADO BOTÃO DE ARQUIVAR
    const handleSetButtonState = (e) => {
        setButtonStateArchive(e);
    }

    // VER CARDS ARQUIVADOS
    const handleViewArchived = () => {
        setViewArchived(!viewArchived);
        get_card_internal(false, !viewArchived);
    }

    // SETA ANEXOS VINDOS DO CARD
    useEffect(() => {
        if (anexos.length == 0 && props.files) {
            if (anexos.length == 0 && props.files) {
                if(props.files.includes('{')){ // MODELO NOVO DE UPLOAD (TODAS AS INFORMAÇÕES DO ARQUIVO)
                    setAnexos(JSON.parse(props.files));
                }else{ // MODELO ANTIGO DE UPLOAD (SOMENTE ID)
                    let anexosValues = [];
                    props?.files.split(',').map((item, i) => {
                        anexosValues.push(item);
                    });
    
                    setAnexos(anexosValues);
                }
            }
        }
    });

    // ATUALIZA POSIÇÃO INICIAL
    useEffect(() => {
        setPosition(props?.position?.position);
    },[props?.position?.position]);

    var border_aux;
    var bg_aux;
    var messageFiles = [];
    var title = '';
    const actions = (props.actions?props.actions:'');

    {(() => {
        switch(props.border){
            case 'blue':
                border_aux = style.border__blue;
                break;
            case 'orange':
                border_aux = style.border__orange;
                break;
            case 'green':
                border_aux = style.border__green;
                break;
            case 'red':
                border_aux = style.border__red;
                break;
            case 'dark':
                border_aux = style.border__dark;
                break;
            case 'light_gray':
                border_aux = style.border__light_gray;
                break;
            case 'dark_gray':
                border_aux = style.border__dark_gray;
                break;
            case 'purple':
                border_aux = style.border__purple;
                break;
            default:
                border_aux = '';
        }

        switch(props.background){
            case 'blue':
                bg_aux = style.bg__blue;
                break;
            case 'orange':
                bg_aux = style.bg__orange;
                break;
            case 'dark_orange':
                bg_aux = style.bg__dark_orange;
                break;
            case 'green':
                bg_aux = style.bg__green;
                break;
            case 'red':
                bg_aux = style.bg__red;
                break;
            case 'dark':
                bg_aux = style.bg__dark;
                break;
            case 'light_gray':
                bg_aux = style.bg__light_gray;
                break;
            case 'dark_gray':
                bg_aux = style.bg__dark_gray;
                break;
            case 'purple':
                bg_aux = style.bg__purple;
                break;
            default:
                bg_aux = '';
        }
    })()}

    // DEFINE TITLE
    if(props?.title){
        if(props?.position?.position && props?.position?.enabled){
            title = <>
                {(props?.position?.enabled ?
                    <Input
                        type="tel"
                        name="card_position"
                        value={position}
                        style={{width : (position > 9 ? 22 : 12)}}
                        onChange={(e) => setPosition(e.target.value)}
                        onKeyPress={(e) => (e.key === 'Enter' ? changePosition(props?.parent?.id_job_status, props?.parent?.id_job, position) : {})}
                        maxLength={2}
                        focusSelect={true}
                    />
                :
                    position
                )}
                <span className="me-2">.</span>
                {props.title}
            </>
        }else{
            title = props.title;
        }
    }else{
        title = 'Card';
    }
    
    let optionsSystems_aux = ['223']; // POR PADRÃO O JOBS (223)
    if(optionsSystems){
        optionsSystems.map((option, i) => {
            if(option.value != 227){ // SE FOR DIFERENTE DE OBRAS (227)
                optionsSystems_aux.push(option.value.toString());
            }
        });
    }    

    // BUSCA MENSAGENS
    function getMessage(id, forceReload){
        if(props?.size !== 'smallest'){
            if(!collapse){
                setSearchMessages(true);
            }

            if(id){
                if(!collapse || forceReload){
                    axios.get(window.host_madnezz+'/systems/integration-react/api/list.php?do=get_msg&filter_id_module='+filterModule+'&id='+id).then((response) => {
                        setMessages(response.data);
                        setSearchMessages(false);
                        handleRefreshChat(false);

                        setTimeout(() => {
                            var divChat = document.getElementById('chat_'+id);
                            if(divChat){     
                                divChat.scrollTop = divChat.scrollHeight;
                            }
                        },50);

                        // CONCATENA ANEXOS
                        if (response.data) {
                            var messageFiles = [];
                            response.data.map((mensagem, i) => {
                                if (mensagem.anexos) {
                                    if(mensagem?.anexos.includes('{')){ // MODELO NOVO DE UPLOAD (TODAS AS INFORMAÇÕES DO ARQUIVO)
                                        JSON.parse(mensagem?.anexos).map((item, i) => {
                                            if (!anexos.includes(item.id)) {
                                                messageFiles.push(item);
                                            }
                                        });
                                    }else{ // MODELO ANTIGO DE UPLOAD (SOMENTE ID)
                                        mensagem?.anexos.split(',').map((item, i) => {
                                            if (!anexos.includes(item)) {
                                                messageFiles.push(item);
                                            }
                                        });
                                    }
                                }
                            });

                            if (messageFiles) {
                                if (anexos.length > 0) {
                                    anexos.map((anexo, i) => {
                                        const itemExists = messageFiles.some(item => item.id === anexo.id);
                                        if (!itemExists) {
                                            messageFiles.push(anexo);
                                        }
                                    });
                                }
                            }

                            setAnexos(messageFiles);
                        }

                        if(props.opened){
                            setShowMessages(true);
                        }
                    });    
                }
            }
        }
    }

    // CHAMA A FUNÇÃO DE BUSCAR MENSAGENS CASO RECEBA A PROPS "OPENED"
    useEffect(() => {
        if(props.opened && props?.id_aux){
            getMessage(props?.id_aux);
        }
    },[props?.opened]);

    // FUNÇÃO PARA FECHAR O CARD SE O CALENDÁRIO ATUALIZAR  
    useEffect(() => {
        setCollapse(false);
    }, [refresh]);

    // FUNÇÃO PARA RECARREGAR CHAT SEMPRE QUE RECEBER NOVA MENSAGEM
    useEffect(() => {
        if(props.header === false){
            getMessage(props.id_aux)
        }
    },[refreshChat]);

    function handleSetExternal(){
        handleSetCardExternal({
            enabled: !maximized,
            id: (!maximized ? props?.parent?.id_job_status : '')
        });

        if(!maximized){
            handleSetPrevIndex(props?.swiper?.activeIndex);
            get_card_internal();
        }

        setMaximized(!maximized);
        setCollapse(false);

        if(props?.expand){
            props?.expand.callback({
                index: props?.expand?.index,
                id: (!maximized ? props?.parent?.id_job_status : '')
            });
        }
        
        // if(props?.parent){
        //     axios({
        //         method: 'get',
        //         url: window.host_madnezz+"/systems/integration-react/api/list.php?do=get_list",
        //         params: {
        //             id_job_status: props?.parent?.id_job_status
        //         }
        //     })
        // }
    }

    const reloadChat = () => {
        handleRefreshChat(true);
        getMessage(props.id_aux, true);
    }

    // REFRESH/RELOAD CARD
    const handleRefreshCard = (e) => {
        if(props?.refreshCard){
            props?.refreshCard(e);
        }
    }

    // MINIMIZAR
    const handleMinimize = (e) => {
        if(!hide.includes(e)){
            setHide(hide => [...hide, e]);
        }        
    }

    // RESETA O ESTADO DE HIDE QUANDO CLICAR PARA MAXIMIZAR (VOLTA A EXIBIR TODOS)
    useEffect(() => {
        setHide([]);
        setCardsInternal([]);
        setLoadCardInternal(false);
        setViewArchived(false);
    },[maximized]);

    // GET CARDS INTERNOS
    function get_card_internal(reloadParent, viewArchived) {
        if (props?.id_group && props.inner !== true) {
            axios({
                method: 'get',
                url: window.host_madnezz + '/systems/integration-react/api/list.php',
                params: {
                    do: 'get_select',
                    filter_id_group: props?.id_group,
                    card_internal: true,
                    filter_closed_job: (viewArchived ? [0, 1] : [0])
                }
            }).then((response) => {
                setCardsInternal(response.data);
                setLoadCardInternal(true);

                if (reloadParent) {
                    // props?.callback(true);

                    setTimeout(() => {
                        props?.callback(false);
                    }, 100);
                }
            });
        }
    }

    useEffect(() => {
        if(props?.reload?.internal && collapse){
            get_card_internal();
        }
    },[props?.reload?.internal]);

    // SCROLL CHAT
    function scrollChat(){
        setTimeout(() => {
            if(props?.id_aux){
                var divChat = document.getElementById('chat_'+props.id_aux);
                if(divChat){     
                    divChat.scrollTop = divChat.scrollHeight;
                }
            }
        },50);
    }

    // GET PREENCHIMENTO MICROSSISTEMA (CADASTRO)
    function get_form_microssistema(){
        let microssistema_aux = JSON.parse(props?.aux_form);
        
        if(microssistemaValues.length == 0){
            axios({
                method: 'get',
                url: window.host+'/systems/microssistemas-novo/api/novo.php?do=get_microssistema',
                params: {
                    tipo: microssistema_aux?.tipo,
                    microssistema_id: microssistema_aux?.microssistema_id,
                    relatorio_id: microssistema_aux?.id_aux_form
                }
            }).then((response) => {
                setMicrossistemaValues(response?.data?.itens);
                let custom_options_aux = [];

                // CHECA TIPOS DE COMPONENTES PARA FAZER CONSULTAS EXTRAS NECESSÁRIAS
                response?.data?.itens.map((item, i) => {
                    if(item.componente_id == global?.componentes?.select_personalizado || item.componente_id == global?.componentes?.checkbox_personalizado){ // CHECA SE EXISTE SELECT PERSONALIZADO
                        if(microssistemaCustomOptions.length == 0){
                            axios({
                                method: 'get',
                                url: window.host+'/systems/microssistemas-novo/api/novo.php?do=get_opcoes',
                                params: {
                                    item_id: item?.id
                                }
                            }).then((response) => {
                                if(response.data.length > 0){
                                    response.data.map((item, i) => {
                                        custom_options_aux.push(item);
                                    });
                                }

                                setMicrossistemaCustomOptions(custom_options_aux);
                            });
                        }
                    }else if(item.componente_id == global?.componentes?.select_loja){ // CHECA SE EXISTE SELECT PERSONALIZADO
                        if(microssistemaOptionsLoja.length == 0){
                            axios({
                                method: 'get',
                                url: window.host+'/api/sql.php?do=select&component=loja'
                            }).then((response) => {
                                setMicrossistemaOptionsLoja(response?.data);
                            });
                        }
                    }
                })
            });
        }
    }

    // GET INFOS LOJA
    function get_info_loja(loja_id){
        setInfoLojaLoading(true);

        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Job',
            params: {
                do: 'getTable',
                tables: [{
                    table: 'store',
                    filter: {id: loja_id}
                }]
            }
        }).then((response) => {
            if(response?.data?.data?.store[0]){
                setInfoLoja(response?.data?.data?.store[0]);
            }
            setInfoLojaLoading(false);
        });
    }

    // GET INFOS USUÁRIO
    function get_info_usuario(usuario_id){
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Job',
            params: {
                do: 'getTable',
                tables: [{
                    table: 'user',
                    filter: {id: usuario_id}
                }]
            }
        }).then((response) => {
            if(response?.data?.data?.user[0]){
                setInfoUsuario(response?.data?.data?.user[0]);
            }
        });
    }

    // FUNÇÕES AO ABRIR CARD
    function handleSetCollapse(){
        if(collapse){
            setCollapse(false);
            setShowMessages(false);
            setLoadCardInternal(false);
        }else{
            setCollapse(true);
            get_card_internal();

            if(props?.aux_form){
                get_form_microssistema();
            }
            
            // VERIFICA SE O CARD TEM A INFORMAÇÃO PAR_AUX_SUBCATEGORIA
            if(props?.parent?.par_aux_subcategoria){
                
                // VERIFICAR SE O CARD TEM A BUSCA DE INFORMAÇÕES DA LOJA NA SUBCATEGORIA, SE POSSUI ID DE LOJA E SE AINDA NÃO POSSUI INFORMAÇÕES DA LOJA JÁ CARREGADAS
                if(JSON.parse(props?.parent?.par_aux_subcategoria)?.info_loja && props?.parent?.cad_lja && !infoLoja){
                    get_info_loja(props?.parent?.cad_lja);
                }

                if(JSON.parse(props?.parent?.par_aux_subcategoria)?.info_usuario && props?.parent?.cad_usr && !infoUsuario){
                    get_info_usuario(props?.parent?.cad_usr);
                }
            }
        }
    }

    // FUNÇÕES AO ABRIR CHAT
    function handleShowMessages(){
        setShowMessages(!showMessages);
        if(!collapse){
            getMessage(props.id_aux, true);
            setCollapse(true);
            get_card_internal();
        }

        scrollChat();
    }

    // SE RECEBER A PROPS "OPENED" SETA COMO ABERTO
    useEffect(() => {
        if(props?.opened){
            handleSetCollapse(props?.parent?.id_job, props?.parent?.id_job_status);
        }
    },[props?.opened]);

    // FUNÇÃO PARA TROCA DE STATUS DOS CARDS INTERNOS
    function changeStatus(ativ_desc, id_job, id_job_status, status, msg = undefined, data_aux = undefined, tipo = undefined, tipo_fase = undefined) {    
        axios({
            method: 'post',
            url: window.host_madnezz+"/systems/integration-react/api/list.php?do=set_status&filter_id_module="+filterModule,
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                status: status,          
                msg: msg,
                data_aux: data_aux,
                tipo: tipo,
                tipo_fase: tipo_fase
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            let message = '';

            if(status == 1){
                message = 'Finalizou um card interno';
            }else if(status == 2){
                message = 'Sinalizou um card interno como "Não tem"';
            }else if(status == 3){
                message = 'Finalizou com atraso um card interno';
            }

            set_message(props?.parent?.id_job_status, message, 'Trocou de operador interno'); // ENVIA MENSAGEM PRO CARD PAI
            get_card_internal(); // RECARREGA CARDS INTERNOS
            if(props.callback){
                props.callback(true)
            }
        });
    }

    // FUNÇÃO PARA ADIAR CARD INTERNO
    function setDate(ativ_desc, id_job_status, date) {      
        axios({
            method: 'post',
            url: window.host_madnezz+"/systems/integration-react/api/list.php?do=set_status&filter_id_module="+filterModule,
            data: {
                ativ_desc: ativ_desc,
                id_mov: id_job_status,
                status: 4,
                postpone_date: cd(date),
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            set_message(props?.parent?.id_job_status, 'Adiou um card interno', 'Adiou card interno'); // ENVIA MENSAGEM PRO CARD PAI
            get_card_internal(); // RECARREGA CARDS INTERNOS
        });
    }

    // FUNÇÃO PARA ENVIAR MENSAGEM
    function set_message(id_job_status, message, ativ=undefined){
        axios({
        method: 'post',
        url: window.host_madnezz+'/systems/integration-react/api/list.php?do=set_msg&filter_id_module='+filterModule,
        data: {
            ativ: (ativ ? ativ : 'Enviou uma mensagem'),
            id_mov: id_job_status,
            ativ_desc: message
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            getMessage(props.id_aux, true);
        });
    }

    // FUNÇÃO PARA TROCAR POSIÇÃO
    function changePosition(id_job_status, id_job, posicao) {  
        let posicoes = [];
        let append = false;

        if(props?.position?.group){
            props.position.group.map((item, i) => {
                if(id_job_status ==  item.id_job_status){
                    posicoes.push({id: item.id_job_status, position: (Number(posicao) - 1)})
                }else{
                    if((Number(posicao) - 1) == i){
                        posicoes.push({id: item.id_job_status, position: (i + 1)})
                        append = true;
                    }else{
                        posicoes.push({id: item.id_job_status, position: (append ? (i + 1) : i)})
                    }
                }
            })

            // ORDENAÇÃO A PARTIR DO POSITION QUE FOI DEFINIDO
            posicoes = posicoes.sort(
                function(a, b){
                    if(a.position < b.position){
                        return -1;
                    }
                    if(a.position > b.position){
                        return 1;
                    }
                    return 0;
                }
            );
        }        

        axios({
            method: 'post',
            url: window.host_madnezz+"/systems/integration-react/api/list.php?do=set_position&filter_id_module="+filterModule,
            data: {
                ativ_desc: 'Alterou a posição do card',
                ids_mov: id_job_status,
                id_job: id_job,
                posicao: posicoes
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            toast('Posição alterada');
            if(!firstLoad){
                refreshCalendar();
            }
            handleRefreshChat(true);
        });
    }

    // RETORNO DA TROCA DE OPERADOR
    const handleChangeOperator = () => {
        get_card_internal();
    }

    // CALLBACK DO COMPONENTE DE MENSAGENS (CARD MAXIMIZADO)
    const handleCallbackMensagens = (e) => {
        if(e?.offsetLeft){            
            let container = document.getElementById('card_maximized_container');
            container.scrollLeft = (e?.offsetLeft - 1120);
        }
    }

    // CALLBACK SUBMIT ARQUIVAR POR PERÍODO
    const handleCallbackArchive = () => {
        setModalArchive(false);
        setDateStartArchive(new Date(window.currentDate));
        setDateEndArchive('');
        refreshCalendar(undefined, true);
    }

    // SCROLL HORIZONTAL ATÉ O ELEMENTO
    function handleScrollToElement(id) {
        let hide_aux = hide.filter((elem) => elem !== id);
        setHide(hide_aux);

        setTimeout(() => {
            let container = document.getElementById('card_maximized_container');
            let elements = document.getElementsByClassName('container_box');
            let element = document.getElementById('container_box_' + id);

            let elements2 = document.getElementsByClassName('card_element');
            let element2 = document.getElementById('card_internal_' + id);

            for(let i=0; i < elements.length; i++){
                elements[i].classList.remove('focus');
            }

            for(let i=0; i < elements2.length; i++){
                elements2[i].classList.remove('focus');
            }

            element.classList.add('focus');
            element2.classList.add('focus');

            container.scrollLeft = (element?.offsetLeft - 1120);
        },100);
    }

    //AJUSTA A ALTURA DO IFRAME
    function iframeHeight(window_name, window_height) {
        const iframe = document.querySelector(`iframe[name="${window_name}"]`);
        iframe.height = window_height;
    }

    useEffect(() => {
        if(props?.iframe && collapse){
            setTimeout(() => {
                window.addEventListener("message", (event) => {
                    if (
                        event.origin === "http://malltech.test" ||
                        event.origin === "http://localhost:3000" ||
                        event.origin === "http://localhost:3001" ||
                        event.origin === "https://local.malltech.com.br" ||
                        event.origin === process.env.REACT_APP_URL_DEV ||
                        event.origin === process.env.REACT_APP_URL_PROD
                    ) {
                        if (event.data.function_type === "iframeHeight") {
                            iframeHeight(event.data.iframe_name, event.data.iframe_height);
                        }
                    }
                });
            },1000);
        }
    }, [collapse]);

    if(props.loading){
        return(
            <>
                {(!props.inner ?
                    <div className={ style.title__loading }></div>
                :'')}

                {[...Array(props.qtd)].map((card, i) => (
                    <div
                        key={'loading_card_'+i}
                        className={ style.card + ' ' + style.card__loading + ' ' + (props?.widget ? style.widget : '') + ' ' + (props.size==='small'?style.card__small:'') + ' ' + (props.size==='smallest'?style.card__smallest:'') + ' ' + (props.inner?style.inner:'')}
                        // style={{width:(props.width?props.width:348)}}
                    ></div>
                ))}
            </>
        )
        
    }else if(props.empty){
        return(
            <div
                className={ style.card + ' ' + style.card__empty + ' ' + (props?.widget ? style.widget : '') + ' ' + (props.size==='small'?style.card__small:'') + ' ' + (props.size==='smallest'?style.card__smallest:'')}
                // style={{width:(props.width?props.width:348)}}
            >
                {(props.title?props.title:'Nenhum')}
            </div>
        )
    }else{
        let showCard = true;
        // if((cardExternal?.id && cardExternal.enabled && cardExternal?.id == props?.parent?.id_job_status) || (!cardExternal || !cardExternal?.id || !cardExternal.enabled)){
        //     showCard = true;
        // }else{
        //     showCard = true;
        // }

        // DEFINE VARIÁVEL DOS CARDS INTERNOS
        let cards_internos_aux;        
        if(props?.internal !== false && props?.size !== 'smallest'){
            if(loadCardInternal && props.inner !== true){
                let cards_pendentes = cardsInternal;

                cards_internos_aux = <div className={(cardsInternal.length > 0 ? 'mt-3' : '')}>
                                        {cards_pendentes.map((card, i) => {
                                            let title;
                                            let subtitle;
                                            let background;

                                            // DEFINE TITLE
                                            if(card.loja){
                                                title = card.loja + ' - ' + card?.job;
                                            }else if(card.usuario){
                                                title = card.usuario + ' - ' + card?.job;
                                            }else{
                                                title = card.job;
                                            }

                                            // DEFINE SUBTITLE
                                            if(card.cliente){
                                                subtitle = card.data_inicio_formatada + (card.cliente ? ' - ' + card.cliente : '');
                                            }else{
                                                subtitle = card.data_inicio_formatada;
                                            }

                                            if(card.data_adiado) { // ADIADO
                                                background = optionsStatus.filter((el) => {
                                                return el.status_name == 'adiado';
                                                })[0]?.cor;
                                            }else{
                                                if ((card.status == 0 || card.status == 4) && card.data_job > window.currentDate) { // PADRÃO
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'nao_feito';
                                                })[0]?.cor;
                                                } else if ((card.status == 0 || card.status == 4) && card.data_job < window.currentDate && card.status_supervisor != 3) { // ATRASADO
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'atrasado';
                                                })[0]?.cor;
                                                } else if (card.status == 0 && card.data_job < window.currentDate && card.status_supervisor == 3) { // ATRASADO REABERTO
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'atrasado';
                                                })[0]?.cor;
                                                } else if (card.status == 1) { // CONCLUÍDO NO PRAZO SEM AVALIAÇÃO DO SUPERVISOR
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'feito';
                                                })[0]?.cor;
                                                } else if (card.status == 2) { // NÃO TEM
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'nao_tem';
                                                })[0]?.cor;
                                                } else if (card.status == 3) { // CONCLUÍDO COM ATRASO
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'feito_com_atraso';
                                                })[0]?.cor;
                                                } else if (card.status == 5) { // CONCLUÍDO COM RESSALVA
                                                background = optionsStatus.filter((el) => {
                                                    return el.status_name == 'feito_com_ressalva';
                                                })[0]?.cor;
                                                } else {
                                                background = '';
                                                } 
                                            }

                        return (
                            <Card
                                key={'card_internal_' + card.id_job_status}
                                inner={true}
                                id={'card_internal_' + card.id_job_status}
                                id_aux={card.id_job_status}
                                background={background}
                                parent={card}
                                title={title}
                                subtitle={subtitle}
                                description={card.descricao}
                                files={card.anexos}
                                fullwidth={props?.fullwidth}
                                chat={
                                    {
                                        api: window.host_madnezz + '/systems/integration-react/api/list.php?do=set_msg&filter_id_module=' + filterModule,
                                        data: {
                                            // nivel_msg: nivel_msg
                                        },
                                        id: card.id_job_status,
                                        defaultMessage: {
                                            date: (card.cad_data ? cdh(card.cad_data) : ''),
                                            sender: card.cad_usr_nome,
                                            text: 'Abriu o ' + (props.chamados ? 'chamado' : 'job'),
                                            align: (card.cad_usr == window.rs_id_usr ? 'right' : 'left')
                                        },
                                        send: (() => { // CHECA SE O ENVIO DE MENSAGENS DEVE OU NÃO SER HABILITADO
                                            if (props?.card?.desabilitar?.split(',').includes('5')) {
                                                return false;
                                            } else {
                                                if (props.chamados) { // SE ESTIVER EM CHAMADOS
                                                    if (props?.card?.dias < 0) {
                                                        return false
                                                    } else {
                                                        if (props?.job.tipo_fase != 'Pós-venda' && (window.rs_id_lja == 0 || !window.rs_id_lja) && (filterModule == 2361 && window.rs_id_emp == 26 || filterModule != 2361)) {
                                                            if (props?.job.tipo_fase == 'Check') { // SE ESTIVER NA FASE DE CHECK
                                                                if (props?.card?.id_usr_sup) { // SE ESTIVER NA FILA DE UM OPERADOR NO CHECK
                                                                    if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') { // SE A PERMISSÃO FOR MENOR QUE "CHECKER"
                                                                        return false;
                                                                    } else {
                                                                        if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                            return false;
                                                                        } else {
                                                                            if (props?.card?.id_usuario == window.rs_id_usr) {
                                                                                return true;
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        }
                                                                    }
                                                                } else {
                                                                    return false;
                                                                }
                                                            } else if (props?.job.tipo_fase == 'Início') {
                                                                if (!props?.card?.recebido || props?.card?.recebido == 0) {
                                                                    return true;
                                                                } else {
                                                                    if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') {
                                                                        return false;
                                                                    } else {
                                                                        if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                            return false;
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                }
                                                            } else {
                                                                if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                    return false;
                                                                } else {
                                                                    if (props?.card?.id_usuario == window.rs_id_usr) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            if (props?.job.tipo_fase == 'Início') {
                                                                if (!props?.card?.recebido || props?.card?.recebido == 0) {
                                                                    return true;
                                                                } else {
                                                                    if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') {
                                                                        return false;
                                                                    } else {
                                                                        if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                            return false;
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                }
                                                            } else {
                                                                if (window.rs_sistema_id == 238 && window.rs_id_emp != 26) { // VERIFICA SE ESTÁ NO CHAMADOS DE MANUTENÇÃO E SE O EMPREENDIMENTO É DIFERENTE DE 26
                                                                    return false;
                                                                } else {
                                                                    if (props?.job.tipo_fase == 'Pós-venda') {
                                                                        return false;
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (props?.card?.data_adiado) {
                                                        return false;
                                                    } else {
                                                        if (props?.card?.status == 1 || props?.card?.status == 2 || props?.card?.status == 3) {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    }
                                                }
                                            }
                                        })()
                                    }
                                }
                                actions={
                                    <>
                                        {/* VISUALIZAÇÃO APÓS MAXIMIZAR */}
                                        {(maximized && cardExternal.enabled ?
                                            <Icon
                                                type="view"
                                                title={(collapse ? 'Mostrar menos' : 'Mostrar mais')}
                                                animated
                                                active={collapse}
                                                onClick={() => handleScrollToElement(card?.id_job_status)}
                                            />
                                            : '')}

                                        {/* BOTÃO DE ARQUIVAR SOMENTE PARA QUEM TEM NÍVEL DE ACESSO MASTER E NÃO ESTIVER NO SISTEMA CHAMADOS"*/}
                                        {(window.rs_permission_apl === 'master' && !props.chamados ?
                                            <Icon
                                                type={(card?.job_arquivado === 1 ? 'unarchive' : 'archive')}
                                                title={(card?.job_arquivado === 1 ? 'Desarquivar' : 'Arquivar')}
                                                onClick={() => handleArchive(card.id_job, card.id_job_status, (card?.job_arquivado === 1 ? 0 : 1), undefined, undefined, false)}
                                                animated
                                            />
                                        : '')}

                                                            {(card.status == 0 || card.status == 4 ?  
                                                                <>      
                                                                    {(card.id_loja == window.rs_id_lja || card.id_usuario == window.rs_id_usr || window.rs_permission_apl === 'master' ? // RESPONSÁVEL PELO CARD OU NÍVEL DE ACESSO MASTER
                                                                        <Icon
                                                                            type="calendar"
                                                                            title="Adiar"
                                                                            datepicker={true}
                                                                            valueStart={new Date(card.data_job)}
                                                                            animated
                                                                            onChange={(e) => setDate("Adiou o job de " + card.data_formatada + " para " + cd(e), card.id_job_status, e)}
                                                                        />
                                                                    :'')}

                                                                    {(card.id_loja == window.rs_id_lja || card.id_usuario == window.rs_id_usr ? // RESPONSÁVEL PELO CARD
                                                                        <>
                                                                            <Recusar
                                                                                chamados={props.chamados}
                                                                                fases={props.fases}
                                                                                id_job={card.id_job}
                                                                                id_job_status={card.id_job_status}
                                                                                title={(props.chamados ? 'Recusar' : 'Não tem')}
                                                                                modalTitle={(props.chamados ? 'Recusar' : 'Não tem') + ' - ' + card.job}
                                                                            />

                                                                            <Icon
                                                                                type="aprovar"
                                                                                title="Finalizar"
                                                                                onClick={() => changeStatus(
                                                                                    "Finalizou o card",
                                                                                    card.id_job,
                                                                                    card.id_job_status,
                                                                                    card.data_job < window.currentDate ? 3 : 1,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    props?.parents?.tipo_fase
                                                                                )}
                                                                            />
                                                                        </>
                                                                    : '')}
                                                                </>
                                                            :'')}
                                                        </>
                                                    }
                                                >
                                                    {(props?.internal?.params && window.rs_permission_apl !== 'leitura' ? 
                                                        <TrocaOperador
                                                            label={'Trocar de operadors'}
                                                            options={props?.internal?.params?.options}
                                                            fases={props?.fases}
                                                            chamados={props?.chamados}
                                                            visitas={props?.visitas}
                                                            params={{
                                                                id_modulo: card?.id_modulo,
                                                                id_job: card?.id_job,
                                                                id_job_status: card?.id_job_status,
                                                                tipo_fase: card?.tipo_fase,
                                                                ativ_desc: props?.internal?.params?.ativ_desc,
                                                                filterModule: props?.internal?.params?.filterModule,
                                                                filter_subtype: props?.internal?.params?.filter_subtype
                                                            }}
                                                            onChange={handleChangeOperator}
                                                        />
                                                    :'')}
                                                </Card>
                                            );                                
                                        })}

                                        {(!props?.parent?.id_grupo_mov ?
                                            <div className={(cardsInternal.length == 0 ? 'mt-3' : '') + ' d-flex align-items-center '+(props?.fases ? 'justify-content-end' : 'justify-content-end')}>
                                                {/* {(props.fases ?
                                                    <Icon
                                                        type="trash"
                                                        title="Ver arquivados"
                                                        animated
                                                    />
                                                :'')} */}

                                                {((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && !props?.parent?.id_grupo_mov && props?.internal?.new !== false && (props?.fases || props?.visitas) ? // SE A PERMISSÃO FOR MAIOR QUE GERENTE MOSTRA BOTÃO DE CRIAR CARDS INTERNOS                                                                                                 
                                                    <Editar
                                                        empty={true}
                                                        dateStart={(props?.parents?.date > window.currentDateWithoutHour ? props?.parents?.date : window.currentDateWithoutHour)}
                                                        frequency={4}
                                                        title="Criar novo card dentro do grupo"
                                                        tipo="usuario"
                                                        modulo={filterModule}
                                                        modalTitle="Novo card interno"
                                                        id_group={props?.parent?.id_job}
                                                        id_job_status_parent={props?.parent?.id_job_status}
                                                        id_system={optionsSystems_aux}
                                                        // job={props?.parent?.job}
                                                        category={props?.parent?.id_categoria}
                                                        subcategory={props?.parent?.id_subcategoria}
                                                        tipo_fase={props?.parent?.tipo_fase}
                                                        id_client={props?.parent?.id_cliente}    
                                                        callback={(e) => (e === true ? get_card_internal(true) : {})}
                                                        refreshCard={handleRefreshCard}
                                                    />
                                                :'')}
                                            </div>
                                        :'')}
                                    </div>;
            }else{
                if(props.inner !== true && !props?.parent?.id_grupo_mov){
                    cards_internos_aux = <div className="mt-3">
                                            {[...Array(2)].map((card, i) => (
                                                <Card
                                                    key={'card_internal_loading_'+i}
                                                    inner={true}
                                                    loading={true}
                                                    fullwith={props?.fullwidth}
                                                />
                                            ))}
                                        </div>;
                }
            }
        }     
        
        // DEFINE VARIAVEL DOS CARDS
        let card_aux = <div
                            id={props.id}
                            ref={cardRef}
                            className={style.card + ' ' + (props?.modal ? style.in_modal : '') + ' ' + (props.focus ? style.focus : '') + ' ' + (props?.widget ? style.widget : '') + ' card_element ' + (props?.disabled ? style.disabled : '') + ' ' + (props.size === 'small' ? style.card__small : '') + ' ' + (props.size === 'smallest' ? style.card__smallest : '') + ' ' + (props.inner ? style.inner : '')}
        >
            {((cardRef && cardInIvew && props.visitas) || !props.visitas ?
                <>
                    <Modal show={modalArchive?.show} onHide={() => (setModalArchive(false), setDateStartArchive(new Date(window.currentDate)), setDateEndArchive(''))}>
                        <ModalHeader>
                            <ModalTitle>
                                {(modalArchive?.title ? modalArchive?.title : 'Selecionar período')}
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                api={window.host_madnezz + '/systems/integration-react/api/list.php?do=closedJob'}
                                data={{
                                    id_job: modalArchive?.id_job,
                                    ativ_desc: ((props?.parent?.job_arquivado ? 'Desarquivou' : 'Arquivou') + ' o card de '+cd(dateStartArchive)+' até '+cd(dateEndArchive)),
                                    date_start: get_date('date_sql', cd(dateStartArchive)),
                                    date_end: get_date('date_sql', cd(dateEndArchive)),
                                    closed: (props?.parent?.job_arquivado ? 0 : 1)
                                }}
                                callback={handleCallbackArchive}                                
                                status={handleSetButtonState}
                                toast={'Card '+(props?.parent?.job_arquivado ? 'desarquivado' : 'arquivado')+' de '+cd(dateStartArchive)+' até '+cd(dateEndArchive)}
                            >
                                <Input
                                    type="date"
                                    label="De"
                                    name="data_inicio_arquivado"
                                    value={dateStartArchive}
                                    onChange={(e) => setDateStartArchive(e)}
                                />

                                <Input
                                    type="date"
                                    label="Até"
                                    name="data_fim_arquivado"
                                    value={dateEndArchive}
                                    valueStart={(dateStartArchive ? dateStartArchive : '')}
                                    valueEnd={(props?.parent?.data_fim_formatada ? new Date(get_date('date_sql', props?.parent?.data_fim_formatada)) : '')}
                                    onChange={(e) => setDateEndArchive(e)}
                                />

                                <Button
                                    type="submit"
                                    status={buttonStateArchive}
                                    disabled={(dateEndArchive ? false : true)}
                                    title={(dateEndArchive ? '' : 'Selecione uma data antes de continuar')}
                                >
                                    {(props?.parent?.job_arquivado ? 'Desarquivar' : 'Arquivar')}
                                </Button>
                            </Form>
                        </ModalBody>
                    </Modal>

                    {(props.header !== false ?
                        <div className={style.card__header + ' ' + border_aux + ' ' + bg_aux + ' ' + (props?.alert ? style.bg__alert : '')}>
                            <div
                                className={style.card__info}
                            // onClick={() => (getMessage(props.id_aux), handleSetCollapse())}
                            >
                                <div className={(props.size === 'smallest' ? 'd-block' : 'd-flex') + ' align-items-start justify-content-between'}>
                                    <Tippy content={(props.tippy ? props.tippy : props.title)} disabled={(props?.size == 'smallest' ? true : false)}>
                                        <h3 className={style.title + ' ' + (props?.avulso ? style.avulso : '')} style={(props?.wrap ? {whiteSpace:'initial',overflow:'visible'} : {})}>
                                            <span className={(props.size === 'smallest' && props.subtitle && props?.bold ? 'font-weight-bold' : '')}>
                                                {title}
                                            </span>
                                            {(props.size === 'smallest' ? <br /> : '')}
                                            {(props.size === 'smallest' ? props.subtitle : '')}
                                        </h3>
                                    </Tippy>

                                                    {(props.obs4 || props.circle || props?.attention ?
                                                        <span className={style.small}>
                                                            <div className="d-flex align-items-center">
                                                                {(props.obs4 ?
                                                                    <span style={{opacity:'.5'}}>
                                                                        {props.obs4}
                                                                    </span>
                                                                :'')}

                                                                {(props.circle ?
                                                                    <Tippy content={props.circle}>
                                                                        <span className={style.info_circle}>
                                                                            {props.circle.slice(0,3).toUpperCase()}
                                                                        </span>
                                                                    </Tippy>
                                                                :'')}

                                                                {(props?.attention ?
                                                                    <Icon type="exclamation" className="text-warning" title={props.attention} animated />
                                                                :'')}
                                                            </div>
                                                        </span>
                                                    :'')}
                                                </div>

                                                {(() => {
                                                    if(!props.size||(props.size!=='small'&&props.size!=='smallest')){
                                                        return(
                                                            <div className={'d-flex align-items-start justify-content-between ' + style.subtitle_container}>
                                                                <div>
                                                                    <Tippy content={props.subtitle}>
                                                                        <h4 className={ style.subtitle }>
                                                                            { props.subtitle }
                                                                        </h4>
                                                                    </Tippy>
                                                                </div>

                                                                {(props?.obs5 ?
                                                                    <div>
                                                                        <h4 className={ style.subtitle }>
                                                                            {props?.obs5}
                                                                        </h4>
                                                                    </div>
                                                                :'')}
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>

                                            {(props?.size !== 'smallest' ?
                                                <div className={ style.card__actions }>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className={ style.left } onClick={() => (getMessage(props.id_aux), handleSetCollapse())}>
                                                            {(props.size!=='smallest' && !props?.inner && props.obs1 ?
                                                                <Tippy content={props.obs1}>
                                                                    <span className={ style.obs1 }>
                                                                        { props.obs1 }
                                                                    </span>
                                                                </Tippy>
                                                            :'')}

                                                            {(props.obs2?
                                                                <Tippy content={props.obs2}>
                                                                    <span className={ style.obs2 } style={(props?.condition ? {maxWidth:'100%'} : {})}>
                                                                        { props.obs2 }
                                                                    </span>
                                                                </Tippy>
                                                            :'')}

                                                            {(props.obs3?
                                                                <Tippy content={props.obs3}>
                                                                    <span className={ style.obs3 } style={(props?.condition ? {maxWidth:'100%'} : {})}>
                                                                        { props.obs3 }
                                                                    </span>    
                                                                </Tippy>
                                                            :'')}
                                                        </div>
                                                        <div className={ style.right }>   
                                                            {/* VISUALIZAÇÃO NORMAL */}
                                                            {(!maximized && props.view !== false && !cardExternal.enabled ?
                                                                <Icon
                                                                    type="view"
                                                                    title={(collapse?'Mostrar menos':'Mostrar mais')}
                                                                    animated
                                                                    active={collapse}
                                                                    onClick={() => (getMessage(props.id_aux), handleSetCollapse(), (!hover?((props.iframe?setLoading(true):setLoading(false))):{}))}                              
                                                                />
                                                            :'')}

                                            {/* VISUALIZAÇÃO APÓS MAXIMIZAR */}
                                            {(maximized && cardExternal.enabled ?
                                                <Icon
                                                    type="view"
                                                    title={(collapse ? 'Mostrar menos' : 'Mostrar mais')}
                                                    animated
                                                    active={collapse}
                                                    onClick={() => handleScrollToElement(props?.parent?.id_job_status)}
                                                />
                                            : '')}

                                            {/* <Icon
                                                                type={(showMessages ? 'message' : 'message')}
                                                                animated
                                                                active={showMessages}
                                                                onClick={() => handleShowMessages()}
                                                            /> */}

                                            {(props.expand?.enabled && !window.isMobile && props?.parent?.card_qtd_total > 0 ?
                                                <Icon
                                                    type={maximized ? 'internal' : 'external'}
                                                    title={maximized ? 'Minimizar' : 'Maximizar'}
                                                    onClick={() => handleSetExternal(props?.id)}
                                                />
                                                : '')}

                                            {(() => {
                                                if (actions) {
                                                    return actions;
                                                }
                                            })()}

                                                            {(props?.visualized?.show ?
                                                                <Icon 
                                                                    type="double-check"
                                                                    className={(props?.visualized.confirm ? 'text-primary' : 'text-secondary')}
                                                                    title={(props?.visualized.confirm ? 'Visualizado ('+cdh(props?.visualized.date)+')' : 'Não visualizado')}
                                                                    readonly={true}
                                                                />
                                                            :'')}                                          
                                                        </div>
                                                    </div>
                                                </div>       
                                            :'')}                         
                                        </div>
                                    :'')}

                                    {(props?.size !== 'smallest' && collapse ?
                                        <div className={ style.card__body }>
                                            <div onClick={() => (setCollapse(true), (!hover?setLoading(false):{}))}>
                                                {(anexos.length > 0 ?
                                                    <>
                                                        <p className="mb-1">Anexos:</p>
                                                        <div className={style.files}>
                                                            {anexos.map((item, i) => {
                                                                if(typeof item === 'string'){ // MODELO ANTIGO DE UPLOAD (SOMENTE ID)
                                                                    return (
                                                                        <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link} key={'file_' + i}>
                                                                            Arquivo {anexos.length - i}
                                                                            <span className="text-secondary">{(props.files ? (props.files?.split(',').includes(item) ? ' (Job)' : ' (Chat)') : ' (Chat)')}</span>
                                                                            <Icon type="external" />
                                                                        </a>
                                                                    )
                                                                }else{ // MODELO NOVO DE UPLOAD (TODAS AS INFORMAÇÕES DO ARQUIVO)
                                                                    return (
                                                                        <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item.id} target="_blank" className={style.file__link} key={'file_' + i}>
                                                                            {item.name}
                                                                            <span className="text-secondary">{(props.files ? (props.files.includes(item.id) ? ' (Job)' : ' (Chat)') : ' (Chat)')}</span>
                                                                            <Icon type="external" />
                                                                        </a>
                                                                    )
                                                                }
                                                            }).reverse()}
                                                        </div>
                                                    </>
                                                : '')}

                                                {(props.description ?
                                                    <div className={style.description} dangerouslySetInnerHTML={{__html: props.description}} />
                                                :'')}

                                                {/* LOADING DA BUSCA DE INFORMAÇÕES DA LOJA */}
                                                {(infoLojaLoading ? 
                                                    <Loader className="mb-3" />
                                                :'')}

                                    {/* INFORMAÇÕES DO SOLICITANTE */}
                                    {(infoUsuario?.telefone || infoUsuario?.email || infoLoja?.logradouro ? 
                                        <div className="mt-3">
                                            <p className="mb-2">
                                                <span className="font-weight-bold">Informações do solicitante</span>
                                            </p>

                                            <table className={style.more_info}>
                                                <tbody>
                                                    {(infoUsuario?.telefone ?
                                                        <tr>
                                                            <td>
                                                                <p className={style.no_border}>Telefone</p>
                                                                <p><a href={'tel:'+infoUsuario?.telefone}>{infoUsuario?.telefone}</a></p>
                                                            </td>
                                                        </tr>
                                                    :<></>)}

                                                    {(infoUsuario?.email ?
                                                        <tr>
                                                            <td>
                                                                <p className={style.no_border}>E-mail</p>
                                                                <p><a href={'mailto:'+infoUsuario?.email}>{infoUsuario?.email}</a></p>
                                                            </td>
                                                        </tr>
                                                    :<></>)}

                                                    {(infoLoja?.logradouro ?
                                                        <tr>
                                                            <td>
                                                                <p className={style.no_border}>Endereço da loja</p>
                                                                <p>
                                                                    {infoLoja?.logradouro + (infoLoja?.numero ? ', '+infoLoja?.numero : '') + (infoLoja?.bairro ? ', '+infoLoja?.bairro : '') + (infoLoja?.cidade ? ', '+infoLoja?.cidade : '') + (infoLoja?.cep ? ' - CEP: '+infoLoja?.cep : '') + (infoLoja?.estado ? ' - '+infoLoja?.estado : '')}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    :<></>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    :'')}

                                    {/* INSERE UM "SEPARADOR" CASO TENHA MICROSSISTEMA E INFORMAÇÕES DA LOJA NO MESMO CARD */}
                                    {(microssistemaValues.length > 0 && infoLoja ?
                                        <p className="mb-2">
                                            <span className="font-weight-bold">Outras informações</span>
                                        </p>
                                    :'')}

                                                {(microssistemaValues.length > 0 ?
                                                    <table className={style.more_info}>
                                                        {microssistemaValues.map((item, i) => {
                                                            let valor_aux;

                                                            if(item?.componente_id == global?.componentes?.select_personalizado){ // SE FOR SELECT PERSONALIZADO
                                                                if(microssistemaCustomOptions.length > 0){
                                                                    if(microssistemaCustomOptions.filter((elem) => elem.id == item.valor).length > 0){
                                                                        valor_aux = microssistemaCustomOptions.filter((elem) => elem.id == item.valor)[0].label;
                                                                    }
                                                                }else{
                                                                    valor_aux = item?.valor;
                                                                }
                                                            }else if(item?.componente_id == global?.componentes?.checkbox_personalizado){ // SE FOR CHECKBOX PERSONALIZADO
                                                                if(microssistemaCustomOptions.length > 0){
                                                                    valor_aux = '';
                                                                    item?.valor.split(',').map((item, i) => {
                                                                        if(microssistemaCustomOptions.filter((elem) => elem.id == item).length > 0){
                                                                            valor_aux += microssistemaCustomOptions.filter((elem) => elem.id == item)[0].label + ', ';
                                                                        }
                                                                    });

                                                                    valor_aux = valor_aux.slice(0,-2);
                                                                }
                                                            }else if(item?.componente_id == global?.componentes?.select_loja){ // SE FOR SELECT DE LOJAS
                                                                if(microssistemaOptionsLoja.length > 0){
                                                                    if(microssistemaOptionsLoja.filter((elem) => elem.id == item.valor).length > 0){
                                                                        valor_aux = microssistemaOptionsLoja.filter((elem) => elem.id == item.valor)[0].label;
                                                                    }else{
                                                                        valor_aux = item?.valor;
                                                                    }
                                                                }else{
                                                                    valor_aux = item?.valor;
                                                                }
                                                            }else if(item?.componente_id == global?.componentes?.data){ // SE FOR SELECT DE LOJAS
                                                                valor_aux = cd(item.valor);
                                                            }else{
                                                                valor_aux = item?.valor;
                                                            }

                                                            return(
                                                                <tr key={'microssistema_item_'+item?.id}>
                                                                    <td>
                                                                        <p className={style.no_border}>{item?.nome}</p>
                                                                        <p>{valor_aux}</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                :'')}

                                                {(() => {
                                                    let placeholder_aux = undefined;
                                                    let nivel_aux = undefined;

                                                    if(configuracoes[0]){ // VERIFICA SE VEIO CONFIGURACOES DA API
                                                        if(configuracoes[0].conf_chat){ // VERIFICA SE POSSUI CONFIGURAÇÕES PARA O CHAT
                                                            if(props?.parents?.tipo_fase){ // VERIFICA SE O JOB TEM TIPO_FASE
                                                                if(JSON.parse(configuracoes[0]?.conf_chat).tipo_fase[props?.parents?.tipo_fase]){ // VERIFICA SE POSSUI CONFIGURAÇÃO PARA O TIPO DA FASE
                                                                    placeholder_aux = JSON.parse(configuracoes[0]?.conf_chat).tipo_fase[props?.parents?.tipo_fase].label; // SETA A LABEL
                                                                    nivel_aux = JSON.parse(configuracoes[0]?.conf_chat).tipo_fase[props?.parents?.tipo_fase].nivel_msg; // SETA O NÍVEL DAS MSGS
                                                                }
                                                            }
                                                        }
                                                    }

                                                    return(
                                                        <>                    
                                                            {(props.chat !== false ?
                                                                <Chat
                                                                    autoSubmit={props.autoSubmit}
                                                                    setAutoSubmit={props.setAutoSubmit}
                                                                    api={props.chat?.api}
                                                                    data={props.chat?.data}
                                                                    id={props.chat?.id}
                                                                    editor={true}
                                                                    send={(props.chat?.send === false ? false : true)}
                                                                    empty={(messages.length > 0 || props.chat?.defaultMessage ? false : true)}
                                                                    defaultMessage={(showMessages ? props.chat?.defaultMessage : '')}
                                                                    anexo={(props?.chat?.anexo === false ? false : {multiple: true})}
                                                                    callback={reloadChat}
                                                                    placeholder={placeholder_aux}
                                                                    nivel_msg={nivel_aux}
                                                                >
                                                                    {(showMessages ?
                                                                        (searchMessages===true?
                                                                            (props.iframe?'':<Loader show={true} className="mb-3" />)
                                                                        :
                                                                            ((collapse||hover||loaded)&&messages?
                                                                                messages?.map((message, i) => { 
                                                                                    if(message.anexos){
                                                                                        message?.anexos.split(',').map((item, i) => {
                                                                                            messageFiles.push(item);                                                                          
                                                                                        });
                                                                                    }

                                                                                    return(
                                                                                        <Message
                                                                                            key={'message_'+message.id}
                                                                                            sender={message.name_usr}
                                                                                            date={cdh(message.cad)}
                                                                                            text={
                                                                                                message.mensagem !== null ?
                                                                                                message.mensagem + (message.motivo ? '\nMotivo: ' + message.motivo + '' : '')
                                                                                                : ''
                                                                                            }
                                                                                            files={
                                                                                                (message.anexos ?
                                                                                                    (message?.anexos.includes('{') ? 
                                                                                                        JSON.parse(message?.anexos).map((item, i) => {
                                                                                                            return (
                                                                                                                <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item.id} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                                                                                    {item.name}
                                                                                                                    <Icon type="external" />
                                                                                                                </a>
                                                                                                            )
                                                                                                        })
                                                                                                    :
                                                                                                        message?.anexos.split(',').map((item, i) => {
                                                                                                            return (
                                                                                                                <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                                                                                    Arquivo {i + 1}
                                                                                                                    <Icon type="external" />
                                                                                                                </a>
                                                                                                            )
                                                                                                        })
                                                                                                    )
                                                                                                : '')
                                                                                            }
                                                                                            align={(window.rs_id_usr == message.id_usr?'right':'left')}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            :'')
                                                                        )
                                                                    :
                                                                        <div className={style.alert__messages} onClick={() => (setShowMessages(true), scrollChat())}>
                                                                            {messages.length + 1} Mensage{(messages.length > 0 ? 'ns':'m')} enviada{(messages.length > 0 ? 's':'')}
                                                                        </div>
                                                                    )}
                                                                </Chat>
                                                            :'')}
                                                        </>
                                                    )
                                                })()}

                                                {(loading ? 
                                                    <div className="mt-3">
                                                        <Loader show={loading} />
                                                    </div>
                                                :'')}

                                                {(props.iframe&&(collapse||hover/*||loaded*/)?
                                                    <div className="position-relative mt-3">
                                                        <iframe
                                                            name={props.iframeName}
                                                            src={props.iframe}
                                                            className="d-block w-100"
                                                            width={'100%'}
                                                            height={"100%"}
                                                            // onClick={() => (setCollapse(collapse?false:true), (!hover?((props.iframe?setLoading(true):setLoading(false))):{}))}
                                                            onLoad={(event) => {
                                                                setLoading(false);
                                                            }}
                                                        ></iframe>
                                                    </div>
                                                    :
                                                    <></>
                                                )}

                                                {(props.integration && collapse?
                                                    props.integration
                                                :
                                                    <></>
                                                )}

                                                {(!props.iframe?props.children:'')}  

                                                {cards_internos_aux}
                                            </div>
                                        </div>
                                    :'')}
                                </>
                            :'')}
                    </div>;

        return(
            <>            
                {(showCard ? card_aux : '')}

                {/* CARD MAXIMIZADO */}
                {(maximized ?
                    <div className={style.card__maximized}>
                        <div className={style.card__maximized_container} id="card_maximized_container">

                            {(props.integration ?
                                <div className={style.card__maximized_box + ' ' + style.width__auto}>
                                    <div className={style.card__maximized_header}>
                                        Integração
                                    </div>
                                    <div className={style.card__maximized_body}>
                                        <div style={{width:382, maxWidth:'100%'}}>
                                            {props.integration}
                                        </div>
                                    </div>
                                </div>
                            :'')}

                            <div className={style.card__maximized_box + ' ' + style.width__auto}>
                                <div className={style.card__maximized_header}>
                                    Job Principal
                                </div>
                                <div className={style.card__maximized_body}>
                                    {card_aux}

                                    <h3 className={style.card__maximized_body_subtitle}>Jobs secundários</h3>

                                    {cards_internos_aux}
                                </div>
                            </div>

                            {(!hide.includes(props?.parent?.id_job_status) ?
                                <div
                                    id={'container_box_' + props?.parent?.id_job_status}
                                    className="container_box"
                                >
                                    <Mensagens
                                        messages={{
                                            messages: messages,
                                            show: showMessages
                                        }}
                                        props={props}
                                        callback={handleCallbackMensagens}
                                        reload={reloadChat}
                                    />
                                </div>
                            :'')}

                            {(cardsInternal.length > 0 ?
                                cardsInternal.filter((elem) => !elem.data_adiado).filter((elem) => {
                                    if(hide.includes(elem.id_job_status)){
                                        return false;
                                    }else{
                                        return true;
                                    }
                                }).map((interno, i) => {
                                    return (
                                        <div
                                            id={'container_box_' + interno?.id_job_status}
                                            key={'interno_' + interno?.id_job_status}
                                            className="container_box"
                                        >
                                            <Mensagens
                                                messages={{
                                                    messages: messages,
                                                    show: showMessages
                                                }}
                                                props={props}
                                                job={interno}
                                                reload={reloadChat}
                                                callback={handleCallbackMensagens}
                                                changeOperator={handleChangeOperator}
                                                minimized={handleMinimize}    
                                                refreshCard={handleRefreshCard}
                                            />
                                        </div>
                                    )
                                })
                                : '')}
                        </div>
                    </div>
                :'')}
            </>     
        )
    }  
}

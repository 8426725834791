import { useSortable } from "@dnd-kit/sortable";
import Input from "../../../../../../components/body/form/input";
import InputContainer from "../../../../../../components/body/form/inputcontainer";
import CheckboxGroup from "../../../../../../components/body/form/checkboxGroup";
import Switch from "../../../../../../components/body/switch";
import { CSS } from "@dnd-kit/utilities";
import Icon from "../../../../../../components/body/icon";
import style from "./style.module.scss";

export default function Item(banner) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        handle
    } = useSortable({ id: banner.banner.id });

    const style_aux = {
        transform: CSS.Translate.toString(transform),
        transition,
        display: window.isMobile ? 'block' : 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        background: '#f0f3f5',
        padding: '20px',
        // maginBottom: '20px',
        gap: '15px',
        with: "100%",
    };

    return (
        // <div></div>
        <div className={style.banner} id={banner.banner.id} style={{ position: 'relative', zIndex: 900 - banner.index }} >
            <span
                {...attributes}
                {...listeners}
                {...handle}
            >
                <Icon type="draggable" title={false} />
            </span>
            {/* DIV 1 - SÓ IMG */}
            <div className={style.col1} >
                <div
                // style={window.isMobile ? { width: "100%", height: "50%", marginBottom: '5px' } : { width: "200px", height: "10vh", flex: '0 0 100px' }}
                >
                    {(banner.banner?.anexo?.length > 0) ?
                        <div style={{ position: 'relative' }} >
                            <div className={style.input_img} >
                                <Input
                                    className={style.input_teste}
                                    key={'atualizacao_foto' + banner.banner.id}
                                    id={banner.banner.id}
                                    type={'file'}
                                    accept={'.png, .jpeg, .jpg'}
                                    multiple={false}
                                    value={banner.banner?.anexo}
                                    required={false}
                                    icon={banner.banner?.anexo ? false : ''}
                                    callback={(e) => banner.handleChange({ name: 'anexo', value: e[0] }, banner.index)}
                                />
                            </div>
                            <img
                                src={'https://upload.malltech.com.br/' + (Array.isArray(banner.banner?.imagens) && banner.banner?.imagens?.length > 0 ? banner.banner?.imagens[0] : banner.banner?.anexo)}
                                width="100%"
                                height="95%"
                                alt=""
                            />
                        </div>
                        :
                        <span style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                            Sem imagem
                            <Input
                                className={style.input_teste}
                                key={'atualizacao_foto' + banner.banner.id}
                                id={banner.banner.id}
                                type={'file'}
                                accept={'.png, .jpeg, .jpg'}
                                multiple={false}
                                value={banner.banner?.anexo}
                                required={false}
                                icon={banner.banner?.anexo ? false : ''}
                                callback={(e) => banner.handleChange({ name: 'anexo', value: e[0] }, banner.index)}
                            />
                        </span>}
                </div>
            </div>

            {/* DIV 2 - NOME, LOJAS MARCAS */}
            <div className={style.col2} >
                <span
                // style={{ width: '100px', flex: '0 0 100px' }}
                >
                    <Input
                        id="nome"
                        name="nome"
                        placeholder="Nome"
                        type="text"
                        value={banner.banner?.nome}
                        onChange={(e) => banner.handleChange({ name: 'nome', value: e.target.value }, banner.index)}
                    />
                </span>
                <div className={style.div_banner} >
                    <span className={style.lojas}
                    //style={{ zIndex: 900 - banner.index }} 
                    >
                        <CheckboxGroup
                            id={'loja_' + banner.banner.id}
                            key={'loja_' + banner.banner.id}
                            group="loja"
                            float={true}
                            required={false}
                            no_selec={true}
                            value={Array.isArray(banner.banner?.lojas) ? banner.banner?.lojas : (banner.banner?.lojas?.indexOf(',') != -1 ? banner.banner?.lojas?.split(',') : [banner.banner?.lojas])}
                            callback={(e) => banner.handleChange({ name: 'lojas', value: e?.toString() }, banner.index)}
                        />
                    </span>

                    <span className={style.marcas}
                    //style={{ zIndex: 900 - banner.index }} 
                    >
                        <CheckboxGroup
                            id={'marca_' + banner.banner.id}
                            key={'marca_' + banner.banner.id}
                            group="marca"
                            float={true}
                            required={false}
                            no_selec={true}
                            value={Array.isArray(banner.banner?.marcas) ? banner.banner?.marcas : (banner.banner?.marcas?.indexOf(',') != -1 ? banner.banner?.marcas?.split(',') : [banner.banner?.marcas])}
                            callback={(e) => banner.handleChange({ name: 'marcas', value: e?.toString() }, banner.index)}
                        />
                    </span>
                </div>
            </div>

            {/* DIV 3 - INPUT DATAS !, CARGOS , DEPARTAMENTOS SWITCH */}
            <div className={style.col3} >
                <div className={style.div_banner} >
                    <div className={style.date}
                    //style={{ zIndex: 901 - banner.index }}
                    >
                        <span>
                            <Input
                                type="period"
                                valueStart={banner.banner?.data_inicial?.length ? new Date(banner.banner?.data_inicial + ' EDT') : ''}
                                valueEnd={banner.banner?.data_final?.length ? new Date(banner.banner?.data_final + ' EDT') : ''}
                                onChangeStart={(e) => banner.handleChange({ name: 'data_inicial', value: (e.toString()) }, banner.index)}
                                onChangeEnd={(e) => banner.handleChange({ name: 'data_final', value: (e.toString()) }, banner.index)}
                            />
                        </span>
                    </div>
                    <span className={style.switch} >
                        <InputContainer>
                            <Switch
                                id={banner.banner.id}
                                required={false}
                                name="ativo"
                                checked={banner.banner?.ativo}
                                onChange={(e) => banner.handleChange({ name: 'ativo', value: !banner.banner?.ativo }, banner.index)}
                            />
                        </InputContainer>
                    </span>
                </div>
                <div className={style.div_banner} >
                    <span
                        className={style.cargos}
                    //style={{ zIndex: 900 - banner.index }}
                    >
                        <CheckboxGroup
                            id={'cargo_' + banner.banner.id}
                            key={'cargo_' + banner.banner.id}
                            group="cargo"
                            float={true}
                            required={false}
                            no_selec={true}
                            value={Array.isArray(banner.banner?.cargos) ? banner.banner?.cargos : (banner.banner?.cargos?.indexOf(',') != -1 ? banner.banner?.cargos?.split(',') : [banner.banner?.cargos])}
                            callback={(e) => banner.handleChange({ name: 'cargos', value: e?.toString() }, banner.index)}
                        />
                    </span>
                    <span className={style.departamentos}
                    //style={{ zIndex: 900 - banner.index }}
                    >
                        <CheckboxGroup
                            id={'departamento_' + banner.banner.id}
                            key={'departamento_' + banner.banner.id}
                            group="departamento"
                            float={true}
                            required={false}
                            no_selec={true}
                            value={Array.isArray(banner.banner?.departamentos) ? banner.banner?.departamentos : (banner.banner?.departamentos?.indexOf(',') != -1 ? banner.banner?.departamentos?.split(',') : [banner.banner?.departamentos])}
                            callback={(e) => banner.handleChange({ name: 'departamentos', value: e?.toString() }, banner.index)}
                        />
                    </span>
                </div>
            </div>
            {/* <span style={{ width: '50px', flex: '0 0 50px' }}>
                <Input
                    id="posicao"
                    name="posicao"
                    type="tel"
                    mask={'999'}
                    maskChar={''}
                    width={'100%'}
                    value={banner.banner?.posicao}
                    onChange={(e) => banner.handleChange({ name: 'posicao', value: e.target.value }, banner.index)}
                />
            </span> */}
        </div>
    )
}
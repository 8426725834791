import { useState } from "react";
import Button from "../../../components/body/button";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import InputContainer from "../../../components/body/form/inputcontainer";
import TextareaEditor from "../../../components/body/form/textarea/textEditor";
import Icon from "../../../components/body/icon";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import SelectReact from "../../../components/body/select";
import InputFoto from "./inputFoto";
import { useEffect } from "react";
import axios from 'axios';
import CheckboxGroup from "../../../components/body/form/checkboxGroup";
import Textarea from "../../../components/body/form/textarea";
import { cd, get_date } from "../../../_assets/js/global";

export default function NewModal({ showNew, handleShowNew, id, handleCloseNew, onHide }) {

    //VARIAVEL FECHAR MODAL
    const handleCloseModalNew = () => {
        onHide(false);
    };

    // ESTADOS
    const [statusForm, setStatusForm] = useState('');

    //ESTADO QUE ARMAZENA INFORMAÇÕES 
    const [titulo, setTitulo] = useState('');
    const [hashtags, setHashtags] = useState('');
    const [departamentos, setDepartamentos] = useState('');
    const [cargos, setCargos] = useState('');
    const [marcas, setMarcas] = useState('');
    const [lojas, setLojas] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [dataInicio, setDataInicio] = useState(new Date());//
    const [dataFinal, setDataFinal] = useState('');//
    const [texto, setTexto] = useState('');
    const [documento, setDocumento] = useState('');
    const [docDownload, setDocDownload] = useState('');
    const [video, setVideo] = useState('');
    const [foto, setFoto] = useState('');

    //MOSTRAR AS INFORMAÇÕES DA API NOS INPUTS, AO CLICAR NO ÍCONE DE EDITAR O POST
    function getEditNews(id) {
        axios({
            url: window.host + "/homes/api/home3.php?do=consultaNoticia&id=" + id,
            method: "post",
            data: { id: id },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setTitulo(response.data[0].nome);
            setHashtags(response.data[0].hashtags);            
            setComentarios(response.data[0].permissao_comentario);
            setDataInicio(response.data[0].data_publicacao ? new Date(get_date('datetime_sql', response.data[0].data_publicacao, 'date')) : '');
            // setDataFinal(new Date(response.data[0].data_saida)) -- Precisa ter horário
            setTexto(response.data[0].descricao);
            setDocumento(response.data[0].documento);
            setDocDownload(response.data[0].documento_download);
            setVideo(response.data[0].video);
            setFoto(response.data[0].img);

            // DEPARTAMENTOS SELECIONADOS
            if(response.data[0].departamento_id){
                let departamento_aux = [];
                response.data[0].departamento_id.split(',').map((item, i) => {
                    departamento_aux.push(item);
                });
                setDepartamentos(departamento_aux);
            }else{
                setDepartamentos([]);
            }
            
            // CARGOS SELECIONADOS
            if(response.data[0].cargo_id){
                let cargo_aux = [];
                response.data[0].cargo_id.split(',').map((item, i) => {
                    cargo_aux.push(item);
                });
                setCargos(cargo_aux);
            }else{
                setCargos([]);
            }

            // MARCAS SELECIONADOS
            if(response.data[0].marca_id){
                let marca_aux = [];
                response.data[0].marca_id.split(',').map((item, i) => {
                    marca_aux.push(item);
                });
                setMarcas(marca_aux);
            }else{
                setMarcas([]);
            }

            // LOJAS SELECIONADOS
            if(response.data[0].loja_id){
                let loja_aux = [];
                response.data[0].loja_id.split(',').map((item, i) => {
                    loja_aux.push(item);
                });
                setLojas(loja_aux);
            }else{
                setLojas([]);
            }
        }).catch((_error) => { })
    };

    // FUNÇÃO QUE ADICIONA OU MUDA AS INFORMAÇÕES DA API 
    const data = {
        id: id ? id : undefined,
        titulo: titulo,
        hashtags: hashtags,
        checkbox_departamento: departamentos,
        cargos: cargos,
        marcas: marcas,
        lojas: lojas,
        permissao_comentario: comentarios,
        data_publicacao: (dataInicio ? cd(dataInicio) + ' 00:00' : ''),
        data_saida: (dataFinal ? cd(dataFinal) + ' 00:00' : ''),
        descricao: texto,
        documento: documento,
        documento_download: docDownload,
        video: video,
        img: foto,
    };

    useEffect(() => {
        getEditNews(id);
    }, [id]);

    //OPÇÕES COMENTÁRIOS
    const options_comentarios = [
        { value: 1, label: "Sim" },
        { value: '0', label: "Não" }
    ];

    // CHECKBOX DEPARTAMENTOS
    function handleSetDepartamentos(e) {
        setDepartamentos(e);
    };

    // CHECKBOX CARGOS
    function handleSetCargos(e) {
        setCargos(e);
    };

    // CHECKBOX MARCAS
    function handleSetMarcas(e) {
        setMarcas(e);
    };

    // CHECKBOX LOJAS
    function handleSetLojas(e) {
        setLojas(e);
    };    

    // SETAR INPUT DOCUMENTO
    const handleSetDocumento = (response) => {
        setDocumento(response[0]);
    };

    // SETAR INPUT DOCUMENTO DOWNLOAD
    const handleSetDocDownload = (response) => {
        setDocDownload(response[0]);
    };

    //FUNÇÃO QUE RESETA OS INPUTS AO CONFIRMAR O ENVIO
    function resetForm() {
        setTitulo('');
        setHashtags('');
        setDepartamentos([]);
        setCargos([]);
        setMarcas([]);
        setLojas([]);
        setComentarios('');
        setDataInicio(''); // QUANDO ACIONADO INTERFERE NO QUE VEM DA API
        setDataFinal(''); // QUANDO ACIONADO INTERFERE NO QUE VEM DA API
        setTexto('');
        setDocumento('');
        setDocDownload('');
        setVideo('');
        setFoto('');

        handleCloseModalNew();
    };

    // SETAR INPUT FOTO
    const handleSetFoto = (response) => {
        setFoto(response[0]);
    };

    // STATUS FORM
    const handleStatusForm = (e) => {
        setStatusForm(e);
    }

    return (
        <>
            <Modal show={showNew} onClick={handleShowNew} onHide={handleCloseModalNew}>
                <ModalHeader>
                    <ModalTitle>
                        {(id > 0 ? "Editar" : "Novo")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host + "/homes/api/home3.php?do=" + (id ? "updateNoticia" : "insertNoticia")}
                        data={data}
                        status={handleStatusForm}
                        callback={resetForm}
                    >
                        <Input
                            name="titulo"
                            type="text"
                            label="Título"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />

                        <Input
                            name="hashtagas"
                            type="text"
                            label="Hashtags"
                            value={hashtags}
                            onChange={(e) => setHashtags(e.target.value)}
                            required={false}
                        />

                        <CheckboxGroup 
                            group="departamento"
                            id="departamento"
                            name="departamento"
                            required={false}
                            value={departamentos}
                            callback={handleSetDepartamentos}
                        />
                        
                        <CheckboxGroup 
                            group="cargo"
                            id="cargo"
                            name="cargo"
                            required={false}
                            value={cargos}
                            callback={handleSetCargos}
                        />
                        
                        <CheckboxGroup 
                            group="marca"
                            id="marca"
                            name="marca"
                            required={false}
                            value={marcas}
                            callback={handleSetMarcas}
                        />
                        
                        <CheckboxGroup 
                            group="loja"
                            id="loja"
                            name="loja"
                            required={false}
                            value={lojas}
                            callback={handleSetLojas}
                        />

                        <SelectReact
                            name="comentario"
                            label="Permitir Comentários"
                            value={comentarios}
                            onChange={(e) => setComentarios(e.value)}
                            options={options_comentarios}
                        />
                        <Input
                            name="data publicacao"
                            type="date"
                            label="Data Publicação"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e)}
                            required={true}
                        />
                        <Input
                            name="data expiracao"
                            type="date"
                            label="Data de expiração"
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e)}
                            required={false}
                        />
                        <Textarea
                            name="texto"
                            type="text"
                            label="Digite seu texto..."
                            editor={true}
                            value={texto}
                            required={false}
                            onChange={(e) => setTexto(e.target.value)}
                        />
                        <Input
                            name="documento"
                            type="file"
                            label="Documento"
                            value={documento}
                            multiple={true}
                            callback={handleSetDocumento}
                            required={false}
                        />
                        <Input
                            name="documento para donwload"
                            type="file"
                            label="Documento para download"
                            value={docDownload}
                            multiple={true}
                            callback={handleSetDocDownload}
                            required={false}
                        />

                        <Input
                            type="text"
                            label="Vídeo (URL)"
                            name="url_video"
                            value={video}
                            onChange={(e) => setVideo(e.target.value)}
                            required={false}
                        />

                        {(() => {
                            if(video){  
                            let link;
                            if(video.includes('shorts')){
                                link = video.split('shorts/')[1];
                            }else if(video.includes('.be/')){
                                link = video.split('.be/')[1];
                            }else{
                                link = video.split('?v=')[1];                      
                            }

                            if((video.includes('youtube') || video.includes('youtu.be')) && link){
                                return(
                                <iframe
                                    className="d-block w-100 mb-3"
                                    style={{height:250, borderRadius: 4, marginTop: 10}}
                                    src={'https://www.youtube.com/embed/'+link.split('&t=')[0]}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                                )
                            }
                            }
                        })()}

                        <Input
                            name="foto"
                            type="file"
                            label="Foto"
                            value={foto}
                            callback={handleSetFoto}
                            required={false}
                            icon="camera"
                            accept={'.png, .jpeg, .jpg'}
                        />

                        <Button type="submit" status={statusForm}>Salvar</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
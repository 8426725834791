import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../context/Global";
import { get_date } from '../../../_assets/js/global.js';
import axios from "axios";

import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Dashboard from "../../../components/body/dashboard";
import Tr from '../../../components/body/table/tr';
import Th from '../../../components/body/table/thead/th';
import Td from '../../../components/body/table/tbody/td';
import CalendarTitle from "../../../components/body/calendarTitle";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import SelectReact from "../../../components/body/select";
import PageError from '../../../components/body/pageError';
import Icon from "../../../components/body/icon";

export default function DashboardPage(props) {
    // CONTEXT
    const { loadingCalendar, handleSetFirstLoad, handleSetFixFilterModule, handleSetFilter, handleSetFilterModule, filterModule } = useContext(GlobalContext); 

    // ESTADOS
    const [monthSelected, setMonthSelected] = useState(window.currentMonth);
    const [yearSelected, setYearSelected] = useState(window.currentYear);
    const [dashContrato1, setDashContrato1] = useState([]);
    const [dashContrato2, setDashContrato2] = useState([]);
    const [dashContrato3, setDashContrato3] = useState([]);
    const [dashProduto1, setDashProduto1] = useState([]);
    const [dashProduto2, setDashProduto2] = useState([]);
    const [dashProduto3, setDashProduto3] = useState([]);
    const [dashLoja1, setDashLoja1] = useState([]);
    const [dashLoja2, setDashLoja2] = useState([]);
    const [dashLoja3, setDashLoja3] = useState([]);
    const [supervisorActive, setSupervisorActive] = useState('');
    const [lojaActive, setLojaActive] = useState('');
    const [categoriaActive, setCategoriaActive] = useState('');
    const [subcategoriaActive, setSubcategoriaActive] = useState('');
    const [tituloActive, setTituloActive] = useState('');
    const [optionsModule, setOptionsModule] = useState([]);

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
        loadingCalendar(true);
        handleSetFixFilterModule(false);
        handleSetFirstLoad(true);
    },[]);

    // OPTIONS MESES
    const optionsMonths = [
        { value: 1, label: 'Janeiro'},
        { value: 2, label: 'Fevereiro'},
        { value: 3, label: 'Março'},
        { value: 4, label: 'Abril'},
        { value: 5, label: 'Maio'},
        { value: 6, label: 'Junho'},
        { value: 7, label: 'Julho'},
        { value: 8, label: 'Agosto'},
        { value: 9, label: 'Setembro'},
        { value: 10, label: 'Outubro'},
        { value: 11, label: 'Novembro'},
        { value: 12, label: 'Dezembro'}
    ]

    // OPTIONS ANO
    var optionsYear = [];
    for(var i=0; i<5; i++){
        optionsYear.push(
            {value: window.currentYear-i, label: window.currentYear-i}
        )
    }

    // GET RESULTADOS DA PRIMEIRA COLUNA DE CONTRATOS
    const handleSetDashContrato1 = (e) => {
        if(e.length > 0){
            setDashContrato1(e);    
        }else{
            setDashContrato1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE CONTRATOS
    const handleSetDashContrato2 = (e) => {
        if(e.length > 0){
            setDashContrato2(e);    
        }else{
            setDashContrato2(
                <Tr empty={true}></Tr>
            )
        }     
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE CONTRATOS
    const handleSetDashContrato3 = (e) => {
        if(e.length > 0){
            setDashContrato3(e);    
        }else{
            setDashContrato3(
                <Tr empty={true}></Tr>
            )
        }       
    }
    
    // GET RESULTADOS DA PRIMEIRA COLUNA DE PRODUTOS
    const handleSetDashProduto1 = (e) => {
        if(e.length > 0){
            setDashProduto1(e);    
        }else{
            setDashProduto1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE PRODUTOS
    const handleSetDashProduto2 = (e) => {
        if(e.length > 0){
            setDashProduto2(e);    
        }else{
            setDashProduto2(
                <Tr empty={true}></Tr>
            )
        }     
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE PRODUTOS
    const handleSetDashProduto3 = (e) => {
        if(e.length > 0){
            setDashProduto3(e);    
        }else{
            setDashProduto3(
                <Tr empty={true}></Tr>
            )
        }       
    }

    // GET RESULTADOS DA PRIMEIRA COLUNA DE LOJAS
    const handleSetDashLoja1 = (e) => {
        if(e.length > 0){
            setDashLoja1(e);    
        }else{
            setDashLoja1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE LOJAS
    const handleSetDashLoja2 = (e) => {
        if(e.length > 0){
            setDashLoja2(e);    
        }else{
            setDashLoja2(
                <Tr empty={true}></Tr>
            )
        }     
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE LOJAS
    const handleSetDashLoja3 = (e) => {
        if(e.length > 0){
            setDashLoja3(e);    
        }else{
            setDashLoja3(
                <Tr empty={true}></Tr>
            )
        }       
    }

    return (            
        <>
            <Row
                wrap={(window.isMobile ? true : false)}
                className="mb-4"
                fixed={true}
            >
                <Col lg={12}>
                    <CalendarTitle>
                        <CalendarFilter margin={false} align="center">
                            {((props.chamados || props.fases) && window.rs_sistema_id != 238 ? // SE FOR CHAMADOS, E NÃO ESTIVER NO SISTEMA "CHAMADOS EMPRESA REACT" MOSTRA O FILTRO DE MÓDULO
                                <SelectReact
                                    options={optionsModule}
                                    placeholder="Nível"
                                    name="filter_module"
                                    value={filterModule}
                                    onChange={(e) => (
                                        handleSetFilterModule(e.value)
                                    )}
                                />
                            :'')}

                            <SelectReact
                                placeholder="Mês"
                                options={optionsMonths}
                                value={monthSelected}
                                onChange={(e) => setMonthSelected(e.value)}
                            />

                            <SelectReact
                                placeholder="Ano"
                                options={optionsYear}
                                value={yearSelected}
                                onChange={(e) => setYearSelected(e.value)}
                            />
                        </CalendarFilter>
                    </CalendarTitle>
                </Col>
            </Row>
        
            <Row wrap={(window.isMobile ? true : false)}>
                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Contrato</Th>
                                <Th>Indústr.</Th>
                                <Th align="center">Produto</Th>
                                <Th align="center">Loja</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Contrato - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_contrato',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashContrato1.length>0?
                                            dashContrato1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashContrato1               
                                },
                                col_2: {
                                    title: 'Supervisores - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',1)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_contrato',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashContrato1.length>0?
                                            dashContrato1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashContrato2                        
                                },
                                col_3: {
                                    title: 'Supervisores - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',2)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_contrato',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashContrato1.length>0?
                                            dashContrato1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashContrato3                       
                                }
                            }
                        }
                    >
                    </Dashboard>
                </Col>

                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Produto</Th>
                                <Th align="center">Contrato</Th>
                                <Th align="center">Loja</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Produto - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_produto',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashProduto1.length>0?
                                            dashProduto1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashProduto1         
                                },
                                col_2: {
                                    title: 'Produto - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_produto',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashProduto1.length>0?
                                            dashProduto1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashProduto2                    
                                },
                                col_3: {
                                    title: 'Produto - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_produto',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashProduto1.length>0?
                                            dashProduto1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashProduto3                   
                                }
                            }
                        }
                    >
                    </Dashboard>
                </Col>

                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Filial</Th>
                                <Th align="center">Contrato</Th>
                                <Th align="center">Produto</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Filial - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_loja',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashLoja1.length>0?
                                            dashLoja1.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.loja}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja1        
                                },
                                col_2: {
                                    title: 'Filial - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_loja',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashLoja2.length>0?
                                            dashLoja2.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja2   
                                },
                                col_3: {
                                    title: 'Filial - '+get_date('month_name',get_date('date','01/'+monthSelected+'/'+yearSelected,'date_sub_month',0)),
                                    api: {
                                        url: window.host+'/systems/trade/api/dashboard.php?do=get_loja',
                                        params: {
                                            page: 0,
                                            data_inicio: '01/04/2023',
                                            data_fim: '30/04/2023',
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody:(
                                        dashLoja3.length>0?
                                            dashLoja3.map((item) => {
                                                return(
                                                    <Tr
                                                        key={item.id}
                                                        active={(supervisorActive == item.id_supervisor_loja ? true : false)}
                                                        onClick={() => (
                                                            setSupervisorActive(supervisorActive == item.id_supervisor_loja ? '' : item.id_supervisor_loja),
                                                            setLojaActive(''),
                                                            setCategoriaActive(''),
                                                            setSubcategoriaActive(''),
                                                            setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja3 
                                },
                            }
                        }
                    >
                    </Dashboard>
                </Col>
            </Row>            
        </>
    )
}

import { useContext, useEffect, useState } from "react";

import Modal from "../../../../components/body/modal";
import ModalBody from "../../../../components/body/modal/modalBody";
import { JobsContext } from "../../../../context/Jobs";
import CardJobs from '../../Main/Card';
import axios from "axios";
import Icon from "../../../../components/body/icon";

export default function ModalListaJob({id, fases, chamados, optionsModule}) {
    // CONTEXT JOBS
    const { configuracoes, refresh, filterEmpreendimento } = useContext(JobsContext);

    // ESTADOS
    const [card, setCard] = useState('');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [iconAvaliacao, setIconAvaliacao] = useState([]);

    let troca_operador;

    if(configuracoes?.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_desabilitar){
        troca_operador = (JSON.parse(configuracoes?.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_desabilitar).trocar_operador == 1 ? false : true);
    }else{
        if(window.rs_permission_apl === 'leitura'){
            troca_operador = false;
        }else{
            troca_operador = true;
        }
    }

    // FUNÇÃO PARA BUSCAR TIPOS DE AVALIAÇÃO E MÓDULOS
    useEffect(() => {
        if(iconAvaliacao.length===0 && show){
            axios({
                method: 'get',
                url: window.host_madnezz+'/systems/integration-react/api/list.php?do=get_assessment&empreendimento_id='+filterEmpreendimento
            }).then((response) => {
                if(response.data){
                    setIconAvaliacao(response?.data);
                }
            });
        }
    },[show]);

    // FUNÇÃO PARA RECARREGAR CARD ESPECÍFICO
    function loadCard(){
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/list.php',
            params: {
                do: 'get_select',
                id_job_status: id
            }
        }).then((response) => {
            setCard(response?.data[0]);
            setLoading(false);
            setShow(true);
        });
    }

    const handleRefreshCard = () => {
        loadCard();
    }

    // AÇÕES AO ABRIR MODAL
    const handleShowModal = (id) => {
        setLoading(true);
        loadCard();
    }

    // AÇÕES AO FECHAR MODAL
    const handleCloseModal = () => {
        setShow(false);
    }

    useEffect(() => {
        if(show){
            loadCard();
        }
    },[refresh]);

    return (
        <>
            <Modal centered show={show} onHide={handleCloseModal}>
                <ModalBody padding={false}>
                    {(card ?
                        <CardJobs
                            card={card}
                            fases={fases}                                           
                            chamados={chamados}
                            fullwith={true}
                            shadow={false}
                            opened={show}
                            id_aux={card?.id_job_status}
                            view={false}
                            troca_operador={troca_operador}
                            job={card}    
                            refreshCard={handleRefreshCard}
                            changeStatus={handleRefreshCard}
                            changeOperator={handleRefreshCard}
                            modal={true}
                            report={true}
                            iconAvaliacao={iconAvaliacao}
                            optionsModule={optionsModule}       
                            filterModule={card?.id_modulo}
                            print={true}
                        />
                    :'')}
                </ModalBody>
            </Modal>

            <Icon
                type="view"
                loading={loading}
                onClick={() => handleShowModal()}
            />
        </>
    )
}

import "./inputEmoji.css";
import { useState, useEffect } from "react";
import Picker from 'emoji-picker-react';
import Icon from "../../../../../../components/body/icon";

export default function InputEmoji(props) {

    const [chosenEmojis, setChosenEmojis] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [inputValue, setInputValue] = useState('');

    function handleChosenEmoji(object) {
        const newEmojis = [...chosenEmojis];
        newEmojis.push(object);

        setChosenEmojis(newEmojis);
        setShowPicker(false);
        setInputValue(props.value + newEmojis.join(''));
    }

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);



    return (
        <>
            <input
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                className="inputStyle-2"
                value={inputValue}
                onChange={props.onChange}
            />
            <div className="inputDiv-2">
                {(
                    !showPicker ?
                        <img
                            className="emoji-icon-2"
                            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                            onClick={() => setShowPicker(val => !val)}
                        />
                        :
                        <span className="input-span">
                            <Icon
                                className="text-secondary"
                                type="close"
                                onClick={() => setShowPicker(val => !val)}
                            />
                        </span>
                )}

                {showPicker && <Picker
                    emojiStyle="native"
                    previewConfig={
                        {
                            defaultCaption: " ",
                            showPreview: false,
                        }
                    }
                    onEmojiClick={(emojiObject) => handleChosenEmoji(emojiObject.emoji)}
                />}
            </div>
        </>
    )
}
import { useState, useContext , useEffect} from "react";
import { cd } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';
import Title from '../../../components/body/title';
import Table from '../../../components/body/table';
import Tr from '../../../components/body/table/tr';
import Thead from '../../../components/body/table/thead';
import Icon from '../../../components/body/icon';
import Tbody from '../../../components/body/table/tbody';
import Td from '../../../components/body/table/tbody/td';
import SelectReact from '../../../components/body/select';
import Th from '../../../components/body/table/thead/th';
import Historico from "../Historico";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import CalendarTitle from "../../../components/body/calendarTitle";
import Filter from "../Filter";

export default function Lista() {
    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    //VARIAVEL COM INFOS
    const [info, setInfo] = useState([]);

    //ESTADO EXPANDIR COLUNAS
    const [hide, setHide] = useState(true);

    //ESTADO ITENS DA TABELA
    const [contrato, setContrato] = useState('');
    const [grupo, setGrupo] = useState('');
    const [filial, setFilial] = useState('');
    const [industria, setIndustria] = useState('');
    const [produto, setProduto] = useState('');
    const [searchDataInicio, setSearchDataInicio] = useState('');
    const [searchDataFim, setSearchDataFim] = useState('');
    const [observacao, setObservacao] = useState('');
    const [valor, setValor] = useState('');
    const [numPonto, setNumPonto] = useState('');
    const [codFornecedor, setCodFornecedor] = useState('');
    const [codProduto, setCodProduto] = useState('');
    const [codFase, setCodFase] = useState('');
    const [checkLoja, setCheckDeLoja] = useState('');
    const [dataFinalizadoInicio, setDataFinalizadoInicio] = useState('');
    const [dataFinalizadoFim, setDataFinalizadoFim] = useState('');
    const [status, setStatus] = useState('');

    //ESTADOS QUE ARMAZENAM ANO E MÊS DO HANDLE SELECT REACT
    const [year, setYear] = useState(window.currentYear);
    const [month, setMonth] = useState(window.currentMonth);
    const [dataInicio, setDataInicio] = useState(new Date(window.currentYear+'-'+window.currentMonth+'-'+'01'));
    const [dataFim, setDataFim] = useState(new Date(window.currentYear+'-'+window.currentMonth+'-'+window.currentMonthLastDay));

    //VARIAVEIS DOS FILTROS
    const todos_os_grupos = [
        {
            id: 1,
            label: "Gondola"
        }
    ]
    const todos_os_produtos = [
        {
            value: 1,
            label: "Clip String"
        },
        {
            value: 2,
            label: "Gondola"
        }
    ]

    //FILTROS
    const handleContrato = (e) => {
        setContrato(e);
    }

    const handleGrupo = (e) => {
        setGrupo(e);
    }

    const handleFilial = (e) => {
        setFilial(e);
    }

    const handleIndustria = (e) => {
        setIndustria(e);
    }

    const handleProduto = (e) => {
        setProduto(e);
    }

    const handleObservacao = (e) => {
        setObservacao(e);
    }

    const handleValor = (e) => {
        setValor(e);
    }

    const handleNumPonto = (e) => {
        setNumPonto(e);
    }

    const handleFornecedor = (e) => {
        setCodFornecedor(e);
    }

    const handleCodProduto = (e) => {
        setCodProduto(e);
    }

    const handleCodFase = (e) => {
        setCodFase(e);
    }

    const handleCheckLoja = (e) => {
        setCheckDeLoja(e);
    }

    const handleStatus = (e) => {
        setStatus(e);
    }

    //FILTRO DE DATA (INÍCIO) - SELECT REACT
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    }

    //FILTRO DE DATA (FIM) - SELECT REACT
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    }

    //FILTRO DATA INICIO - data_inicio
    const handleSearchDataInicio = (e) => {
        setSearchDataInicio(e);
        handleSetFilter(true);
    }
    //FILTRO DATA FIM - data_fim
    const handleSearchDataFim = (e) => {
        setSearchDataFim(e);
        handleSetFilter(true);
    }
    //FILTRO DATA FINALIZADO INICIO - di_i
    const handleDataFinalizadoInicio = (e) => {
        setDataFinalizadoInicio(e);
        handleSetFilter(true);
    }
    //FILTRO DATA FINALIZADO FIM - df_f
    const handleDataFinalizadoFim = (e) => {
        setDataFinalizadoFim(e);
        handleSetFilter(true);
    }
    const handleSetItems = (e) => {
        setInfo(e);
    }

    function changeSelect(e){
        setYear(e)
    }
    function changeMonth(e){
        setMonth(e)
    }

    function changeTableInfo(month,year){
        setDataInicio(new Date(year+'-'+month+'-01'))
        setDataFim(new Date(year+'-'+month+'-'+ window.currentMonthLastDay));
        handleSetFilter(true);
    }

    return (
        <div className="content">
            {/* FILTROS TESTEIRA */}
            <Filter actions={true} relatorio={true} />
            {/* no console diz que não consegue ler o length na linha 72  */}
            
            {/* RELATÓRIO */}
            <Row className="mt-4">
                <Col>
                    <Table
                        id="trade"
                        api={window.host + "/systems/trade/api/trade.php?do=get_list"}
                        params={{
                            tipo: "loja",
                            numerocontrato: contrato,
                            grupo: grupo,
                            filial: filial,
                            nomefantasiafornecedor: industria,
                            descricaoprodutotrade: produto,
                            data_inicio: (searchDataInicio ? cd(searchDataInicio) : ''),
                            data_fim: (searchDataFim ? cd(searchDataFim) : ''),
                            filtro_data_inicio: (dataInicio ? cd(dataInicio) : ''), //filtro do select react
                            filtro_data_fim: (dataFim ? cd(dataFim) : ''), //filtro do select react
                            obs: observacao,
                            valor: valor,
                            numeroponto: numPonto,
                            codigofornecedor: codFornecedor,
                            codigoprodutotrade: codProduto,
                            numerofase: codFase,
                            check_loja: checkLoja,
                            di_i: (dataFinalizadoInicio? cd(dataFinalizadoInicio) : ''),
                            df_f: (dataFinalizadoFim ? cd(dataFinalizadoFim) : ''), 
                            status: status,
                        }}
                        onLoad={handleSetItems}
                    >
                        <Thead>
                            <Tr>
                                <Th
                                    name='busca_contra'
                                    onChange={handleContrato}
                                >Contr.
                                </Th>
                                <Th
                                    name='busca_grupo'
                                    items={todos_os_grupos}
                                    onChange={handleGrupo}
                                >Grupo
                                </Th>
                                <Th
                                    name='busca_filial'
                                    api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true"}
                                    onChange={handleFilial}
                                >Filial
                                </Th>
                                <Th
                                    name='busca_industria'
                                    onChange={handleIndustria}
                                >Indústria
                                </Th>
                                <Th
                                    name='busca_produto'
                                    items={todos_os_produtos}
                                    onChange={handleProduto}
                                >Produto
                                </Th>
                                <Th
                                    type="date"
                                    start={{ value: searchDataInicio, onChange: handleSearchDataInicio }}
                                    end={{ value: searchDataFim, onChange: handleSearchDataFim }}
                                >Data
                                </Th>
                                <Th hide={hide}>Job</Th>
                                <Th hide={hide}>Job Categoria</Th>
                                <Th hide={hide}>Job SubCategoria</Th>
                                <Th hide={hide}>Job Status</Th>
                                <Th hide={hide}
                                    name='busca_observacao'                                
                                    onChange={handleObservacao}
                                >Observação
                                </Th>
                                <Th hide={hide}
                                    name='busca_valor'
                                    onChange={handleValor}
                                >Valor
                                </Th>
                                <Th hide={hide}
                                    name='busca_num_ponto'
                                    onChange={handleNumPonto}
                                >Nº Do Ponto
                                </Th>
                                <Th hide={hide}
                                    name='busca_cod_fornecedor'
                                    onChange={handleFornecedor}
                                >Cod. Fornecedor
                                </Th>
                                <Th hide={hide}
                                    name='busca_cod_produto'
                                    onChange={handleCodProduto}
                                >Cod. Produto
                                </Th>
                                <Th hide={hide}
                                    name='busca_cod_fase'
                                    onChange={handleCodFase}
                                >Cod. Fase
                                </Th>
                                <Th>Modelo</Th>
                                <Th
                                    name='busca_check_loja'
                                    items={[
                                        { value: 2, label: "Conforme" },
                                        { value: 3, label: "Não conforme" },
                                        { value: 4, label: "Não se aplica" }
                                    ]}
                                    onChange={handleCheckLoja}
                                >Check Loja
                                </Th>
                                <Th
                                    type="date"
                                    start={{ value: dataFinalizadoInicio, onChange: handleDataFinalizadoInicio }}
                                    end={{ value: dataFinalizadoFim, onChange: handleDataFinalizadoFim }}
                                >Data Finalizado
                                </Th>
                                <Th>Check ADM</Th>
                                <Th
                                    name='busca_status'
                                    items={[
                                        { value: 2, label: "Checar" },
                                        { value: 3, label: "Avaliar" },
                                        { value: 4, label: "Aprovado" },
                                        { value: 5, label: "Reprovado" }
                                    ]}
                                    onChange={handleStatus}
                                >Status
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(info.length > 0 ?
                                info.map((item) => {
                                    var color;
                                    if (item.status_adm_nome === "Avaliar"){
                                        color = "text-primary"
                                    }
                                    
                                    return (
                                        <Tr key={item.trade_id}>
                                            <Td>
                                                {item.numerocontrato}
                                            </Td>
                                            <Td>
                                                {item.grupo}
                                            </Td>
                                            <Td>
                                                {item.filial}
                                            </Td>
                                            <Td>
                                                {item.nomefantasiafornecedor}
                                            </Td>
                                            <Td>
                                                {item.descricaoprodutotrade}
                                            </Td>
                                            <Td>
                                                {item.data_contrato}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.job}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.job_categoria}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.job_subcategoria}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.job_status}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.obs}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.valor}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.numeroponto}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.codigofornecedor}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.codigoprodutotrade}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.numerofase}
                                            </Td>
                                            <Td>
                                                <Icon type="file" />
                                                <Icon type="view" />
                                            </Td>
                                            <Td align="center">
                                                {(
                                                    item.job_status === "Concluído" ?
                                                        <Icon type="view" />
                                                        :
                                                        <Icon type="view"
                                                            disabled={true} />
                                                )}
                                                {(
                                                    item.job_status === "Concluído" ?
                                                        <Icon type="aprovar"
                                                            className="text-success" />
                                                        : " "
                                                )}
                                            </Td>
                                            <Td>
                                                {cd(item.data_foto_loja)}
                                            </Td>
                                            <Td>
                                                <Icon type="check" />
                                                <Icon type="reprovar2" />
                                                <Historico
                                                    id={item.trade_id}
                                                    contrato={item.numerocontrato}
                                                    loja={item.filial}
                                                    grupo={item.grupo}
                                                    data={item.data_contrato}
                                                />
                                            </Td>
                                            <Td className={color}>
                                                {item.status_adm_nome}
                                            </Td>
                                        </Tr>
                                    )
                                })
                            :<></>)}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}
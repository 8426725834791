import { useState, useContext } from "react";
import SelectReact from "../../select";
import axios from "axios";
import { toast } from "react-hot-toast";
import { JobsContext } from "../../../../context/Jobs";
    
export default function TrocaOperador({ label, placeholder, options, margin, params, filterModule, onChange, menuPlacement, fases, chamados, visitas }){
    // CONTEXT JOBS
    const { filterEmpreendimento } = useContext(JobsContext);

    // ESTADOS
    const [operatorSelected, setOperatorSelected] = useState([]);

    // FUNÇÃO PARA TROCAR O OPERADOR
    function handleChangeOperator(e){
        let tipo_aux;
        if(params?.tipo_fase === 'Check'){
            tipo_aux = 'present';         
        }else{
            if(fases){
                tipo_aux = 'present';
            }else{
                tipo_aux = 'next';
            }
        }

        setOperatorSelected(e.value);
    
        axios({
            method: 'post',
            url: window.host_madnezz+'/systems/integration-react/api/list.php?do=set_operator&filter_id_module='+(params.id_modulo ? params.id_modulo : params?.filterModule),
            data: {
                id_job: params?.id_job,
                id_mov: params?.id_job_status,
                id_operador: e.value,
                tipo_fase: params?.tipo_fase,
                ativ_desc: params?.ativ_desc + ' ' + e.label,
                tipo: tipo_aux,
                filter_subtype: params?.filter_subtype,
                nivel_msg: 2
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            toast('Card encaminhado ao operador ' + e.label);
            onChange();
        });
    }

    // DEFINE A API QUE IRÁ BUSCAR OS OPTIONS DE OPERADORES
    let url_aux, params_aux;

    if((chamados || params?.id_modulo) && !fases){
        let tipo_fase_aux;

        if(params?.tipo_fase === 'Check'){
            tipo_fase_aux = 'Check';
        }else{
            tipo_fase_aux = 'Operação';
        }

        url_aux = window.host_madnezz+'/systems/integration-react/api/list.php?do=get_operator';
        params_aux = {
            empreendimento_id: filterEmpreendimento,
            filter_id_module: (params?.id_modulo ? params?.id_modulo : filterModule),
            tipo_fase: tipo_fase_aux,
            filter_subtype: params?.filter_subtype,
            filter_id_operator: (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'checker' ? window.rs_id_usr : ''),
            id_apl: 224
        }
    }else{
        let component_aux = '';
        if(params?.filter_subtype == 'user'){
            component_aux = 'usuario';
        }else if(params?.filter_subtype == 'store'){
            component_aux = 'loja';
        }

        url_aux = window.host+'/api/sql.php?do=select&component='+component_aux+'&np=true';
        params_aux = {
            empreendimento_id: filterEmpreendimento,
        }  
    }

    return(
        <div className={(margin !== false ? 'mt-3 mb-2' : '')}>
            <SelectReact
                label={label}
                placeholder={placeholder}
                name="troca_operador"
                options={options}
                api={{
                    url: url_aux,
                    params: params_aux
                }}
                value={operatorSelected}
                required={false}
                menuPlacement={menuPlacement}
                isRtl={true}
                onChange={(e) => handleChangeOperator(e)}
            />
        </div>
    )
}
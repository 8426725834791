import "./stories.css";
import Tippy from "@tippyjs/react";
import { useState, useEffect } from "react";
import { Circle } from "rc-progress";

export default function Stories(props) {

    // filtro quando tiver api
    // params:{
    //     filter_multiple_columns: props?.search,
    //     id_apl: props?.search
    // }

    //ESTADOS
    const [numero, setNumero] = useState([
        { id: 1, num: 10 }, { id: 3, num: 45 }, { id: 4, num: 55 }, { id: 7, num: 90 }, { id: 5, num: 110 },{ id: 8, num: 119 }, { id: 9, num: 120 },  { id: 6, num: 130 }
    ])

    let color;

    function selectColor(num) {
        if (num >= 0 && num <= 50) {
            color = "#dc3545"
        } else if (num >= 51 && num <= 99) {
            color = "#ffc577"
        } else if (num >= 100 && num <= 119) {
            color = "#50b7ff"
        } else if (num >= 120) {
            color = "#198754"
        } else {
            color = "#dc3545"
        }
    }

    return (
        <div className="div-home-stories" >
            <div>
                <h4>KPI - Metas</h4>
                <div className="gray-border"></div>
                <ul>
                    {
                        numero &&
                        numero.map((item, i) => {
                            let new_num

                            if(item.num > 100){
                                new_num = 100
                            }else{
                                new_num = item.num
                            }

                            selectColor(item.num)

                            return (
                                <li key={item.id} >
                                    <div className="div-porcentagem" >
                                        <Circle
                                            percent={new_num }
                                            strokeWidth={4}
                                            trailWidth={1}
                                            strokeColor={color}
                                        />
                                        <div className="texto-porcentagem" style={{ color: color }}>
                                            {`${item.num}%`}
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Lorem Ipsum</h5>
                                        <p>
                                            <Tippy content="Meta mensal"><span>10.000,00 /</span></Tippy>
                                            <Tippy content="Meta diária"><span> 600,00</span></Tippy>
                                        </p>
                                    </div>
                                </li>
                            )
                        })
                    }                    
                </ul>
            </div>

            <div className="stories-btn" >
            <a href="https://sistemas.malltech.com.br/systems/vendas" target="_blank" ><button>Vendas</button></a>
            </div>
        </div>
    )
}
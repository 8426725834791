import "./create-post.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { cd } from "../../../../../_assets/js/global";
import Icon from "../../../../../components/body/icon"
import Form from "../../../../../components/body/form";
import Input from "../../../../../components/body/form/input";
import AbrirChamado from '../../../../../components/body/card/editar';


export default function BuscaPostComponent(props) {

    return (
        <div className="busca-post-container" >
            <div className="div-busca-post" >
                <div className="busca-post-container" >
                    <Input
                        className="busca-post-Form-Input"
                        placeholder="Procure aqui..."
                        value={props?.value}
                        onChange={props?.onChange}
                        icon={<Icon type='search' onClick={() => props.handleSearch(props?.value)} />}
                    />
                </div>
            </div>
            <div className="busca-post-button-container" >
                <AbrirChamado
                    empty={true}
                    format={'custom'}
                    chamados={true}
                    boxUser={false}
                    modalLarge={false}
                    integration={false}
                    id_apl={224}
                    id_module={231}
                    component={
                        <button>
                            Abrir Chamado
                        </button>
                    }
                />
            </div>
        </div>
    )
}
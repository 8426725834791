import React from 'react';
import { useRef } from 'react';
import { cd, get_date } from '../../../_assets/js/global';
import { useState } from 'react';
// import axios from '../lib/axios';
import axios from 'axios';
import { useEffect } from 'react';
import "./style.css"
import Loader from '../../../components/body/loader';

function VendaInput({ loja, dia, color, venda, vendaRef, mes, callback, style, loading }) {


    const formRef = useRef(null);

    //VALOR DO INPUT
    const [valor, setValor] = useState(null);
    //LIMPAR O INPUT ENQUANTO CARREGA INFO, MANDAR VENDA, SE TIVER
    useEffect(() => {
        if (loading) {
            setValor('');
        }
        if (venda) {
            setValor(parseInt(venda).toFixed(2))
        }
    }, [loja, mes, venda])

    //MANDAR INFO DO INPUT PARA A API 
    const onSubmit = () => {
        // e.preventDefault();
        const data_aux = get_date('date_sql', (cd(dia)));
        // const token = localStorage.getItem('token');
        const valor_aux = valor?.replace(',', '.')
        axios({
            url: "https://backend.madnezz.com.br/api/v1/gis/lancamento",
            data: {
                loja_id: loja,
                data: data_aux,
                venda_1: valor_aux,
            },
            method: 'post',
            // headers: {
            //     Authorization: `Bearer ${token}`,
            // }
        }).then((_response) => {
            callback(true)
        })
    }

    //MANDAR INFO DO INPUT COM ENTER
    const handleEnter = (e) => {
        // e.preventDefault();
        // e.key === 'Enter' && formRef.current.submit();
        e.key === 'Enter' && onSubmit();
    }
 
    return (
        <form
            // onSubmit={onSubmit}
            className={color}
            ref={formRef}
        >
            {
                loading ?

                    <Loader show={true} />

                    :
                    <>
                        <input
                            className={`${style} ${color}`}
                            type="text"
                            name="venda_1"
                            // defaultValue={venda !== null ? parseInt(venda).toFixed(2).replace('.', ',') : null}
                            onKeyDown={handleEnter}
                            placeholder="R$:"
                            // label="Digite o valor"
                            value={valor?.replace('.',',') || ''}
                            onChange={(e) => setValor(e.target.value)}
                        />
                        <input
                            type="text" name="loja_id"
                            defaultValue={loja}
                            hidden
                        />
                        <input
                            type="text"
                            name="data"
                            defaultValue={dia}
                            hidden
                        />
                    </>
            }
        </form>
    )
}

export default VendaInput
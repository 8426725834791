import { useEffect, useState } from 'react';

/*SWIPER*/
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import style from './style.module.scss';
import banner from '../../../../_assets/img/hs_telecom.jpg'; // PROVISÓRIO
import axios from 'axios';
import Icon from "../../../../components/body/icon";
import Editar from './Editar';

export default function Banner() {
    // ESTADOS
    const [swiper, setSwiper] = useState();
    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);

    function getItems(){
        axios({
            method: 'get',
            url: window.host + '/systems/banner/api/banner.php',
            params: {
                do: 'get'
            }
        }).then((response) => {
            if (response.data) {
                setItems(response.data);
            }
        })
    }

    // GET BANNERS
    useEffect(() => {
        getItems();
    }, []);

    const handleEditarBannerCallback = (e) => {
        setShow(e?.show);  

        if(e?.reload){
            getItems();
        }
    }

   

    if (items.length > 0) {
        return (
            <div className={style.banner}>
                {(show?<Editar callback={handleEditarBannerCallback}/>:'')}

                {(window.rs_permission_apl === 'master' || window.rs_permission_usr>1 ?
                    <div className={style.button} onClick={() => handleEditarBannerCallback({show:true})}>
                        <Icon type="edit" />
                    </div>    
                :'')}
                
                <Swiper
                    ref={swiper}
                    focusableElements="input, select, div, button, label, option, textarea"
                    preventClicks={false}
                    modules={[Autoplay, Pagination]}
                    autoHeight={false}
                    speed={1000}
                    autoplay={{
                        delay: 3000,
                    }}
                    spaceBetween={24}
                    onSwiper={(swiper) => setSwiper(swiper)}
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                    className="swiper_banner"
                >
                    {(items.map((item, i) => {
                        if(!item?.data_inicial && !item?.data_final){
                            return (
                                <SwiperSlide key={'banner_' + item?.id}>
                                    <img src={process.env.REACT_APP_URL_UPLOAD + '/' + item?.anexo} />
                                </SwiperSlide>
                            )
                        }else{
                           let data_inicial_valida= (item?.data_inicial && new Date(item?.data_inicial) <= new Date()) || !item?.data_inicial;
                           let data_final_valida= (item?.data_final && new Date(item?.data_final) >= new Date()) || !item?.data_final;

                           if(data_final_valida && data_inicial_valida){
                                return (
                                    <SwiperSlide key={'banner_' + item?.id}>
                                        <img src={process.env.REACT_APP_URL_UPLOAD + '/' + item?.anexo} />
                                    </SwiperSlide>
                                )
                           }
                        }
                        
                    }))}
                </Swiper>
            </div>
        )
    }
}

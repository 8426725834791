import { cd, get_date } from "../../../_assets/js/global";
import { useState, useEffect } from "react";
import Dashboard from "../../../components/body/dashboard"
import Table from "../../../components/body/table";
import Tr from "../../../components/body/table/tr";
import Th from "../../../components/body/table/thead/th";
import Col from "../../../components/body/col";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";
import Input from "../../../components/body/form/input";
import Row from "../../../components/body/row";
import axios from "axios";

export default function DashboadAcesso({ icons, filters, mes, ano }) {


    //ESTADOS FILTRO
    const [filterMonth, setFilterMonth] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    const [dashMonth, setDashMonth] = useState(window.currentMonth);
    const [dashYear, setDashYear] = useState(window.currentYear);

    //ESTADOS DASH
    //SUPERVISOR
    const [dashSupervisor1, setDashSupervisor1] = useState([]);
    const [dashSupervisor2, setDashSupervisor2] = useState();
    const [dashSupervisor3, setDashSupervisor3] = useState();
    const [supervisorSelected, setSupervisorSelected] = useState();
    //ORDER Supervisor
    const [orderSupervisor, setOrderSupervisor] = useState([{ column: 'usuario', type: 'desc' }]);
    const [orderSupervisor1, setOrderSupervisor1] = useState(true);
    const [orderSupervisor2, setOrderSupervisor2] = useState(false);
    const [orderSupervisor3, setOrderSupervisor3] = useState(false);
    const [orderSupervisor4, setOrderSupervisor4] = useState(false);
    //LOJA
    const [dashLoja1, setDashLoja1] = useState();
    const [dashLoja2, setDashLoja2] = useState();
    const [dashLoja3, setDashLoja3] = useState();
    const [lojaSelected, setLojaSelected] = useState();
    //ORDER Loja
    const [orderLoja, setOrderLoja] = useState([{ column: 'loja', type: 'desc' }]);
    const [orderLoja1, setOrderLoja1] = useState(true);
    const [orderLoja2, setOrderLoja2] = useState(false);
    const [orderLoja3, setOrderLoja3] = useState(false);
    const [orderLoja4, setOrderLoja4] = useState(false);
    //USUÁRIO
    const [dashUsuario1, setDashUsuario1] = useState();
    const [dashUsuario2, setDashUsuario2] = useState();
    const [dashUsuario3, setDashUsuario3] = useState();
    const [usuarioSelected, setUsuarioSelected] = useState();
    //ORDER Usuario
    const [orderUsuario, setOrderUsuario] = useState([{ column: 'usuario', type: 'desc' }]);
    const [orderUsuario1, setOrderUsuario1] = useState(true);
    const [orderUsuario2, setOrderUsuario2] = useState(false);
    const [orderUsuario3, setOrderUsuario3] = useState(false);
    const [orderUsuario4, setOrderUsuario4] = useState(false);


    //HANDLE EVENT DE ACORDO COM O SET-STATE
    const handleEvent = (setState) => (e) => {
        if (e.length > 0) {
            setState(e);
        } else {
            setState(
                <Tr empty={true}></Tr>
            )
        }
    }
    //FILTRO DE MES
    const handleMes = (e) => {
        setFilterMonth(e);
        let arr_aux = cd(e).split("/");
        setDashMonth(arr_aux[1]);
        setDashYear(arr_aux[2]);
    }

    //ORDENAR POR ASC E DESC   
    // ORDENAÇÃO
    function handleOrderSupervisor(order_1, order_2, order_3, order_4) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'usuario', type: order_1 });
            setOrderSupervisor1(true);
            setOrderSupervisor2(false);
            setOrderSupervisor3(false);
            setOrderSupervisor4(false);
        }

        if (order_2) {
            order_aux.push({ column: 'qtd_acesso', type: order_2 });
            setOrderSupervisor1(false);
            setOrderSupervisor2(true);
            setOrderSupervisor3(false);
            setOrderSupervisor4(false);
        }
        if (order_3) {
            order_aux.push({ column: 'qtd_dias', type: order_3 });
            setOrderSupervisor1(false);
            setOrderSupervisor2(false);
            setOrderSupervisor3(true);
            setOrderSupervisor4(false);
        }
        if (order_4) {
            order_aux.push({ column: 'qtd_dias', type: order_4 });
            setOrderSupervisor1(false);
            setOrderSupervisor2(false);
            setOrderSupervisor3(false);
            setOrderSupervisor4(true);
        }

        setOrderSupervisor(order_aux);
    }
    // ORDENAÇÃO Loja
    function handleOrderLoja(order_1, order_2, order_3, order_4) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'loja', type: order_1 });
            setOrderLoja1(true);
            setOrderLoja2(false);
            setOrderLoja3(false);
            setOrderLoja4(false);
        }

        if (order_2) {
            order_aux.push({ column: 'qtd_acesso', type: order_2 });
            setOrderLoja1(false);
            setOrderLoja2(true);
            setOrderLoja3(false);
            setOrderLoja4(false);
        }
        if (order_3) {
            order_aux.push({ column: 'qtd_dias', type: order_3 });
            setOrderLoja1(false);
            setOrderLoja2(false);
            setOrderLoja3(true);
            setOrderLoja4(false);
        }
        if (order_4) {
            order_aux.push({ column: 'qtd_dias', type: order_4 });
            setOrderLoja1(false);
            setOrderLoja2(false);
            setOrderLoja3(false);
            setOrderLoja4(true);
        }

        setOrderLoja(order_aux);
    }
    // ORDENAÇÃO Usuario
    function handleOrderUsuario(order_1, order_2, order_3, order_4) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'usuario', type: order_1 });
            setOrderUsuario1(true);
            setOrderUsuario2(false);
            setOrderUsuario3(false);
            setOrderUsuario4(false);
        }

        if (order_2) {
            order_aux.push({ column: 'qtd_acesso', type: order_2 });
            setOrderUsuario1(false);
            setOrderUsuario2(true);
            setOrderUsuario3(false);
            setOrderUsuario4(false);
        }
        if (order_3) {
            order_aux.push({ column: 'qtd_dias', type: order_3 });
            setOrderUsuario1(false);
            setOrderUsuario2(false);
            setOrderUsuario3(true);
            setOrderUsuario4(false);
        }
        if (order_4) {
            order_aux.push({ column: 'qtd_dias', type: order_4 });
            setOrderUsuario1(false);
            setOrderUsuario2(false);
            setOrderUsuario3(false);
            setOrderUsuario4(true);
        }

        setOrderUsuario(order_aux);
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    {/* <Icon type='print' />
                    <Icon type='excel' api={{ body: body }} /> */}
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={filterMonth}
                        onChange={(e) => handleMes(e)}
                    />
                </>
            )
        }
    }, [filterMonth]);

    function ultimoDiaDoMes(ano, mes) {
        if (mes === 12) {
            ano++;
            mes = 0;
        }
        const ultimoDia = new Date(ano, mes + 1, 0).getDate();
        return ultimoDia;
    }

    const ultimoDia = ultimoDiaDoMes(dashYear, dashMonth + 1);

    //SUBTRAIR MÊS
    let data_1_inicio
    let data_1_final
    let data_2_inicio
    let data_2_final
    let data_3_inicio
    let data_3_final
    if (dashMonth) {
        data_1_inicio = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, 1)))));
        data_1_final = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, ultimoDia - 1)))));
        data_2_inicio = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, 1))), 'date_sub_month', 1));
        data_2_final = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, ultimoDia - 1))), 'date_sub_month', 1));
        data_3_inicio = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, 1))), 'date_sub_month', 2));
        data_3_final = (get_date('date_sql', (cd(new Date(dashYear, dashMonth - 1, ultimoDia - 1))), 'date_sub_month', 2));
    }

    return (
        <Row wrap={(window.isMobile ? true : false)}>
            <Col>
                <Dashboard
                    id="regional"
                    thead={
                        <Tr>
                            <Th
                            // cursor="pointer"
                            // active={orderSupervisor1}
                            // onClick={() => handleOrderSupervisor((orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                            >
                                Nome
                                {/* {orderSupervisor1 ? <Icon type={'sort-' + (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderSupervisor1 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                                //  cursor="pointer"
                                // active={orderSupervisor2}
                                // onClick={() => handleOrderSupervisor(undefined, (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined)}
                                title="Total de Acessos"
                            >
                                T/ Acessos
                                {/* {orderSupervisor2 ? <Icon type={'sort-' + (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderSupervisor2 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderSupervisor3}
                            // onClick={() => handleOrderSupervisor(undefined, undefined, (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                            >
                                Dias ON
                                {/* {orderSupervisor3 ? <Icon type={'sort-' + (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderSupervisor3 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderSupervisor4}
                            // onClick={() => handleOrderSupervisor(undefined, undefined, undefined, (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc'))}
                            >
                                Dias OFF
                                {/* {orderSupervisor4 ? <Icon type={'sort-' + (orderSupervisor[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderSupervisor4 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                        </Tr>
                    }
                    cols={{
                        col_1: {
                            title: "Supervisor - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 0)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 0))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'supervisor',
                                    filter_date_start_access: data_1_inicio,
                                    filter_date_end_access: data_1_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashSupervisor1?.length > 0 ?
                                    dashSupervisor1?.map((item, i) => {
                                        return (
                                            <Tr
                                                key={i}
                                            //  active={(supervisorSelected === item.id ? true : false)}
                                            >
                                                <Td
                                                //  cursor="pointer"
                                                //   onClick={() => setSupervisorSelected(supervisorSelected === item.id ? '' : item.id)}
                                                >{item?.supervisor_loja ? item?.supervisor_loja : 'SEM SUPERVISOR'}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashSupervisor1)
                        },
                        col_2: {
                            title: "Supervisor - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 1)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 1))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'supervisor',
                                    filter_date_start_access: data_2_inicio,
                                    filter_date_end_access: data_2_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashSupervisor2?.length > 0 ?
                                    dashSupervisor2?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.supervisor_loja ? item?.supervisor_loja : 'SEM SUPERVISOR'}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashSupervisor2)
                        },
                        col_3: {
                            title: "Supervisor - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 2)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 2))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'supervisor',
                                    filter_date_start_access: data_3_inicio,
                                    filter_date_end_access: data_3_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashSupervisor3?.length > 0 ?
                                    dashSupervisor3?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.supervisor_loja ? item?.supervisor_loja : 'SEM SUPERVISOR'}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashSupervisor3)
                        }
                    }}
                ></Dashboard>
            </Col>
            <Col>
                <Dashboard
                    id="loja"
                    thead={
                        <Tr>
                            <Th
                            // cursor="pointer"
                            // active={orderLoja1}
                            // onClick={() => handleOrderLoja((orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                            >
                                Nome
                                {/* {orderLoja1 ? <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja1 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                                // cursor="pointer"
                                // active={orderLoja2}
                                // onClick={() => handleOrderLoja(undefined, (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined)}
                                title="Total de Acessos"
                            >
                                T/ Acessos
                                {/* {orderLoja2 ? <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja2 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderLoja3}
                            // onClick={() => handleOrderLoja(undefined, undefined, (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                            >
                                Dias ON
                                {/* {orderLoja3 ? <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja3 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderLoja4}
                            // onClick={() => handleOrderLoja(undefined, undefined, undefined, (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'))}
                            >
                                Dias OFF
                                {/* {orderLoja4 ? <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja4 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                        </Tr>
                    }
                    cols={{
                        col_1: {
                            title: "Loja - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 0)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 0))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'store',
                                    filter_date_start_access: data_1_inicio,
                                    filter_date_end_access: data_1_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashLoja1?.length > 0 ?
                                    dashLoja1?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.loja}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashLoja1)
                        },
                        col_2: {
                            title: "Loja - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 1)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 1))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'store',
                                    filter_date_start_access: data_2_inicio,
                                    filter_date_end_access: data_2_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashLoja2?.length > 0 ?
                                    dashLoja2?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.loja}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashLoja2)
                        },
                        col_3: {
                            title: "Loja - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 2)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 2))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'store',
                                    filter_date_start_access: data_3_inicio,
                                    filter_date_end_access: data_3_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashLoja3?.length > 0 ?
                                    dashLoja3?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.loja}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashLoja3)
                        }
                    }}
                ></Dashboard>
            </Col>

            <Col>
                <Dashboard
                    id="usuario"
                    thead={
                        <Tr>
                            <Th
                            // cursor="pointer"
                            // active={orderUsuario1}
                            // onClick={() => handleOrderUsuario((orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                            >
                                Nome
                                {/* {orderUsuario1 ? <Icon type={'sort-' + (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderUsuario1 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                                // cursor="pointer"
                                // active={orderUsuario2}
                                // onClick={() => handleOrderUsuario(undefined, (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined)}
                                title="Total de Acessos"
                            >
                                T/ Acessos
                                {/* {orderUsuario2 ? <Icon type={'sort-' + (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderUsuario2 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderUsuario3}
                            // onClick={() => handleOrderUsuario(undefined, undefined, (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                            >
                                Dias ON
                                {/* {orderUsuario3 ? <Icon type={'sort-' + (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderUsuario3 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                            <Th
                            // cursor="pointer"
                            // active={orderUsuario4}
                            // onClick={() => handleOrderUsuario(undefined, undefined, undefined, (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc'))}
                            >
                                Dias OFF
                                {/* {orderUsuario4 ? <Icon type={'sort-' + (orderUsuario[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderUsuario4 ? 'text-primary' : '')} /> : ''} */}
                            </Th>
                        </Tr>
                    }
                    cols={{
                        col_1: {
                            title: "Usuário - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 0)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 0))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'user',
                                    filter_date_start_access: data_1_inicio,
                                    filter_date_end_access: data_1_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashUsuario1?.length > 0 ?
                                    dashUsuario1?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.usuario}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashUsuario1)
                        },
                        col_2: {
                            title: "Usuário - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 1)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 1))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'user',
                                    filter_date_start_access: data_2_inicio,
                                    filter_date_end_access: data_2_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashUsuario2?.length > 0 ?
                                    dashUsuario2?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.usuario}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashUsuario2)
                        },
                        col_3: {
                            title: "Usuário - " + (dashMonth ? get_date('month_name', get_date('date', '01/' + dashMonth + '/' + dashYear, 'date_sub_month', 2)) :
                                get_date('month_name', get_date('date', '01/' + window.currentMonth + '/' + window.currentYear, 'date_sub_month', 2))),
                            api: {
                                url: window.host_madnezz + '/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess3',
                                params: {
                                    filter_type: 'user',
                                    filter_date_start_access: data_3_inicio,
                                    filter_date_end_access: data_3_final,
                                    reload: (filterMonth)
                                },
                                key_aux_2: 'data'
                            },
                            tbody: (
                                dashUsuario3?.length > 0 ?
                                    dashUsuario3?.map((item, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>{item?.usuario}</Td>
                                                <Td align="center" >{item?.qtd_acesso}</Td>
                                                <Td align="center" >{item?.qtd_dias}</Td>
                                                <Td align="center" >{(ultimoDia - item.qtd_dias)}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <></>
                            ),
                            callback: handleEvent(setDashUsuario3)
                        }
                    }}
                ></Dashboard>
            </Col>
        </Row>
    )
}
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Icon from "../../../../components/body/icon";
import style from './style.module.scss';
import Tippy from "@tippyjs/react";
import Modal from "../../../../components/body/modal";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../../components/body/modal/modalBody";
import Table from "../../../../components/body/table";
import Thead from "../../../../components/body/table/thead";
import Tr from "../../../../components/body/table/tr";
import Th from "../../../../components/body/table/thead/th";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import { cd } from "../../../../_assets/js/global";
import Loader from "../../../../components/body/loader";
import Pasta from "./Pasta";
import { MdOutlineFolderCopy } from "react-icons/md";

export default function Documentos({search, active, callback, permission}){
    // ESTADOS
    const [showModal, setShowModal] = useState(false);
    const [lista, setLista] = useState([]);
    const [collapse, setCollapse] = useState(window.isMobile);
    const [loading, setLoading] = useState(true);
    const [documentoInfo, setDocumentoInfo] = useState([]);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [modalTitle, setModalTitle] = useState(null);
    const [optionsCategoria, setOptionsCategoria] = useState([]);
    const [categorias, setCategorias] = useState([]);

    // BUSCA CATEGORIAS
    function getDocumentos(level) {
        setLoading(true);
        setCategorias([]);
        
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/lista.php',
            params: {
                do: 'get_foldersFiles',
                sistema_id: 218,
                limit: false,
                page: 0,
                level: level,
                // aux_id: categoria.id
            }
        }).then((response) => {
            if(response.data){
                setCategorias(response.data)
            }
            
            setLoading(false);
        });
    }

    // EXECUTA A FUNÇÃO DE BUSCA SEMPRE QUE SOFRE ALTERAÇÃO NA PROPS SEARCH
    useEffect(() => {
        getDocumentos();
    }, [search])

    // GET INFO DOCUMENTO
    function getInfo(aux_id, level, name){
        setShowModal(true);
        setLoadingInfo(true);
        if(name){
            setModalTitle('Documentos - '+name);
        }else{
            setModalTitle('Documentos com "'+search+'"');
        }
        
        axios({
            method: 'get',
            url: window.host+'/systems/treinamento/api/lista.php',
            params: {
                do: "get_foldersFiles",
                sistema_id: 218,
                aux_id: aux_id,
                filter_multiple_columns: aux_id ? undefined : search, // SE RECEBER O AUX_ID NÃO PASSA ESSE FILTRO
                level: level,
                limit: false
            }
        }).then((response) => {
            setLoadingInfo(false);

            if(response.data){
                setDocumentoInfo(response.data);
            }
        });
    }

    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            setDocumentoInfo([]);
        },500);
    }

    // MOSTRAR/ESCONDER SIDEBAR
    const handleCollapseSidebar = (e) => {
        setCollapse(!e?.sidebar);
       
        if(callback){
            callback({
                active: e?.sidebar
            });
        }
    }
{/* <MdOutlineFolderCopy /> */}

    return(
        <>
            <Modal show={showModal} onHide={handleCloseModal} large={true} >
                <ModalHeader>
                    <ModalTitle>
                        {modalTitle}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                <Table text_limit={loadingInfo ? false : true} className="mb-4">
                        <Thead>
                            <Tr>
                                <Th>
                                    Nome
                                </Th>
                                <Th>
                                    Cadastro
                                </Th>
                                <Th>
                                    Atualização
                                </Th>
                                <Th align="center" width={1}>
                                    Ações
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(loadingInfo ?
                                <Tr>
                                    <Td colspan="100%">
                                        <Loader />
                                    </Td>
                                </Tr>
                            :
                                (documentoInfo.filter((elem) => elem.file_id != 904).length > 0 ?
                                    documentoInfo.filter((elem) => elem.file_id != 904).map((item, i) => { // ID ESPECÍFICO DA HS (PROVISÓRIO) PARA APRESENTAR AO CLIENTE
                                        return (
                                            <Tr key={item.file_id}>
                                                <Td>{item.file_name}</Td>
                                                <Td>{item.cad !== "-" ? cd(item.cad) : item.cad}</Td>
                                                <Td>{item.cad_update !== "-" ? cd(item.cad_update) : item.cad_update}</Td>
                                                <Td align="center" width={1}>
                                                    <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.anexo} target="_blank" rel="noreferrer">
                                                        <Icon type="view" />
                                                    </a>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                :<></>)
                            )}
                        </Tbody>
                    </Table>

                    {(documentoInfo.filter((elem) => elem.file_id == 904).length > 0 ?
                        <>
                            <ModalTitle close={false}>
                                Links
                            </ModalTitle>
                            <Table className="my-3" text_limit={false}>
                                <Tbody>
                                    <Tr>
                                        <Td width={1}>
                                            Claro Troca
                                        </Td>
                                        <Td>
                                            <a href="https://tradeinv2.trocafone.com/claro-aa/auth/login" target="_blank">
                                                https://tradeinv2.trocafone.com/claro-aa/auth/login
                                            </a>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={1}>
                                            Payjoy
                                        </Td>
                                        <Td>
                                            <a href="https://movil-journey.adelantosbrasil.com/" target="_blank">
                                                https://movil-journey.adelantosbrasil.com/
                                            </a>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={1}>
                                            Soudi Captação
                                        </Td>
                                        <Td>
                                            <a href="https://soudi.onboarding.smilego.io/app/login" target="_blank">
                                            https://soudi.onboarding.smilego.io/app/login
                                            </a>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={1}>
                                           Soudi SAV
                                        </Td>
                                        <Td>
                                            <a href="https://sav.wooza.com.br/hstelecom" target="_blank">
                                                https://sav.wooza.com.br/hstelecom
                                            </a>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </>
                    :'')}
                </ModalBody>
            </Modal>

            <div className={style.documentos + ' ' + (collapse ? '' : style.active)}>
                <div className={style.subtitle} style={{display: 'none'}}>
                    <div>
                        <Icon type="document"  className="text-primary" />  Pastas
                    </div>
                    
                    {(permission == 2 ?
                        <a href={window.host+'systems/documentos-novo'} target="_blank">
                            <Icon type="plus" />
                        </a>
                    :'')}
                </div>

                <ul>

                    {( !collapse ? <li className="text-primary"><MdOutlineFolderCopy className="text-primary" /> Todos os Documentos</li> : '' )}

                    {(loading ?
                        [...Array(20)].map((item, i) => (
                            <li
                                key={'documento_loading_' + i}
                                className={style.loading}
                            ></li>
                        ))
                    :
                        (categorias.length > 0 ?
                            categorias.map((categoria, i) => {
                                return(
                                    <Pasta
                                        index={i}
                                        id={categoria.folder_id}
                                        sidebar={collapse}
                                        level={0}
                                        item={categoria}
                                        callback={handleCollapseSidebar}
                                    />
                                )
                            })
                        :        
                            (!collapse ?                    
                                (search ?
                                    <>Nenhum documento encontrado para: <b>{search}</b></>
                                :
                                    <>Nenhum documento encontrado</>
                                )
                            :'')
                        )
                    )}
                </ul>

                {(search && !collapse ?
                    <button
                        onClick={(e) => getInfo(undefined, 4, undefined)}
                    >
                        Buscar documentos com "{search}"
                    </button>
                :'')}
            </div>
        </>
    )
}

import FastLogo from "../../../_assets/img/logo.png";

export default function TemperaturaTable(props) {
    return (
        <div className="container-temperaturas">
            <div className="" >
                <div className="main-div-temperaturas">
                    <h3>CONTROLE DE TEMPERATURA</h3>
                    <div>
                        <img src={FastLogo} />
                        {props?.logo ? <img className="logo-temperaturas" src={props.logo} /> : <></>}
                    </div>
                </div>
                <div className="div-temperaturas">
                    <span className="span-title-temperaturas">Categoria:</span>
                    <span className="span-temperaturas"> {props?.categoria}</span>
                    <span className="span-title-temperaturas">Nome:</span><span className="span-temperaturas">{props?.nome}</span>
                </div>
                <div className="div-temperaturas">
                    <span className="span-title-temperaturas">Razão Social:</span>
                    <span className="span-temperaturas">{props?.razao_social}</span>
                    <span className="span-title-temperaturas" >Mês:</span>
                    <span className="span-temperaturas">{props?.mes}</span>
                    <span className="span-title-temperaturas" >Ano:</span>
                    <span className="span-temperaturas">{props?.ano}</span>
                    <span className="span-title-temperaturas">Referências:</span>
                    <span className="span-temperaturas">{props?.temperatura}</span>
                    <span className="span-temperaturas">{props?.umidade}</span>
                </div>
            </div>
            {props.children}
        </div>
    )
}
import { useState } from "react";

import Row from "../../../components/body/row"
import Grupo from "../Grupo";
import Empreendimento from "../Empreendimento";
import Lojas from "../Lojas";
import Usuarios from "../Usuarios";
import Sistemas from "../Sistemas";
import Permissao from "../Permissao";
import Tipo from "../Tipo";
import Jobs from '../../Jobs/Gerenciador';

export default function Main(){
    // ESTADOS
    const [grupos, setGrupos] = useState([]);
    const [grupoActive, setGrupoActive] = useState(null);
    const [gruposEdit, setGruposEdit] = useState(false);
    const [grupoId, setGrupoId] = useState(null);
    const [empreendimentos, setEmpreendimentos] = useState([]);
    const [empreendimentosVisible, setEmpreendimentosVisible] = useState((window.rs_id_emp == 26 ? false : true));
    const [empreendimentoActive, setEmpreendimentoActive] = useState(null);
    const [lojaActive, setLojaActive] = useState(null);    
    const [lojaUsuarioActive, setLojaUsuarioActive] = useState(null);
    const [usuarioActive, setUsuarioActive] = useState(null);    
    const [sistemas, setSistemas] = useState([]);
    const [sistemaActive, setSistemaActive] = useState(null);
    const [sistemaIdApl, setSistemaIdApl] = useState(null);
    const [tipoActive, setTipoActive] = useState((window.rs_permission_apl === 'master' ? null : 'usuarios'));

    // CALLBACK DO CLIQUE COMPONENTE DE GRUPOS
    const handleCallbackGrupo = (e) => {
        if(e.edit){
            setGruposEdit(true);
        }else{
            setGruposEdit(false);
            setEmpreendimentosVisible(true);
            setGrupos(e.items);
            
            if(e.active || e.active === ''){
                setGrupoActive(e.active);
                setEmpreendimentoActive(null);
                setLojaActive(null);
                setLojaUsuarioActive(null);
                setUsuarioActive(null);
                setSistemaActive(null);
            }
        }
    }

    // CALLBACK DO CLIQUE COMPONENTE DE EMPREENDIMENTOS
    const handleCallbackEmpreendimento = (e) => {
        setEmpreendimentoActive(e.active);
        setGrupoId(e.id_grupo);
        setTipoActive((window.rs_permission_apl === 'master' ? null : 'usuarios'));
        setLojaActive(null);
        setLojaUsuarioActive(null);
        setUsuarioActive(null);
        setSistemaActive(null);
    }

    // CALLBACK DO CLIQUE COMPONENTE DE TIPOS
    const handleCallbackTipo = (e) => {
        setTipoActive(e.active);
        setLojaActive(null);
        setLojaUsuarioActive(null);
        setUsuarioActive(null);
        setSistemaActive(null);
    }

    // CALLBACK DO CLIQUE COMPONENTE DE LOJAS
    const handleCallbackLoja = (e) => {
        setLojaActive(e.active);
        setLojaUsuarioActive(null);
        setUsuarioActive(null);
        setSistemaActive(null);
    }

    // CALLBACK DO CLIQUE COMPONENTE DE USUÁRIOS
    const handleCallbackUsuario = (e) => {
        setUsuarioActive(e.active);
        setLojaUsuarioActive((e.store ? e.store : null));
        setSistemaActive(null);
    }

    // CALLBACK DO CLIQUE COMPONENTE DE SISTEMAS
    const handleCallbackSistema = (e) => {
        setSistemas(e.systems);
        setSistemaIdApl(e?.id_apl);
        setSistemaActive(e.active);
    }

    return(
        <Row wrap={(window.isMobile ? true : false)}>
            {(window.rs_id_emp == 26 ?
                <Grupo
                    items={grupos}
                    callback={handleCallbackGrupo}
                />
            :'')}

            {(empreendimentosVisible ? 
                <Empreendimento
                    group={grupoActive}
                    items={empreendimentos}   
                    callback={handleCallbackEmpreendimento}
                    show={empreendimentosVisible}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {(empreendimentoActive && empreendimentosVisible && (!window.rs_id_lja || window.rs_id_lja == 0) && window.rs_permission_apl === 'master' ?
                <Tipo
                    callback={handleCallbackTipo}
                    emp={empreendimentoActive}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {(tipoActive === 'sistemas' ? 
                <Sistemas
                    group={grupoId}
                    emp={empreendimentoActive}
                    store={lojaActive}
                    user={window.rs_id_usr}
                    user_store={window.rs_id_usr}
                    type={tipoActive}
                    callback={handleCallbackSistema}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {/* SISTEMA JOBS / CHAMADOS / FASES / VISITAS / OBRAS / COMUNICADOS / NOTIFICAÇÕES */}
            {(tipoActive === 'sistemas' && (sistemaActive == 223 || sistemaActive == 230 || sistemaActive == 231 || sistemaActive == 255 || sistemaActive == 242 || sistemaActive == 249 || sistemaActive == 256)  ? 
                <Jobs 
                    id_emp={empreendimentoActive}
                    id_grupo={grupoId}
                    id_apl={sistemaIdApl}
                />
            :'')}

            {((empreendimentoActive || grupos.length == 0) && empreendimentosVisible && (!window.rs_id_lja || window.rs_id_lja == 0) && tipoActive === 'usuarios' ? 
                <Lojas
                    group={grupoActive}
                    emp={empreendimentoActive}
                    type={tipoActive}
                    callback={handleCallbackLoja}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {((empreendimentoActive || grupos.length == 0) && empreendimentosVisible && tipoActive === 'usuarios' ? 
                <Usuarios
                    group={grupoActive}
                    emp={empreendimentoActive}
                    type={tipoActive}
                    store={lojaActive}
                    callback={handleCallbackUsuario}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {(usuarioActive && tipoActive === 'usuarios' ? 
                <Sistemas
                    group={grupoActive}
                    emp={empreendimentoActive}
                    store={lojaActive}
                    user={usuarioActive}
                    user_store={lojaUsuarioActive}
                    callback={handleCallbackSistema}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}

            {(sistemaActive && tipoActive === 'usuarios' ? 
                <Permissao
                    group={grupoActive}
                    emp={empreendimentoActive}
                    store={lojaActive}
                    user={usuarioActive}
                    user_store={lojaUsuarioActive}
                    systems={sistemas}
                    system={sistemaActive}
                    disabled={(gruposEdit ? true : false)}
                />
            :'')}
        </Row>
    )
}
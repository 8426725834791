import { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../../../context/Global";
import { useParams } from "react-router-dom";

/*SWIPER*/
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../../../_assets/css/swiper.scss";

import Title from "../../../../components/body/title";
import Editar from "../../../../components/body/card/editar";
import CardJobs from "../Card";
import Card from "../../../../components/body/card";
import { JobsContext } from "../../../../context/Jobs";
import style from '../card.module.scss';
import axios from "axios";
import PageError from "../../../../components/body/pageError";
import Button from "../../../../components/body/button";
import Icon from "../../../../components/body/icon";

export default function Jobs({jobs, widget, optionsModule, filters, changeLayout, reloadInternal, refreshCard, changeStatus, reachBeginning, reachEnd, transitionEnd, expand, refresh, actionButton}){
    // PARAMS
    const params = useParams();

    // CONTEXT GLOBAL
    const { loadingCards, cardExternal, prevIndex, handleSetPrevIndex } = useContext(GlobalContext);

    // CONTEXT JOBS
    const { autoSwiper, optionsEmpreendimentos, handleSetAutoSwiper, filterEmpreendimento } = useContext(JobsContext);

    // ESTADOS
    const [swiper, setSwiper] = useState();
    const [iconAvaliacao, setIconAvaliacao] = useState([]);

    const handleReloadInternal = (e) => {
        reloadInternal(e);
    }

    const handleRefreshCard = (e) => {
        refreshCard(e);
    }

    const handleChangeStatus = (e) => {
        changeStatus(e);
    }

    const handleReachBeginning = () => {
        reachBeginning();
    }

    const handleReachEnd = () => {
        reachEnd();
    }

    function handleTransitionEnd(swiper){
        if(filters?.tipoCalendario==1){ // SE FOR TIPO CALENDÁRIO (1)
            if(swiper.isEnd || swiper.isBeginning){
                transitionEnd();
            }
        }else if(filters?.tipoCalendario==2 || filters?.tipoCalendario==4){ // SE FOR TIPO LOJA (2) OU USUÁRIO (4)
            if(swiper.isEnd){
                transitionEnd();
            }
        }
    }

    // FUNÇÃO PARA BUSCAR TIPOS DE AVALIAÇÃO
    useEffect(() => {
        if(iconAvaliacao.length===0 && filterEmpreendimento){
            axios.get(window.host_madnezz+'/systems/integration-react/api/list.php?do=get_assessment&empreendimento_id='+filterEmpreendimento+'&id_apl=224').then((response) => {
                setIconAvaliacao(response.data);
            });
        }
    },[filterEmpreendimento]);

    // CALLBACK AO CLICAR EM EXPANDIR O CARD
    const handleExpandCallback = (e) => {
        expand?.callback();

        setTimeout(() => {
            swiper.slideTo(e?.index);

            if(prevIndex){
                swiper.slideTo(prevIndex);
                handleSetPrevIndex('');
            }

            let scrollElement = document.getElementById('card_'+e?.id);
            window.scrollTo({
                top: scrollElement.offsetTop + 42,
                behavior: 'smooth'
            });
        },100);
    }

    // CALLBACK DO CADASTRO PARA RECARREGAR A CONSULTA
    const handleReload = () => {
        if(refresh){
            refresh();
        }
    }

    // ACTION BUTTON
    const handleActionButton = () => {
        actionButton(true);
    }

    if(filters?.status.length == 1 && filters?.status.includes('-2') && jobs.length === 0 && loadingCards === false){
        return(
            <PageError
                icon="happiest"
                title="Muito bem!"
                text={`Você está cumprindo todas suas tarefas no prazo\ne não possui nenhuma em atraso.`}
                button={
                    <Button onClick={handleActionButton}>
                        Ir para Jobs do dia <Icon type="external" />
                    </Button>
                }
            />
        )
    }else{
        return(
            <Swiper
                ref={swiper}
                focusableElements="input, select, div, button, label, option, textarea"
                preventClicks={false}
                simulateTouch={false}
                modules={[Navigation]}
                autoHeight={false}
                spaceBetween={24}
                onSwiper={(swiper) => setSwiper(swiper)}
                onReachBeginning={() => handleReachBeginning()}
                onReachEnd={() => handleReachEnd()}
                onSlideChangeTransitionEnd={(swiper) => handleTransitionEnd(swiper)}
                navigation={(widget || cardExternal.enabled ? false : true)}
                className={changeLayout ? "panel" : "" + ' calendar' + ' ' + (widget ? 'widget' : '')}
                allowTouchMove={true}
                slidesPerView={1}
                slidesPerGroup={1}
                watchSlidesProgress={true}
                breakpoints={{
                768: {
                    allowTouchMove: false,
                    slidesPerView: 'auto',
                    slidesPerGroupAuto: true
                },
                }}
            >
                {(loadingCards ?
                    [...Array(5)].map((card, i) => (
                        <SwiperSlide key={'empty_'+i}>
                            <Card
                                loading={true}
                                qtd={1}
                                widget={widget}
                            ></Card>
                        </SwiperSlide>
                    ))
                :
                    (jobs.length > 0 ?
                        <>
                            {jobs?.map((job, indexSlide) => {
                                //SETA SLIDE INICIAL
                                {(() => {
                                    setTimeout(() => {
                                        if (filters?.tipoCalendario == 1) {
                                            if (autoSwiper && !params['periodEnd']) {
                                                if ((window.isMobile && job.active) || (!window.isMobile && job.monday)) {
                                                    if (swiper) {
                                                        swiper.slideTo(indexSlide);
                                                        setTimeout(() => {
                                                            handleSetAutoSwiper(false);
                                                        }, 500);
                                                    }
                                                }
                                            }
                                        } else {
                                            if (autoSwiper && !params['periodEnd']) {
                                                if (job.active) {
                                                    if (swiper) {
                                                        swiper.slideTo(indexSlide);
                                                        setTimeout(() => {
                                                            handleSetAutoSwiper(false);
                                                            swiper.update();
                                                        }, 500);
                                                    }
                                                }
                                            }
                                        }
                                    }, 200);
                                })();}

                                let logo = false;
                                if((filters?.tipoCalendario == 2 || filters?.tipoCalendario == 8 ) && optionsEmpreendimentos.length > 1){ // TIPO LOJA (2) OU PLANO DE AÇÃO (8)
                                    logo = true;
                                }

                                return (
                                    <SwiperSlide key={'swiper_'+indexSlide}>
                                        {({ isVisible }) => (
                                            (isVisible ?
                                                <>
                                                    {!changeLayout ?
                                                        <Title
                                                            icon={
                                                            <>
                                                                {((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') ? // SE PERMISSÃO FOR MAIS QUE 2
                                                                    (job?.date ? // SE O JOB POSSUI DATA
                                                                        (job.date >= window.currentDateWithoutHour?
                                                                            <Editar
                                                                                empty={true}
                                                                                dateStart={job?.date}
                                                                                frequency={job?.id_frequency}
                                                                                reload={handleReload}
                                                                                model={filters?.modelo}
                                                                            />
                                                                        :
                                                                            ''
                                                                        )
                                                                    : 
                                                                        <Editar
                                                                            empty={true}
                                                                            frequency={job?.id_frequency}
                                                                            id_lja={(job.id_lja?job.id_lja:'')}
                                                                            id_emp={(job.id_emp?job.id_emp:'')}
                                                                            id_usr={(job.id_usr?job.id_usr:'')}
                                                                            reload={handleReload}
                                                                            modalTitle={(filters?.modelo ? 'Novo Modelo' : '')}
                                                                            model={filters?.modelo}
                                                                        />
                                                                    )
                                                                : ''
                                                                )}
                                                            </>
                                                            }
                                                            active={job.active ? true : false}
                                                        >
                                                            {(logo && job?.logo_emp ? 
                                                                <img src={process.env.REACT_APP_URL_UPLOAD+'/'+job?.logo_emp} className={style.logo} />
                                                            :
                                                                <></>
                                                            )}
                                                            
                                                            {(job?.logo_emp && job?.id_filial && optionsEmpreendimentos.length > 1 ?
                                                                'Filial '+job?.id_filial
                                                            :
                                                                job?.title
                                                            )}                                                    
                                                        </Title>
                                                    : 
                                                        ''
                                                    }

                                                    {(job.group.length>0 ?                       
                                                        job.group?.map((group, i) => {
                                                            let filter_jobs;
                                                            let filter_chamados;
                                                            let filter_comunicados;
                                                            let filter_notificacoes;

                                                            // FILTRA OS CARDS QUE POSSUEM O SISTEMA CHAMADOS E QUE NÃO FORAM SOLICITADOS PELO USUÁRIO OU OS JOBS QUE NÃO POSSUEM O SISTEMA CHAMADOS
                                                            if(filters?.tipoCalendario == 1){
                                                                if(filters?.loja > 0 || filters?.loja.length > 0){                                                                
                                                                    filter_jobs = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_lja != filters?.loja) || !elem.id_apl.includes('224'));
                                                                    filter_jobs = filter_jobs.filter((elem) => (elem.id_apl.includes('229') && elem.cad_lja != filters?.loja) || !elem.id_apl.includes('229'));
                                                                    filter_jobs = filter_jobs.filter((elem) => !elem.id_apl.includes('231'));
                                                                }else if(filters?.usuario > 0 || filters?.usuario.length > 0){     
                                                                    filter_jobs = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_usr != filters?.usuario) || !elem.id_apl.includes('224'));    
                                                                    filter_jobs = filter_jobs.filter((elem) => (elem.id_apl.includes('229') && elem.cad_usr != filters?.usuario) || !elem.id_apl.includes('229'));                                                             
                                                                    filter_jobs = filter_jobs.filter((elem) => !elem.id_apl.includes('231'));  
                                                                }
                                                            }else if(filters?.tipoCalendario == 2){
                                                                filter_jobs = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_lja != job.id_lja) || !elem.id_apl.includes('224'));
                                                                filter_jobs = filter_jobs.filter((elem) => (elem.id_apl.includes('229') && elem.cad_lja != job.id_lja) || !elem.id_apl.includes('229'));
                                                                filter_jobs = filter_jobs.filter((elem) => !elem.id_apl.includes('231'));
                                                            }else if(filters?.tipoCalendario == 4){
                                                                filter_jobs = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_usr != job.id_usr) || !elem.id_apl.includes('224'));
                                                                filter_jobs = filter_jobs.filter((elem) => (elem.id_apl.includes('229') && elem.cad_usr != job.id_usr) || !elem.id_apl.includes('229'));
                                                                filter_jobs = filter_jobs.filter((elem) => !elem.id_apl.includes('231'));
                                                            }else{
                                                                filter_jobs = group.cards;
                                                            }

                                                            // FILTRA OS CARDS QUE POSSUEM O SISTEMA CHAMADOS E QUE FORAM SOLICITADOS PELO USUÁRIO
                                                            if(filters?.tipoCalendario == 1){
                                                                if(filters?.loja > 0 || filters?.loja.length > 0){
                                                                    filter_chamados = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_lja == filters?.loja));                                                                
                                                                }else if(filters?.usuario > 0 || filters?.usuario.length > 0){                                                                
                                                                    filter_chamados = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_usr == filters?.usuario));
                                                                }
                                                            }else if(filters?.tipoCalendario == 2){
                                                                filter_chamados = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_lja == job.id_lja));
                                                            }else if(filters?.tipoCalendario == 4){
                                                                filter_chamados = group.cards.filter((elem) => (elem.id_apl.includes('224') && elem.cad_usr == job.id_usr));
                                                            }else{
                                                                filter_chamados = [];
                                                            }

                                                            // FILTRA OS CARDS QUE POSSUEM O SISTEMA COMUNICADOS
                                                            if(filters?.tipoCalendario == 1){
                                                                if(filters?.loja > 0 || filters?.loja.length > 0){
                                                                    filter_comunicados = group.cards.filter((elem) => (elem.id_apl.includes('229') && !elem.id_apl.includes('231')));
                                                                }else if(filters?.usuario > 0 || filters?.usuario.length > 0){                                                                
                                                                    filter_comunicados = group.cards.filter((elem) => (elem.id_apl.includes('229') && !elem.id_apl.includes('231')));
                                                                }
                                                            }else if(filters?.tipoCalendario == 2){
                                                                filter_comunicados = group.cards.filter((elem) => (elem.id_apl.includes('229') && !elem.id_apl.includes('231')));
                                                            }else if(filters?.tipoCalendario == 4){
                                                                filter_comunicados = group.cards.filter((elem) => (elem.id_apl.includes('229') && !elem.id_apl.includes('231')));
                                                            }else{
                                                                filter_comunicados = [];
                                                            }

                                                            // FILTRA OS CARDS QUE POSSUEM O SISTEMA NOTIFICAÇÕES
                                                            if(filters?.tipoCalendario == 1){
                                                                if(filters?.loja > 0 || filters?.loja.length > 0){
                                                                    filter_notificacoes = group.cards.filter((elem) => elem.id_apl.includes('231'));                                                                
                                                                }else if(filters?.usuario > 0 || filters?.usuario.length > 0){                                                                
                                                                    filter_notificacoes = group.cards.filter((elem) => elem.id_apl.includes('231'));
                                                                }
                                                            }else if(filters?.tipoCalendario == 2){
                                                                filter_notificacoes = group.cards.filter((elem) => elem.id_apl.includes('231'));
                                                            }else if(filters?.tipoCalendario == 4){
                                                                filter_notificacoes = group.cards.filter((elem) => elem.id_apl.includes('231'));
                                                            }else{
                                                                filter_notificacoes = [];
                                                            }
                                                            
                                                            return(                          
                                                                <div key={'group_'+i}>
                                                                    <div>
                                                                        {(filter_jobs.length > 0 ?
                                                                            filter_jobs.map((card, i) => {
                                                                                if((cardExternal.enabled && cardExternal.id == card?.id_job_status) || !cardExternal.enabled){
                                                                                    return(
                                                                                        <CardJobs
                                                                                            key={'calendario_' + card?.id_job_status}
                                                                                            i={i}
                                                                                            indexSlide={indexSlide}
                                                                                            card={card}
                                                                                            group={group}
                                                                                            jobs={jobs}
                                                                                            job={job}
                                                                                            loja={filters?.loja}
                                                                                            usuario={filters?.usuario}
                                                                                            periodStart={filters?.periodStart}
                                                                                            periodEnd={filters?.periodEnd}
                                                                                            swiper={swiper}
                                                                                            optionsModule={optionsModule}
                                                                                            iconAvaliacao={iconAvaliacao}
                                                                                            tipoCalendario={filters?.tipoCalendario}
                                                                                            subTipoCalendario={filters?.subTipoCalendario}
                                                                                            changeLayout={changeLayout}
                                                                                            reload={handleReload}
                                                                                            reloadInternal={handleReloadInternal}
                                                                                            refreshCard={handleRefreshCard}
                                                                                            changeStatus={handleChangeStatus}
                                                                                            widget={widget}
                                                                                            expand={{
                                                                                                callback: handleExpandCallback
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                        :
                                                                            <Card
                                                                                empty={true}
                                                                                widget={widget}
                                                                            ></Card>
                                                                        )}
                                                                    </div>

                                                                    {/* CHAMADOS ABERTOS PELO USUÁRIO */}
                                                                    <div>
                                                                        {(filter_chamados.length > 0 ?
                                                                            <div className={style.separator}>
                                                                                <span>Chamados departamentos</span>
                                                                            </div>
                                                                        :'')}

                                                                        {(filter_chamados.length > 0 ?
                                                                            filter_chamados.map((card, i) => {
                                                                                if((cardExternal.enabled && cardExternal.id == card?.id_job_status) || !cardExternal.enabled){
                                                                                    return(
                                                                                        <CardJobs
                                                                                            key={'calendario_' + card?.id_job_status}
                                                                                            i={i}
                                                                                            indexSlide={indexSlide}
                                                                                            card={card}
                                                                                            group={group}
                                                                                            jobs={jobs}
                                                                                            job={job}
                                                                                            loja={filters?.loja}
                                                                                            usuario={filters?.usuario}
                                                                                            periodStart={filters?.periodStart}
                                                                                            periodEnd={filters?.periodEnd}
                                                                                            swiper={swiper}
                                                                                            optionsModule={optionsModule}
                                                                                            iconAvaliacao={iconAvaliacao}
                                                                                            tipoCalendario={filters?.tipoCalendario}
                                                                                            subTipoCalendario={filters?.subTipoCalendario}
                                                                                            changeLayout={changeLayout}
                                                                                            reload={handleReload}
                                                                                            reloadInternal={handleReloadInternal}
                                                                                            refreshCard={handleRefreshCard}
                                                                                            changeStatus={handleChangeStatus}
                                                                                            widget={widget}
                                                                                            expand={{
                                                                                                callback: handleExpandCallback
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                        :
                                                                            <></>
                                                                        )}
                                                                    </div>

                                                                    {/* COMUNICADOS */}
                                                                    <div>
                                                                        {(filter_comunicados.length > 0 ?
                                                                            <div className={style.separator}>
                                                                                <span>Comunicados</span>
                                                                            </div>
                                                                        :'')}

                                                                        {(filter_comunicados.length > 0 ?
                                                                            filter_comunicados.map((card, i) => {
                                                                                if((cardExternal.enabled && cardExternal.id == card?.id_job_status) || !cardExternal.enabled){
                                                                                    return(
                                                                                        <CardJobs
                                                                                            key={'calendario_' + card?.id_job_status}
                                                                                            i={i}
                                                                                            indexSlide={indexSlide}
                                                                                            card={card}
                                                                                            group={group}
                                                                                            jobs={jobs}
                                                                                            job={job}
                                                                                            loja={filters?.loja}
                                                                                            usuario={filters?.usuario}  
                                                                                            periodStart={filters?.periodStart}
                                                                                            periodEnd={filters?.periodEnd}
                                                                                            swiper={swiper}
                                                                                            optionsModule={optionsModule}
                                                                                            iconAvaliacao={''}
                                                                                            tipoCalendario={filters?.tipoCalendario}
                                                                                            subTipoCalendario={filters?.subTipoCalendario}
                                                                                            changeLayout={changeLayout}
                                                                                            reload={handleReload}
                                                                                            reloadInternal={handleReloadInternal}
                                                                                            refreshCard={handleRefreshCard}
                                                                                            changeStatus={handleChangeStatus}
                                                                                            widget={widget}
                                                                                            expand={{
                                                                                                callback: handleExpandCallback
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                        :
                                                                            <></>
                                                                        )}
                                                                    </div>

                                                                    {/* NOTIFICAÇÕES */}
                                                                    <div>
                                                                        {(filter_notificacoes.length > 0 ?
                                                                            <div className={style.separator}>
                                                                                <span>Notificações</span>
                                                                            </div>
                                                                        :'')}

                                                                        {(filter_notificacoes.length > 0 ?
                                                                            filter_notificacoes.map((card, i) => {
                                                                                if((cardExternal.enabled && cardExternal.id == card?.id_job_status) || !cardExternal.enabled){
                                                                                    return(
                                                                                        <CardJobs
                                                                                            key={'calendario_' + card?.id_job_status}
                                                                                            i={i}
                                                                                            indexSlide={indexSlide}
                                                                                            card={card}
                                                                                            group={group}
                                                                                            jobs={jobs}
                                                                                            job={job}
                                                                                            loja={filters?.loja}
                                                                                            usuario={filters?.usuario}  
                                                                                            periodStart={filters?.periodStart}
                                                                                            periodEnd={filters?.periodEnd}
                                                                                            swiper={swiper}
                                                                                            optionsModule={optionsModule}
                                                                                            iconAvaliacao={''}
                                                                                            tipoCalendario={filters?.tipoCalendario}
                                                                                            subTipoCalendario={filters?.subTipoCalendario}
                                                                                            changeLayout={changeLayout}
                                                                                            reload={handleReload}
                                                                                            reloadInternal={handleReloadInternal}
                                                                                            refreshCard={handleRefreshCard}
                                                                                            changeStatus={handleChangeStatus}
                                                                                            widget={widget}
                                                                                            expand={{
                                                                                                callback: handleExpandCallback
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                        :
                                                                            <></>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    : 
                                                        <></>
                                                    )}
                                                </>
                                            :
                                                ''
                                            )
                                        )}
                                    </SwiperSlide>
                                )
                            })}

                            {(cardExternal.enabled ? 
                                [...Array(5)].map((card, i) => (
                                    <SwiperSlide key={'empty_'+i}>
                                        {/* SLIDES NECESSÁRIOS PARA QUANDO CLICAR EM MAXIMIZAR O CARD TER SLIDES EXTRAS PARA PODER FAZER O SCROLL PARA A ESQUERDA */}
                                    </SwiperSlide>
                                ))
                            :'')}
                        </>
                    :
                        <></>
                    )
                )}
            </Swiper>
        )
    }
}
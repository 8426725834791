import "./stories.css";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useState, useEffect } from "react";
import Icon from "../../../../../components/body/icon";

export default function Comunicados(props) {

    //ESTADOS
    const [comunicado, setComunicado] = useState([]);

    function getComunicado() {
        axios.get(
            "https://dev.malltech.com.br/systems/comunicado/api/lista.php?", {
            params: {
                p: "lista",
                s: "1",
                page: "1",
                limit: "10",
                lista_metodo: "array",
                filtro_nome: "",
                filtro_loja: "",
                filtro_data_inicio: "",
                filtro_data_fim: "",
                filtro_tipo_comunicado: "Comunicados",
                filtro_usuario: 79507,
                filter_multiple_columns: props?.search,
            }
        }).then((response) => setComunicado(response.data))
    }

    useEffect(() => {
        getComunicado()
    }, [props?.search])


    return (
        <div className="div-home-stories" >
            <div>
                <h4>Comunicados</h4>
                <div className="gray-border"></div>
                <ul>
                    {(comunicado.length > 0 ?
                        comunicado.map((item, i) => {
                            return (
                                <li key={item.id}>
                                    <a href={`https://dev.malltech.com.br/systems/comunicado/?p=lista&s=1&c=${item.id}`} target="_blank">
                                        <div className="" style={{ display: "flex", gap: "20px" }} >
                                            <div className=" " >
                                                <Icon
                                                    type="message"
                                                    animated
                                                    title={false}
                                                    readonly
                                                />
                                            </div>
                                            <div>
                                                <h5>{item.nome}</h5>
                                                <p className="mb-0">{item.data}</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            )
                        })
                    :
                        <li>
                            <a href="#" target="_blank">
                                <div className="" style={{ display: "flex", gap: "20px" }} >
                                    <div>
                                        <p className="mb-0" dangerouslySetInnerHTML={{__html: 'Nenhum resultado encontrado para a busca: <b>'+props?.search+'</b>'}} />
                                    </div>
                                </div>
                            </a>
                        </li>
                    )}
                </ul>
            </div>

            {/* <div className="stories-btn" >
                <a href="https://sistemas.malltech.com.br/systems/vendas" target="_blank" ><button>Vendas</button></a>
            </div> */}
        </div>
    )
}